import { styled, Tab, Tabs } from "@mui/material";
import { sizeDecreaseCalc } from "../../utils";

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  borderBottom: "2px solid #4BD4FF",

  "& .Mui-selected": {
    borderRadius: theme.spacing(1, 1, 0, 0),
    background: "#4BD4FF",
    color: "#000000",
    transition: "background-color 0.4s ease, color 0.4s ease",
  },

  "& .MuiTabs-indicator": {
    display: "none",
  },
}));

export const StyledTab = styled(Tab, { shouldForwardProp: prop => prop !== "active" })(({ theme }) => ({
  minHeight: "48px !important",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: theme.spacing(1.5),
  padding: theme.spacing(0, 2, 0, 2),
  "& span": {
    textTransform: "none",
    color: "#000000",
    fontSize: sizeDecreaseCalc(16, 14, "px"),
    fontWeight: 500,
    lineHeight: "130%" /* 20.8px */,
  },
}));
