import React, { FC } from "react";
import { Grid2, useMediaQuery, useTheme } from "@mui/material";

import { ProgressOverlay } from "@dzambalaorg/mui-page-layout";
import { useCollection } from "@dzambalaorg/provider-collection";

import { StyledEmptyWrapper, StyledPagination } from "@framework/styled";
import { ITokenSearchDto } from "@framework/types";

import {
  BoxesAssetVariant,
  DESKTOP_ITEMS_COUNT,
  LoadMoreButton,
  MOBILE_ITEMS_COUNT,
  PATHS,
  TokenCard,
} from "../../../../shared";
import { Token } from "../types";
import { useChangeAssetsTab } from "../useChangeAssetsTab";

interface IBoxesAssetsProps {
  variant: BoxesAssetVariant;
}

export const BoxesAssets: FC<IBoxesAssetsProps> = props => {
  const { variant } = props;

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const { rows, count, search, isLoading, handleChangePage, handleChangePaginationModel } = useCollection<
    Token,
    ITokenSearchDto
  >({
    baseUrl: "/tokens/search",
    search: {
      take: isMd ? MOBILE_ITEMS_COUNT : DESKTOP_ITEMS_COUNT,
    },
    redirect: () => `${PATHS.DashboardBase}/${variant}`,
  });

  useChangeAssetsTab(variant);

  const loadMoreHandler = () => {
    handleChangePaginationModel({
      page: 0,
      pageSize: search.take * 2,
    });
  };

  return (
    <React.Fragment>
      <ProgressOverlay isLoading={isLoading}>
        <Grid2 container spacing={2}>
          <StyledEmptyWrapper count={rows.length} isLoading={isLoading}>
            {rows.map(token => (
              <Grid2 size={{ lg: 4, md: 6, sm: 12, xs: 12 }} key={token.id}>
                <TokenCard template={token.template} token={token} variant={variant} />
              </Grid2>
            ))}
          </StyledEmptyWrapper>
        </Grid2>
      </ProgressOverlay>

      {!isMd && !!count && count > search.take && (
        <StyledPagination
          shape="rounded"
          page={search.skip / search.take + 1}
          count={Math.ceil(count / search.take)}
          onChange={handleChangePage}
        />
      )}

      {isMd && rows.length !== count && <LoadMoreButton loadMoreHandler={loadMoreHandler} />}
    </React.Fragment>
  );
};
