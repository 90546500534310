import { css } from "@emotion/react";

export const globalStyles = css`
  html,
  body,
  #root {
    font-family: "Poppins", sans-serif;
    background: #E2E2E2;
  }
`;
