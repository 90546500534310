import React, { SVGProps } from "react";

export const LinearShape = ({ width = "66", height = "28" }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={width} height={height} viewBox="0 0 66 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M65.5 27.9999V0.5L1 27V27.9999H65.5Z" fill="url(#paint0_linear_2749_3702)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.5001 0.999974L0.622999 27.4782L0.398187 26.5216L65.5 -3.03638e-05L65.5001 0.999974Z"
        fill="#71FFFF"
      />
      <defs>
        <linearGradient id="paint0_linear_2749_3702" x1="65" y1="1" x2="65" y2="30" gradientUnits="userSpaceOnUse">
          <stop stopColor="#71FFFF" stopOpacity="0.6" />
          <stop offset="1" stopColor="#0F1D2C" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
