export const progressBarColors = {
  progressOrange: {
    backgroundColor: "#F8BB81",
    barColor: "#F6851B",
    indexerColor: "#BE6007",
  },
  progressBlue: {
    backgroundColor: "#AEC8E4",
    barColor: "#2775CA",
    indexerColor: "#1B5DA5",
  },
  progressGreen: {
    backgroundColor: "#B9E0A7",
    barColor: "#448327",
    indexerColor: "#285513",
  },
};
