import { FC, SyntheticEvent, useState } from "react";
import { FormattedMessage } from "react-intl";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Root, StyledAccordion, StyledAccordionDetails, StyledAccordionSummary, StyledContent } from "./styled";
import { faqData } from "./schemas";

export const Faq: FC = () => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string) => (_event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Root id={"faq"}>
      <FormattedMessage id={"pages.landing.faq.title"} tagName="h1" />

      <StyledContent>
        {faqData.map(item => (
          <StyledAccordion
            key={item.expanded}
            disableGutters
            elevation={0}
            expanded={expanded === item.expanded}
            onChange={handleChange(item.expanded)}
          >
            <StyledAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={"panel1-content"}
              id={"panel1-header"}
            >
              <FormattedMessage key={item.question} id={item.question} />
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              {Object.values(item.answers).map(answer => (
                <FormattedMessage key={answer} tagName={"p"} id={answer} />
              ))}
            </StyledAccordionDetails>
          </StyledAccordion>
        ))}
      </StyledContent>
    </Root>
  );
};
