import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Link as RouterLink } from "react-router-dom";

import { StyledBreadcrumbs } from "./styled";
import { BcSeparatorSvg } from "../../shared";

export interface IBreadcrumbsProps {
  paths: Array<string> | Record<string, string>;
  isHidden?: boolean;
  data?: Array<any>;
}

export const Breadcrumbs: FC<IBreadcrumbsProps> = props => {
  const { paths, isHidden, data = [] } = props;

  if (isHidden) {
    return null;
  }

  const mappedPaths = useMemo(
    () =>
      Array.isArray(paths)
        ? paths.map(item => ({ path: item.replace(".", "/"), key: item }))
        : Object.keys(paths).map(key => ({
            path: paths[key],
            key,
          })),
    [paths],
  );

  return (
    <StyledBreadcrumbs separator={<BcSeparatorSvg color={"rgba(0, 0, 0, 0.8)"} />} aria-label="breadcrumbs">
      {mappedPaths.map(({ path, key }, i) => {
        if (i === mappedPaths.length - 1) {
          return <FormattedMessage key={i} id={`pages.${key}.title`} values={data[i]} tagName="p" />;
        }
        return (
          <RouterLink to={`/${path}`} key={i}>
            <FormattedMessage id={`pages.${key}.title`} values={data[i]} />
          </RouterLink>
        );
      })}
    </StyledBreadcrumbs>
  );
};
