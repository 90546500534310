import React, { FC } from "react";
import { useIntl } from "react-intl";
import { Box, Typography } from "@mui/material";

import { IVestingBox } from "@framework/types";

import {
  CustomTooltip,
  INFINITY_DISPLAYING,
  InfoSvg,
  pageHeader,
  progressBarColors,
  sizeDecreaseCalc,
  StyledLinearProgress,
  StyledProgressBarBox,
  useVestingContractInfo,
} from "../../../../../../../shared";
import { cliffImmediateShapes } from "../../constants";

interface IProgressBarsProps {
  vestingBox: IVestingBox;
  totalTokensCount: number;
  tokenId?: string | null;
}

export const ProgressBars: FC<IProgressBarsProps> = props => {
  const {
    vestingBox: { afterCliffBasisPoints, template, shape },
    totalTokensCount,
    tokenId,
  } = props;
  const { progressBlue, progressGreen } = progressBarColors;

  const { formatMessage } = useIntl();

  const { releasablePercentage } = useVestingContractInfo(tokenId || "", template!.contract!.address, totalTokensCount);

  const isCliffVesting = cliffImmediateShapes.includes(shape);
  const availableBoxesPercentage =
    Number(template!.cap) > 0 ? 100 - (Number(template!.amount) / Number(template!.cap)) * 100 : 100;
  const availableBoxesCount =
    Number(template!.cap) > 0 ? Number(template!.cap) - Number(template!.amount) : INFINITY_DISPLAYING;
  const availableTokensPercentage = isCliffVesting ? 0 : tokenId ? releasablePercentage : afterCliffBasisPoints / 100;

  if (availableBoxesCount === 0) {
    return (
      <Typography
        sx={{
          ...pageHeader(),
          fontSize: sizeDecreaseCalc(24, 18),
        }}
        variant="body1"
        component="p"
      >
        {`${formatMessage({ id: "pages.vesting.currentBox.unavailablePurchase" })}`}
      </Typography>
    );
  }

  return (
    <React.Fragment>
      <StyledProgressBarBox>
        <Typography variant="body1" component="p">
          {`${formatMessage({ id: "pages.vesting.currentBox.boxesAvailable" })}`}
        </Typography>
        <StyledLinearProgress
          {...progressBlue}
          content={availableBoxesCount as never}
          variant="determinate"
          value={availableBoxesPercentage}
        />
      </StyledProgressBarBox>

      <StyledProgressBarBox>
        <Box className="ProgressBar-tooltip_box">
          <Typography variant="body1" component="p">
            {`${formatMessage({ id: "pages.vesting.currentBox.tokensAvailable" })}`}
          </Typography>
          {isCliffVesting && (
            <CustomTooltip
              title={formatMessage(
                { id: "pages.vesting.currentBox.availableProgressTooltip" },
                { percentage: `${afterCliffBasisPoints / 100}%` },
              )}
            >
              <InfoSvg />
            </CustomTooltip>
          )}
        </Box>
        <StyledLinearProgress
          {...progressGreen}
          content={`${availableTokensPercentage}%` as never}
          variant="determinate"
          value={availableTokensPercentage}
        />
      </StyledProgressBarBox>
    </React.Fragment>
  );
};
