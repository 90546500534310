export const agreementData = {
  generalProvisions: {
    title: "dialogs.userAgreement.generalProvisions.title",
    paragraphs: {
      1: "dialogs.userAgreement.generalProvisions.1",
      2: "dialogs.userAgreement.generalProvisions.2",
      3: "dialogs.userAgreement.generalProvisions.3",
    },
  },
  accessConditions: {
    title: "dialogs.userAgreement.accessConditions.title",
    paragraphs: {
      1: "dialogs.userAgreement.accessConditions.1",
      2: "dialogs.userAgreement.accessConditions.2",
      3: "dialogs.userAgreement.accessConditions.3",
    },
  },
  vesting: {
    title: "dialogs.userAgreement.vesting.title",
    paragraphs: {
      1: "dialogs.userAgreement.vesting.1",
      2: "dialogs.userAgreement.vesting.2",
      3: "dialogs.userAgreement.vesting.3",
    },
  },
  trading: {
    title: "dialogs.userAgreement.trading.title",
    paragraphs: {
      1: "dialogs.userAgreement.trading.1",
      2: "dialogs.userAgreement.trading.2",
      3: "dialogs.userAgreement.trading.3",
    },
  },
  confidentiality: {
    title: "dialogs.userAgreement.confidentiality.title",
    paragraphs: {
      1: "dialogs.userAgreement.confidentiality.1",
      2: "dialogs.userAgreement.confidentiality.2",
      3: "dialogs.userAgreement.confidentiality.3",
    },
  },
  liabilityLimitation: {
    title: "dialogs.userAgreement.liabilityLimitation.title",
    paragraphs: {
      1: "dialogs.userAgreement.liabilityLimitation.1",
      2: "dialogs.userAgreement.liabilityLimitation.2",
      3: "dialogs.userAgreement.liabilityLimitation.3",
    },
  },
  disputeResolution: {
    title: "dialogs.userAgreement.disputeResolution.title",
    paragraphs: {
      1: "dialogs.userAgreement.disputeResolution.1",
      2: "dialogs.userAgreement.disputeResolution.2",
    },
  },
  finalProvisions: {
    title: "dialogs.userAgreement.finalProvisions.title",
    paragraphs: {
      1: "dialogs.userAgreement.finalProvisions.1",
      2: "dialogs.userAgreement.finalProvisions.2",
      3: "dialogs.userAgreement.finalProvisions.3",
    },
  },
};
