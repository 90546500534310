import { Accordion, AccordionDetails, AccordionSummary, accordionSummaryClasses, styled } from "@mui/material";

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  width: "100%",
  transition: "all 0.3s linear",
  background: "transparent",

  "&::before": {
    display: "none",
  },
}));

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  gap: theme.spacing(1.5),
  color: "#000000",
  opacity: 0.8,
  fontSize: theme.spacing(2),
  fontWeight: 400,
  lineHeight: "160%",
  borderLeft: "1px solid #4BD4FF",
  borderRight: "1px solid #4BD4FF",
  borderBottom: "1px solid #4BD4FF",
  padding: theme.spacing(1, 1.5),
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },

  "& p": {
    margin: 0,
  },
}));

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  flexDirection: "row-reverse",
  alignItems: "center",
  color: "#000000",
  opacity: 0.8,
  fontSize: theme.spacing(2),
  fontWeight: 500,
  lineHeight: "130%",
  letterSpacing: "-0.075px",
  paddingLeft: theme.spacing(1.5),
  background: "#4BD4FF",
  borderRadius: theme.spacing(1.5),

  [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]: {
    transform: "rotate(180deg)",
  },

  "&.Mui-expanded": {
    borderTopLeftRadius: theme.spacing(1.5),
    borderTopRightRadius: theme.spacing(1.5),
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },

  "& .MuiAccordionSummary-content": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    textAlign: "center",
    margin: 0,
    paddingLeft: theme.spacing(1.5),
    [theme.breakpoints.down("sm")]: {},
  },
}));
