import React, { SVGProps } from "react";

export const VestingSvg = ({ width = "20", height = "20" }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id={"fill"}
        d="M10 18.75C14.825 18.75 18.75 14.825 18.75 10C18.75 5.175 14.825 1.25 10 1.25C5.175 1.25 1.25 5.175 1.25 10C1.25 14.825 5.175 18.75 10 18.75ZM10 2.5C14.1375 2.5 17.5 5.8625 17.5 10C17.5 14.1375 14.1375 17.5 10 17.5C5.8625 17.5 2.5 14.1375 2.5 10C2.5 5.8625 5.8625 2.5 10 2.5Z"
        fill="white"
      />
      <path
        id={"fill"}
        d="M6.24992 9.37501H13.7499C14.0954 9.37501 14.3749 9.09513 14.3749 8.75001C14.3749 8.40488 14.0954 8.12501 13.7499 8.12501H7.41786L8.01998 7.22169C8.21136 6.93451 8.13379 6.54632 7.84667 6.35501C7.55892 6.16307 7.17098 6.24088 6.97998 6.52832L5.72998 8.40332C5.60211 8.59494 5.59023 8.84157 5.69886 9.04482C5.80773 9.24807 6.01948 9.37501 6.24992 9.37501Z"
        fill="white"
      />
      <path
        id={"fill"}
        d="M6.25 11.875H12.5824L11.9799 12.7783C11.7883 13.0655 11.8658 13.4537 12.1532 13.645C12.2601 13.7161 12.3804 13.75 12.4994 13.75C12.7014 13.75 12.8998 13.6523 13.02 13.4717L14.27 11.5967C14.3976 11.4051 14.4097 11.1584 14.3011 10.9552C14.1925 10.7519 13.9807 10.625 13.75 10.625H6.25C5.90487 10.625 5.625 10.9049 5.625 11.25C5.625 11.5951 5.90487 11.875 6.25 11.875Z"
        fill="white"
      />
    </svg>
  );
};
