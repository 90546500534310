import { FC } from "react";
import { Navigate } from "react-router";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { Error, Message } from "@dzambalaorg/common-pages";
import { FirebaseLogin } from "@dzambalaorg/firebase-login";
import { MetamaskLoginButton } from "@dzambalaorg/login-button-metamask";
import { ParticleLoginButton } from "@dzambalaorg/login-button-particle";
import { WalletConnectLoginButton } from "@dzambalaorg/login-button-wallet-connect";

import { PATHS } from "../shared";
import { Layout } from "../components/common/layout";
import { Providers } from "./providers";
import { mechanicsRoutes } from "./mechanics/routes";
import { exchangeRoutes } from "./exchange/routes";
import { infrastructureRoutes } from "./infrastructure/routes";
import withAgreementAuth from "../components/withAgreementAuth";

const WrappedLayout = withAgreementAuth(Layout);

const router = createBrowserRouter([
  {
    path: PATHS.Main,
    element: (
      <Providers>
        <WrappedLayout />
      </Providers>
    ),
    children: [
      ...infrastructureRoutes,
      ...mechanicsRoutes,
      ...exchangeRoutes,
      {
        path: PATHS.Login,
        element: (
          <FirebaseLogin
            withEmail={false}
            buttons={[MetamaskLoginButton, WalletConnectLoginButton, ParticleLoginButton]}
          />
        ),
      },
      {
        path: PATHS.Error,
        element: <Error />,
      },
      {
        path: PATHS.Message,
        element: <Message />,
      },
      { path: "*", element: <Navigate to="/message/page-not-found" /> },
    ],
  },
]);

export const App: FC = () => {
  return <RouterProvider router={router} />;
};
