import React, { FC } from "react";
import { useIntl } from "react-intl";
import { Grid2 } from "@mui/material";

import { EntityInput } from "@dzambalaorg/mui-inputs-entity";
import { EthInput } from "@dzambalaorg/mui-inputs-mask";
import { CommonSearchForm } from "@dzambalaorg/mui-form-search";
import { AutocompleteInput } from "@dzambalaorg/mui-inputs-core";
import { IVestingBoxInMarketSearchDto, VestingBoxCategory } from "@framework/types";

import { VestingBoxesTabs } from "../../../../../../shared";

interface IBoxesFiltersProps {
  isFiltersOpen: boolean;
  search: IVestingBoxInMarketSearchDto;
  handleSearch: (values: IVestingBoxInMarketSearchDto) => Promise<void>;
  tab?: VestingBoxesTabs;
}

export const BoxesFilters: FC<IBoxesFiltersProps> = props => {
  const { isFiltersOpen, search, tab, handleSearch } = props;

  const { formatMessage } = useIntl();

  const onHandleSearch = async ({ priceFrom, priceTo, ...restValues }: IVestingBoxInMarketSearchDto) => {
    await handleSearch({
      ...restValues,
      priceFrom: priceFrom || "0",
      priceTo: priceTo || "0",
    });
  };
  return (
    <CommonSearchForm
      key={tab}
      onSubmit={onHandleSearch}
      initialValues={search}
      open={isFiltersOpen}
      testId="VestingboxSearchForm"
    >
      <Grid2 container spacing={2} alignItems="flex-end">
        <Grid2 size={{ xs: 12, md: 4, lg: 4 }} className="BoxesFilters-grid">
          <EntityInput
            key={tab}
            multiple
            label=""
            variant="outlined"
            name={"durations"}
            controller={"vesting/boxes"}
            fetchPath={"autocompleteDuration"}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 4, lg: 4 }} className="BoxesFilters-grid">
          <EntityInput
            key={tab}
            multiple
            label=""
            variant="outlined"
            name={"symbols"}
            controller={"vesting/boxes"}
            fetchPath={"autocompletePriceTicker"}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 4, lg: 4 }} className="BoxesFilters-grid">
          <AutocompleteInput
            key={tab}
            multiple
            readOnly
            variant="outlined"
            name="vestingBoxCat"
            label=""
            options={Object.entries(VestingBoxCategory).map(([key, value]) => ({
              key,
              value: formatMessage({ id: `enums.vestingBoxBadge.${value}` }),
            }))}
          />
        </Grid2>
        <Grid2 container spacing={2} size={{ xs: 12, md: 6, lg: 6 }} alignItems="flex-end">
          <Grid2 size={{ xs: 12, md: 6, lg: 6 }} className="BoxesFilters-grid">
            <EthInput name={"priceFrom"} variant="outlined" />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 6, lg: 6 }} className="BoxesFilters-grid">
            <EthInput name={"priceTo"} variant="outlined" />
          </Grid2>
        </Grid2>
      </Grid2>
    </CommonSearchForm>
  );
};
