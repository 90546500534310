import { Outlet, RouteObject } from "react-router-dom";

import { PATHS } from "../../../../shared";
import { VestingBoxes } from "./boxes";
import { CurrentVestingBox } from "./current";
import { StepperProvider, VestingApplication } from "./vesting-application";

export const vestingRoutes: Array<RouteObject> = [
  {
    path: PATHS.VestingBoxesBase,
    element: (
      <>
        <Outlet />
      </>
    ),
    children: [{ path: PATHS.VestingBoxes, element: <VestingBoxes /> }],
  },
  {
    path: PATHS.CurrentBox,
    element: <CurrentVestingBox />,
  },
  {
    path: PATHS.VestingApplication,
    element: (
      <StepperProvider>
        <VestingApplication />
      </StepperProvider>
    ),
  },
];
