import { FormattedMessage } from "react-intl";

import { Root, StyledCardHeader } from "./styled";
import { howItWorksBlockData } from "./schemas";
import { StyledCard, StyledCardBox } from "../cards/styled";

export const HowItWorks = () => {
  return (
    <Root>
      <FormattedMessage id="pages.main.howItWorks.title" tagName="h1" />

      <StyledCardBox container mt={4} spacing={2}>
        {howItWorksBlockData.map(({ title, subtitle, icon }, index) => (
          <StyledCard key={title} index={index} size={{ lg: 6, md: 6, xs: 12 }}>
            <StyledCardHeader>
              {icon}
              <FormattedMessage id={title} tagName="h4" />
            </StyledCardHeader>
            {subtitle && <FormattedMessage id={subtitle} tagName="p" />}
          </StyledCard>
        ))}
      </StyledCardBox>
    </Root>
  );
};
