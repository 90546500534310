import React, { FC } from "react";
import { useIntl } from "react-intl";
import { Grid2, useMediaQuery, useTheme } from "@mui/material";

import { ProgressOverlay } from "@dzambalaorg/mui-page-layout";
import { DeleteDialog } from "@dzambalaorg/mui-dialog-delete";
import { CollectionActions, useCollection } from "@dzambalaorg/provider-collection";
import { IVestingBoxInMarketSearchDto } from "@framework/types";
import { StyledEmptyWrapper, StyledPagination } from "@framework/styled";

import {
  AssetsDashboardTabs,
  IVestingOfferResponse,
  PATHS,
  StyledGridTable,
  StyledGridTableHeader,
  LoadMoreButton,
  MOBILE_ITEMS_COUNT,
  DESKTOP_ITEMS_COUNT,
} from "../../../../shared";
import { useChangeAssetsTab } from "../useChangeAssetsTab";
import { OfferAsset } from "./components";
import { offersTableHeaderSchema } from "./schemas";

interface IOffersAssetsProps {
  variant: AssetsDashboardTabs;
}

export const OffersAssets: FC<IOffersAssetsProps> = props => {
  const { variant } = props;

  const { formatMessage } = useIntl();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const {
    rows,
    selected,
    search,
    count,
    action,
    isLoading,
    handleChangePage,
    handleChangePaginationModel,
    handleDelete,
    handleDeleteCancel,
    handleDeleteConfirm,
  } = useCollection<IVestingOfferResponse, IVestingBoxInMarketSearchDto>({
    baseUrl: "/vesting/offers",
    search: {
      take: isMd ? MOBILE_ITEMS_COUNT : DESKTOP_ITEMS_COUNT,
    },
    redirect: () => `${PATHS.DashboardBase}/${variant}`,
  });

  useChangeAssetsTab(variant);

  const getTitle = (selected: IVestingOfferResponse) => {
    if (Object.keys(selected).length === 0) return "";
    return selected.box.template!.title;
  };

  const loadMoreHandler = () => {
    handleChangePaginationModel({
      page: 0,
      pageSize: search.take * 2,
    });
  };

  return (
    <Grid2>
      <ProgressOverlay isLoading={isLoading}>
        {!isMd && (
          <StyledGridTable>
            <StyledGridTableHeader spacing={2} sx={{ fontWeight: 500 }}>
              {offersTableHeaderSchema.map((item, index, array) => {
                const isLastItem = index === array.length - 1;
                return (
                  <Grid2
                    sx={{ textAlign: index === 0 ? "left" : "right" }}
                    size={isLastItem ? 1 : 13 / array.length}
                    key={item}
                  >
                    {item ? formatMessage({ id: item }) : item}
                  </Grid2>
                );
              })}
            </StyledGridTableHeader>
            <StyledEmptyWrapper count={rows.length} isLoading={isLoading}>
              {rows.map(offer => (
                <OfferAsset key={offer.id} offer={offer} handleDelete={handleDelete} />
              ))}
            </StyledEmptyWrapper>
            <Grid2 size={12}>
              {!!count && count > search.take && (
                <StyledPagination
                  sx={{ marginTop: 0 }}
                  shape="rounded"
                  page={search.skip / search.take + 1}
                  count={Math.ceil(count / search.take)}
                  onChange={handleChangePage}
                />
              )}
            </Grid2>
          </StyledGridTable>
        )}
        {isMd && (
          <StyledEmptyWrapper count={rows.length} isLoading={isLoading}>
            {rows.map(offer => (
              <OfferAsset key={offer.id} offer={offer} isMd={isMd} handleDelete={handleDelete} />
            ))}
            {rows.length !== count && <LoadMoreButton loadMoreHandler={loadMoreHandler} />}
          </StyledEmptyWrapper>
        )}
      </ProgressOverlay>

      {!!count && (
        <DeleteDialog
          onCancel={handleDeleteCancel}
          onConfirm={handleDeleteConfirm}
          open={action === CollectionActions.delete}
          initialValues={selected}
          getTitle={getTitle}
          dialogMessage={"dialogs.closeOffer"}
        />
      )}
    </Grid2>
  );
};
