import React, { FC } from "react";
import { BootstrapInput } from "../styled";
import { InputBaseProps, Typography } from "@mui/material";
import { StyledTextFieldBox } from "./styled";

interface ICustomInputProps extends InputBaseProps {
  label?: string;
}

export const CustomInput: FC<ICustomInputProps> = props => {
  const { label, value, onChange, type = "number", placeholder = "", ...restProps } = props;
  return (
    <StyledTextFieldBox>
      {label && (
        <Typography variant="body1" component="h6">
          {label}
        </Typography>
      )}
      <BootstrapInput type={type} placeholder={placeholder} onChange={onChange} {...restProps} />
    </StyledTextFieldBox>
  );
};
