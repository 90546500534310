export const secondStepFormEmpty = {
  contactDetails: "",
  whoRefereed: "",
  keyQuestionA: "",
  keyQuestionB: "",
  keyQuestionC: "",
  tokenSymbol: "",
  tokenCirculation: "",
  tgeDeadlines: "",
  projectFocusOn: "",
  highPotential: "",
  painPoints: "",
  painSolutions: "",
  productAudience: "",
  projectBenefits: "",
  team: "",
  tokenUtility: "",
  tokenDistribution: "",
  productLaunched: "",
  numberOfUsers: "",
  tvl: "",
  totalVolume: "",
  nftSales: "",
  communitySize: "",
  communityDemography: "",
  communitySupport: "",
  innovations: "",
  streamsRevenue: "",
  isTeamWorking: "",
  partners: "",
  mainCompetitors: "",
  mainInnovations: "",
  isProjectOpen: "",
  extendingVesting: "",
  willingToLowerPrice: "",
  legalTokenDocs: [],
  constitutionalDocs: [],
  antiPhishingCode: "",
  confirmationDocs: [],
}
