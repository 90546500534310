import React, { FC, useEffect } from "react";
import { useIntl } from "react-intl";
import { useFormContext } from "react-hook-form";

import { FirebaseFileInput, ILoadedFile } from "@dzambalaorg/mui-inputs-file-firebase";

import { useStyles } from "./styles";
import { FileCard } from "./card";
import { StyledFileCardsBox } from "./styled";
import { acceptableFiles } from "./constants";

export interface IFileInputProps {
  name: string;
  initialValues?: any;
}

export const FileInput: FC<IFileInputProps> = props => {
  const { initialValues, name } = props;
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const form = useFormContext<any>();

  const resetForm = () => {
    form.reset(initialValues);
  };

  const handleChange = async (urls: Array<string>) => {
    try {
      form.setValue(name, urls, { shouldDirty: true });
    } catch (e) {
      console.error(e);
      form.reset(initialValues);
      form.setError(name, { type: "custom", message: "form.validations.badInput" });
    }
  };

  useEffect(() => {
    return () => resetForm();
  }, []);

  return (
    <FirebaseFileInput
      name={name}
      helperMessage={formatMessage({ id: "messages.uploadFiles" })}
      onChange={handleChange}
      classes={classes}
      minSize={0}
      maxFiles={3}
      accept={acceptableFiles}
      renderUploadedFiles={({ loadedFiles, handleDeleteLoadedFile }) => (
        <StyledFileCardsBox container size={12} spacing={1}>
          {loadedFiles.map((item: ILoadedFile) => (
            <FileCard key={item.url} loadedFile={item} onDeleteUrl={handleDeleteLoadedFile} />
          ))}
        </StyledFileCardsBox>
      )}
    />
  );
};
