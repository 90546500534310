import React, { SVGProps } from "react";
import { useIntl } from "react-intl";

import { MenuVariant, PATHS } from "../../../shared";

export interface IMenuItem {
  id: number;
  link?: string;
  title: string;
  children?: Array<IMenuItem>;
  external?: boolean;
  Svg?: (props: SVGProps<SVGSVGElement>) => React.JSX.Element;
}

const menuItemsByVariant = (
  formatMessage: any,
): {
  [Key in MenuVariant]: Array<IMenuItem>;
} => ({
  [MenuVariant.LANDING]: [
    {
      id: 1,
      link: "#metrics",
      title: formatMessage({ id: "components.header.menu.landing.metrics" }),
    },
    {
      id: 2,
      link: "#invest",
      title: formatMessage({ id: "components.header.menu.landing.invest" }),
    },
    {
      id: 3,
      link: "#partners",
      title: formatMessage({ id: "components.header.menu.landing.partners" }),
    },
    {
      id: 4,
      link: "#howItWorks",
      title: formatMessage({ id: "components.header.menu.landing.howItWorks" }),
    },
    {
      id: 5,
      link: "#faq",
      title: formatMessage({ id: "components.header.menu.landing.faq" }),
    },
  ],
  [MenuVariant.INNER]: [
    {
      id: 1,
      link: `${PATHS.Main}#aboutUs`,
      title: formatMessage({ id: "components.header.menu.inner.aboutUs" }),
    },
    {
      id: 2,
      link: `${PATHS.Main}#faq`,
      title: formatMessage({ id: "components.header.menu.inner.faq" }),
    },
    {
      id: 3,
      link: PATHS.VestingBoxes,
      title: formatMessage({ id: "components.header.menu.inner.trading" }),
    },
  ],
});

export const useMenuItems = (variant: MenuVariant): Array<IMenuItem> => {
  const { formatMessage } = useIntl();

  return menuItemsByVariant(formatMessage)[variant];
};
