import { BoxProps, Grid2, styled } from "@mui/material";
import { pageHeader } from "../../../../shared";

export const StyledCardBox = styled(Grid2)(() => ({
  maxWidth: "80%",
}));

interface IStyledCard {
  index: number;
}

export const StyledCard = styled(Grid2, {
  shouldForwardProp: prop => prop !== "index",
})<BoxProps & IStyledCard>(({ theme, index }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  backgroundColor: "rgb(255, 255, 255)",
  boxShadow:
    "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
  borderRadius: theme.spacing(1.25),
  textDecoration: "none",
  padding: theme.spacing(3, 4),

  "& h4": {
    ...pageHeader(),
    fontSize: theme.spacing(3),
    marginTop: theme.spacing(1),
    marginBottom: 0,
  },

  "& .MuiSvgIcon-root": {
    width: "50px",
    height: "50px",
  },

  opacity: 0,
  transform: "translateY(20px)",
  animation: `fadeInUp 0.8s ${0.1 * index + 1}s forwards`,
  "@keyframes fadeInUp": {
    "0%": {
      opacity: 0,
      transform: "translateY(20px)",
    },
    "100%": {
      opacity: 1,
      transform: "translateY(0)",
    },
  },
}));
