import React from "react";

import { Spinner } from "@dzambalaorg/mui-page-layout";

import { StyledCurrentRoot, StyledSpinnerBox } from "../../../shared";
import { useFetchToken } from "./useFetchToken";
import { Box } from "./box";

export const CurrentAssetBox = () => {
  const { selected, isLoading, variant } = useFetchToken();

  if (isLoading) {
    return (
      <StyledCurrentRoot isLoading={isLoading}>
        <StyledSpinnerBox>
          <Spinner />
        </StyledSpinnerBox>
      </StyledCurrentRoot>
    );
  }

  if (!selected || !selected.template) {
    // TODO - add error component
    return <div>Something went wrong!</div>;
  }

  return <Box selected={selected} variant={variant} />;
};
