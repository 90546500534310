import { Link, LinkProps, SxProps, styled } from "@mui/material";

import { IMenuVariant } from "../types";
import { dashboardMenuItem, MenuVariant } from "../../../../shared";

export interface IStyledLinkProps extends IMenuVariant {
  withBackground?: boolean;
  isActive?: boolean;
}

export const stylesByMenuVariant = ({
  theme,
  isActive,
  withBackground,
}: any): {
  [Key in MenuVariant]: SxProps;
} => ({
  [MenuVariant.LANDING]: {
    fontSize: theme.typography.pxToRem(12),
    textDecoration: isActive ? "underline" : "none",
    cursor: "pointer",
    background: isActive ? (withBackground ? theme.palette.primary.main : "transparent") : "inherit",
    color: theme.palette.text.primary,
    "&:hover": {
      textDecoration: "underline",
    },
    [theme.breakpoints.down("md")]: {
      color: theme.palette.text.primary,
      fontSize: theme.typography.pxToRem(20),
    },
  },
  [MenuVariant.INNER]: dashboardMenuItem(theme, isActive),
});

export const StyledLink = styled(Link, {
  shouldForwardProp: prop => prop !== "withBackground" && prop !== "isActive" && prop !== "variant",
})<LinkProps & IStyledLinkProps>(({ theme, isActive, withBackground, variant }) => {
  const styles = stylesByMenuVariant({ theme, isActive, withBackground });
  return styles[variant];
}) as any;
