import { Stepper, styled } from "@mui/material";
import { sizeDecreaseCalc } from "../../../../../../shared";

export const StyledStepper = styled(Stepper)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    alignItems: "flex-start",
  },

  "& .MuiStepLabel-root": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(1),

    "& .MuiStepLabel-labelContainer": {
      textAlign: "center",
      "& .MuiStepLabel-label": {
        fontSize: sizeDecreaseCalc(14, 10),
      },
    },
  },

  "& .Mui-active": {
    fill: "#ffd70e",

    "& .MuiStepIcon-text": {
      fill: "#000000",
    },
  },

  "& .Mui-completed": {
    fill: "#285513",

    "& .MuiStepIcon-text": {
      fill: "#000000",
    },
  },
}));
