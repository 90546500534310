import { FC } from "react";
import { Button, DialogContent, DialogTitle } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useWeb3React } from "@web3-react/core";

import { useUser } from "@dzambalaorg/provider-user";
import { useAppDispatch } from "@dzambalaorg/redux";
import { walletActions } from "@dzambalaorg/provider-wallet";

import { IDialogBase, PATHS } from "../../../shared";
import { CloseButton } from "../../common/close";
import { StyledDialog } from "../../styled";

export interface IWalletDialogProps extends IDialogBase {}

export const WalletMenuDialog: FC<IWalletDialogProps> = props => {
  const { onClose, open } = props;

  const user = useUser<any>();
  const { connector } = useWeb3React();
  const { setActiveConnector } = walletActions;
  const dispatch = useAppDispatch();

  const handleDisconnect = () => {
    if (connector?.deactivate) {
      void connector.deactivate();
    } else {
      void connector.resetState();
    }

    void user.logOut(PATHS.VestingBoxes);
    localStorage.clear();
    void dispatch(setActiveConnector(null));
    onClose();
  };

  return (
    <StyledDialog onClose={onClose} open={open}>
      <DialogTitle>
        <FormattedMessage tagName="span" id="components.header.wallet.menu" />
        <CloseButton onClick={onClose} color="#000000" />
      </DialogTitle>
      <DialogContent>
        <Button onClick={handleDisconnect}>
          <FormattedMessage tagName={"span"} id="form.buttons.disconnect" />
        </Button>
      </DialogContent>
    </StyledDialog>
  );
};
