import { Grid2, styled } from "@mui/material";

export const Root = styled(Grid2)(({ theme }) => ({
  width: "100%",
  display: "flex",

  [theme.breakpoints.down("md")]: {
    flexWrap: "wrap",
  },

  "& h4": {
    width: "fit-content",
    color: "#000000",
    fontSize: theme.spacing(3),
    fontWeight: 500,
    lineHeight: "140%",
    margin: 0,
    borderBottom: "1px solid #4BD4FF",
  },
}));

export const StyledDatesItem = styled(Grid2)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),

  "& span, .MuiTypography-body1": {
    margin: 0,
    fontSize: theme.spacing(2),
    color: "#5A5A5A",
    fontWeight: 500,
    lineHeight: "140%" /* 16.8px */,
  },

  "& .MuiTypography-body1": {
    color: "#1E293B",
  },

  "& .DateItem-text": {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: theme.spacing(2),
  },
}));
