import { generateBoxContent, TBoxContent } from "../../utils";
import { CalculationSlides } from "./types";
import { SPLIT_BOX_PRICE_COMMISSION } from "@framework/constants/dist/commissions";
import { CalculationCurrencyWithValue, CalculationData } from "../../types";

const calculateBoxesPrice = (
  originalBoxTokenPrice: number,
  sideBoxTokensCount: number,
  vendorAllowanceValue: number,
) => {
  const sideBaseBoxPrice = originalBoxTokenPrice * sideBoxTokensCount;
  const withBenefitsBoxPrice = sideBaseBoxPrice * (1 + vendorAllowanceValue / 100);
  const tokenPrice = withBenefitsBoxPrice / sideBoxTokensCount;

  return {
    boxPrice: withBenefitsBoxPrice,
    tokenPrice,
  };
};

export const splitCalculation = (
  calculationSlides: Required<CalculationSlides>,
  vendorAllowanceValue: number,
  config: TBoxContent,
): {
  leftBoxCalculation: CalculationData;
  rightBoxCalculation: CalculationData;
} => {
  const { tokenPrice, boxPrice } = generateBoxContent({
    price: config.price,
    content: config.content,
    duration: config.duration,
    cliff: config.cliff,
    afterCliffBasisPoints: config.afterCliffBasisPoints,
  });

  /** Boxes tokens count calculation **/
  const totalSplitCount =
    Number(config.content!.components[0].amount) / 1e18 - calculationSlides.releasable - calculationSlides.withdrawn;
  const leftBoxTokensCount = (totalSplitCount * calculationSlides.split) / 100;
  const rightBoxTokensCount = totalSplitCount - leftBoxTokensCount;

  /** Boxes prices calculation **/

  const priceTicker = tokenPrice.split(" ")[0] as "USDT" | "USDC";
  const originalBoxTokenPrice = Number(tokenPrice.split(" ")[1]);
  const originalBoxPrice = Number(boxPrice.split(" ")[1]);
  const commissionValue = SPLIT_BOX_PRICE_COMMISSION / 100;

  const { boxPrice: leftBoxPrice, tokenPrice: leftTokenPrice } = calculateBoxesPrice(
    originalBoxTokenPrice,
    leftBoxTokensCount,
    vendorAllowanceValue,
  );
  const { boxPrice: rightBoxPrice, tokenPrice: rightTokenPrice } = calculateBoxesPrice(
    originalBoxTokenPrice,
    rightBoxTokensCount,
    vendorAllowanceValue,
  );

  /** Boxes benefit **/
  const leftBoxBenefit = leftBoxPrice - originalBoxPrice - leftBoxPrice * commissionValue;

  const rightBoxBenefit = rightBoxPrice - originalBoxPrice - rightBoxPrice * commissionValue;

  /** Result box price connected with benefit **/
  const leftPlatformFee: number = leftBoxPrice - leftBoxPrice * (1 - commissionValue);
  let resultLeftBoxBenefit: CalculationCurrencyWithValue = `${priceTicker} ${leftBoxBenefit}`;

  const rightPlatformFee: number = rightBoxPrice - rightBoxPrice * (1 - commissionValue);
  let resultRightBoxBenefit: CalculationCurrencyWithValue = `${priceTicker} ${rightBoxBenefit}`;

  if (leftBoxBenefit < 0) {
    resultLeftBoxBenefit = "-";
  }

  if (rightBoxBenefit < 0) {
    resultRightBoxBenefit = "-";
  }

  return {
    leftBoxCalculation: {
      tokensCount: leftBoxTokensCount,
      tokenPrice: `${priceTicker} ${leftTokenPrice}`,
      boxPrice: `${priceTicker} ${leftBoxPrice}`,
      platformFee: `${leftPlatformFee}`,
      benefit: resultLeftBoxBenefit,
    },
    rightBoxCalculation: {
      tokensCount: rightBoxTokensCount,
      tokenPrice: `${priceTicker} ${rightTokenPrice}`,
      boxPrice: `${priceTicker} ${rightBoxPrice}`,
      platformFee: `${rightPlatformFee}`,
      benefit: resultRightBoxBenefit,
    },
  };
};

export function calculationSlidesMaxValues(calculationSlides: Required<CalculationSlides>): {
  [Key in keyof CalculationSlides]: number;
} {
  return {
    split: 100,
    releasable: 100 - calculationSlides.withdrawn,
    withdrawn: 100 - calculationSlides.releasable,
  };
}
