import React, { SVGProps } from "react";
import styled from "@emotion/styled";

interface ITabIconProps {
  isActive?: boolean;
}

const StyledBasicPath = styled.path<{ isActive: boolean }>`
  fill: ${props => (props.isActive ? "#FFFFFF" : "#4BD4FF")};
`;

const StyledArrowPath = styled.path<{ isActive: boolean }>`
  fill: ${props => (props.isActive ? "#000000" : "#FFFFFF")};
  transform: ${props => (props.isActive ? "rotate(180deg)" : "rotate(0deg)")};
  transform-origin: center; /* Убедитесь, что вращение происходит вокруг центра элемента */
  transition: transform 0.3s ease; /* Плавная анимация */
`;

export const TabIcon = (props: SVGProps<SVGSVGElement> & ITabIconProps) => {
  const { width = 29, height = 32, isActive = false } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 29 32" fill="none">
      <g clipPath="url(#clip0_5080_1307)">
        <g filter="url(#filter0_bii_5080_1307)">
          <StyledBasicPath
            isActive={isActive}
            d="M23.1871 4.57532C25.666 6.04939 26.9055 6.78643 27.5861 8.00053C28.2667 9.21463 28.2667 10.6887 28.2667 13.6368L28.2667 18.3689C28.2667 21.3144 28.2667 22.7871 27.5871 23.9997C26.9076 25.2123 25.6698 25.9483 23.1944 27.4203L19.2056 29.7922C16.7302 31.2642 15.4924 32.0002 14.133 32C12.7736 31.9998 11.5353 31.2634 9.05859 29.7907L5.07959 27.4247C2.60065 25.9506 1.36117 25.2136 0.680587 23.9995C-1.97647e-09 22.7854 -2.34916e-08 21.3113 -1.58516e-09 18.3632L3.35772e-08 13.6311C5.54635e-08 10.6856 7.32591e-08 9.2129 0.679536 8.00029C1.35907 6.78768 2.59682 6.05167 5.07231 4.57965L9.06102 2.20783C11.5365 0.735814 12.7743 -0.000193228 14.1337 3.73967e-08C15.4931 0.000193303 16.7314 0.736553 19.2081 2.20927L23.1871 4.57532Z"
            fill="#FFFFFF"
          />
        </g>
        <g filter="url(#filter1_f_5080_1307)">
          <path
            d="M20.2872 16.7039L20.2872 11.6986L14.1333 15.4526L7.97949 11.6986L7.97949 16.0783L14.1333 20.3015L20.2872 16.7039Z"
            fill="#FFFFFF"
          />
        </g>
        <StyledArrowPath
          isActive={isActive}
          d="M20.2872 16.7039L20.2872 11.6986L14.1333 15.4526L7.97949 11.6986L7.97949 16.0783L14.1333 20.3015L20.2872 16.7039Z"
          fill="#000000"
        />
      </g>
      <defs>
        <filter
          id="filter0_bii_5080_1307"
          x="-7.46667"
          y="-7.46667"
          width="43.1999"
          height="46.9333"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="3.73333" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_5080_1307" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_5080_1307" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.533333" dy="0.533333" />
          <feGaussianBlur stdDeviation="0.266667" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.42 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_5080_1307" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.533333" dy="-0.533333" />
          <feGaussianBlur stdDeviation="0.266667" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0588235 0 0 0 0 0.890196 0 0 0 0 0.898039 0 0 0 0.42 0" />
          <feBlend mode="normal" in2="effect2_innerShadow_5080_1307" result="effect3_innerShadow_5080_1307" />
        </filter>
        <filter
          id="filter1_f_5080_1307"
          x="5.84616"
          y="9.5654"
          width="16.5743"
          height="12.8694"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="1.06667" result="effect1_foregroundBlur_5080_1307" />
        </filter>
        <clipPath id="clip0_5080_1307">
          <rect width="32" height="28.2667" fill="white" transform="translate(0 32) rotate(-90)" />
        </clipPath>
      </defs>
    </svg>
  );
};
