import React from "react";
import { Grid2 } from "@mui/material";

import { useFetchAssetsInfo } from "./useFetchAssetsInfo";
import { StyledGridBlock } from "./styled";
import { LockedAssets } from "./components/LockedAssets";
import { UnlockedAssets } from "./components/UnlockedAssets";
import { WithdrawalAssets } from "./components/WithdrawalAssets";
import { Spinner } from "@dzambalaorg/mui-page-layout";

export const AssetsInfo = () => {
  const { assetsInfo, isLoading } = useFetchAssetsInfo();

  if (isLoading) {
    return (
      <Grid2 display="flex" flexDirection="column" alignItems="center" justifyContent="flex-start" mt={10}>
        <Spinner />
      </Grid2>
    );
  }

  return (
    <Grid2 container spacing={2}>
      <StyledGridBlock size={{ xl: 4.5, lg: 4.5, md: 4.5, sm: 12, xs: 12 }}>
        <LockedAssets assetsInfo={assetsInfo} />
      </StyledGridBlock>
      <Grid2 container gap={2} flexDirection="column" size={{ xl: 7.5, lg: 7.5, md: 7.5, sm: 12, xs: 12 }}>
        <StyledGridBlock>
          <UnlockedAssets assetsInfo={assetsInfo} />
        </StyledGridBlock>
        <StyledGridBlock>
          <WithdrawalAssets assetsInfo={assetsInfo} />
        </StyledGridBlock>
      </Grid2>
    </Grid2>
  );
};
