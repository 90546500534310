import React from "react";

import { Spinner } from "@dzambalaorg/mui-page-layout";

import { StyledCurrentRoot, StyledSpinnerBox } from "../../../../../shared";
import { useFetchToken } from "./useFetchToken";
import { CurrentBox } from "./box";

export const CurrentUserBox = () => {
  const { selected, isLoading } = useFetchToken();

  if (isLoading) {
    return (
      <StyledCurrentRoot isLoading={isLoading}>
        <StyledSpinnerBox>
          <Spinner />
        </StyledSpinnerBox>
      </StyledCurrentRoot>
    );
  }

  if (!selected || !selected.template) {
    // TODO - add error component
    return <div>Something went wrong!</div>;
  }

  return <CurrentBox selected={selected} />;
};
