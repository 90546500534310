import { RouteObject } from "react-router-dom";

import { Protected } from "@dzambalaorg/common-pages";

import { PATHS } from "../../../../shared";
import { VestingBoxes } from "./boxes";
import { CurrentVestingBox } from "./current";
import { CurrentUserBox } from "./current-user-box";
import { StepperProvider, VestingApplication } from "./vesting-application";

export const vestingRoutes: Array<RouteObject> = [
  {
    path: PATHS.VestingBoxes,
    element: <VestingBoxes />,
  },
  {
    path: PATHS.CurrentBox,
    element: <CurrentVestingBox />,
  },
  {
    path: PATHS.VestingApplication,
    element: (
      <StepperProvider>
        <VestingApplication />
      </StepperProvider>
    ),
  },
  {
    path: PATHS.Token,
    element: (
      <Protected>
        <CurrentUserBox />
      </Protected>
    ),
  },
];
