import React, { SVGProps } from "react";

export const Clock = ({ width = 40, height = 40 }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="clock">
        <path
          id="union-1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.0002 6.66683C12.6364 6.66683 6.66683 12.6364 6.66683 20.0002C6.66683 27.364 12.6364 33.3335 20.0002 33.3335C27.364 33.3335 33.3335 27.364 33.3335 20.0002C33.3335 12.6364 27.364 6.66683 20.0002 6.66683ZM3.3335 20.0002C3.3335 10.7954 10.7954 3.3335 20.0002 3.3335C29.2049 3.3335 36.6668 10.7954 36.6668 20.0002C36.6668 29.2049 29.2049 36.6668 20.0002 36.6668C10.7954 36.6668 3.3335 29.2049 3.3335 20.0002ZM20.0002 10.0002C20.9206 10.0002 21.6668 10.7464 21.6668 11.6668V18.3335H28.3335C29.254 18.3335 30.0002 19.0797 30.0002 20.0002C30.0002 20.9206 29.254 21.6668 28.3335 21.6668H20.0002C19.0797 21.6668 18.3335 20.9206 18.3335 20.0002V11.6668C18.3335 10.7464 19.0797 10.0002 20.0002 10.0002Z"
          fill="#4BD4FF"
        />
      </g>
    </svg>
  );
};
