import { FC } from "react";

import { IconButton } from "@mui/material";
import { Instagram, Telegram, X } from "@mui/icons-material";

import { StyledLink, StyledWrapper } from "./styled";

export const SocialLinks: FC = () => {
  return (
    <StyledWrapper>
      <StyledLink href="https://www.instagram.com/project_alphax" target="_blank">
        <IconButton
          sx={{
            color: "#ffffff",
            opacity: 0.4,
            width: "8px",
          }}
        >
          <Instagram />
        </IconButton>
      </StyledLink>
      <StyledLink href="https://x.com/projectalphax" target="_blank">
        <IconButton
          sx={{
            color: "#ffffff",
            opacity: 0.4,
            width: "8px",
          }}
        >
          <X />
        </IconButton>
      </StyledLink>
      <StyledLink href="https://t.me/alphaxannouncements" target="_blank">
        <IconButton
          sx={{
            color: "#ffffff",
            opacity: 0.4,
            width: "8px",
          }}
        >
          <Telegram />
        </IconButton>
      </StyledLink>
    </StyledWrapper>
  );
};
