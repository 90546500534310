import React, { SVGProps } from "react";

export const CliffShape = ({ width = "66", height = "28" }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={width} height={height} viewBox="0 0 66 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M65.5 27.9999V0.5L18 17.9999V27.9999H65.5Z" fill="url(#paint0_linear_2749_3858)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4841 18.3417L65.4999 1.00006L65.4999 5.73562e-05L17.5159 17.6584L17.5159 26.4846L0.581619 26.4846L0.439359 27.5154L18.4841 27.5154L18.4841 18.3417Z"
        fill="#71FFFF"
      />
      <defs>
        <linearGradient id="paint0_linear_2749_3858" x1="65" y1="1" x2="65" y2="30" gradientUnits="userSpaceOnUse">
          <stop stopColor="#71FFFF" stopOpacity="0.6" />
          <stop offset="1" stopColor="#0F1D2C" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
