import { Box, styled } from "@mui/material";

export const Root = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  gap: theme.spacing(1),
  marginTop: theme.spacing(3),

  "& h4": {
    width: "fit-content",
    color: "#000000",
    fontSize: theme.spacing(2.5),
    fontWeight: 500,
    lineHeight: "140%",
    margin: 0,
    borderBottom: "1px solid #4BD4FF",
  },
})) as typeof Box;
