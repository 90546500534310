import SafetyCheckOutlinedIcon from '@mui/icons-material/SafetyCheckOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import ShowChartOutlinedIcon from '@mui/icons-material/ShowChartOutlined';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import PollOutlinedIcon from '@mui/icons-material/PollOutlined';

export const aboutBlockData = [
  {
    title: "pages.main.about.experienceTitle",
    subtitle: "pages.main.about.experienceSubtitle",
    icon: <SafetyCheckOutlinedIcon sx={{color: "#2563eb"}}/>,
  },
  {
    title: "pages.main.about.liquidityTitle",
    subtitle: "pages.main.about.liquiditySubtitle",
    icon: <MonetizationOnOutlinedIcon sx={{color: "#22c55e"}}/>,
  },
  {
    title: "pages.main.about.profitScheduleTitle",
    subtitle: "pages.main.about.profitScheduleSubtitle",
    icon: <BarChartOutlinedIcon sx={{color: "#a955f7"}}/>,
  },
  {
    title: "pages.main.about.pricesTitle",
    subtitle: "pages.main.about.pricesSubtitle",
    icon: <ShowChartOutlinedIcon sx={{color: "#ef4445"}}/>,
  },
  {
    title: "pages.main.about.protocolsTitle",
    subtitle: "pages.main.about.protocolsSubtitle",
    icon: <CodeOutlinedIcon sx={{color: "#eab208"}}/>,
  },
  {
    title: "pages.main.about.accessTitle",
    subtitle: "pages.main.about.accessSubtitle",
    icon: <VpnKeyOutlinedIcon sx={{color: "#6365f1"}}/>,
  },
  {
    title: "pages.main.about.walletConnection",
    icon: <AccountBalanceWalletOutlinedIcon sx={{color: "#f97516"}}/>,
  },
  {
    title: "pages.main.about.analytics",
    icon: <PollOutlinedIcon sx={{color: "#14b8a5"}}/>,
  },
];
