import { FC, MouseEvent } from "react";
import { FormattedMessage } from "react-intl";
import { Button, DialogContent, DialogTitle } from "@mui/material";

import { IDialogBase } from "../../../shared";
import { CloseButton } from "../../common/close";
import { StyledDialog } from "../../styled";
import { agreementData } from "./constants";
import { StyledAgreementDataBox, StyledButtonsBox } from "./styled";

interface IAgreementDialog extends IDialogBase {
  handleAgreement: (isConsent: boolean) => Promise<void>;
}

export const AgreementDialog: FC<IAgreementDialog> = props => {
  const { onClose, open, handleAgreement } = props;

  const onClickHandler = (event: MouseEvent<HTMLButtonElement>) => {
    const isConsent = !!event.currentTarget.dataset.agree;
    void handleAgreement(isConsent);
  };

  return (
    <StyledDialog onClose={onClose} open={open}>
      <DialogTitle>
        <FormattedMessage tagName="span" id="dialogs.userAgreement.title" />
        <CloseButton onClick={onClose} color="#000000" />
      </DialogTitle>
      <DialogContent>
        <FormattedMessage tagName="p" id="dialogs.userAgreement.description" />
        {Object.values(agreementData).map(({ title, paragraphs }) => (
          <StyledAgreementDataBox key={title}>
            <FormattedMessage tagName="h4" id={title} />
            {Object.values(paragraphs).map(paragraph => (
              <FormattedMessage key={paragraph} tagName="p" id={paragraph} />
            ))}
          </StyledAgreementDataBox>
        ))}
        <StyledButtonsBox>
          <Button className="AgreementDialog-AgreeBtn" data-agree={true} onClick={onClickHandler}>
            <FormattedMessage tagName={"span"} id="dialogs.userAgreement.agreeBtn" />
          </Button>
          <Button className="AgreementDialog-Cancel" onClick={onClickHandler}>
            <FormattedMessage tagName={"span"} id="form.buttons.cancel" />
          </Button>
        </StyledButtonsBox>
      </DialogContent>
    </StyledDialog>
  );
};
