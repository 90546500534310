import React, { ChangeEvent, useState } from "react";
import { useIntl } from "react-intl";

import { StyledTab, StyledTabs } from "./styled";
import { TabIcon } from "../../assets";
import { TabMenuProps, TabMode } from "./types";
import { useTabMenu } from "./useTabMenu";
import { useDidMountEffect } from "@dzambalaorg/react-hooks";

export { TabMode };

export const TabMenu = <T extends Record<string, string>, M extends TabMode>(props: TabMenuProps<T, M>) => {
  const { tabs, initialTab, basicLocale } = props;

  const { handleChangeTab, setInitialTab, pathParams, searchValues } = useTabMenu(props);
  const [tab, setTab] = useState(() => setInitialTab(initialTab));
  const { formatMessage } = useIntl();

  const handleChange = (_event: ChangeEvent<any>, newValue: T[keyof T]) => {
    if (newValue === tab) {
      return;
    }
    setTab(newValue);
    handleChangeTab(newValue);
  };

  useDidMountEffect(() => {
    setTab(setInitialTab(initialTab));
  }, [searchValues, pathParams]);
  return (
    <StyledTabs value={tab} indicatorColor="primary" textColor="primary" onChange={handleChange}>
      {Object.values(tabs).map(tabItem => (
        <StyledTab
          disableRipple
          icon={<TabIcon isActive={tabItem === tab} />}
          iconPosition="start"
          key={tabItem}
          label={<span>{formatMessage({ id: `${basicLocale}.${tabItem}` })}</span>}
          value={tabItem}
        />
      ))}
    </StyledTabs>
  );
};
