import React, { FC } from "react";
import { useIntl } from "react-intl";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

import { IVestingBox } from "@framework/types";
import { StyledAvatar } from "@framework/styled";

import {
  BoxContentLeftSide,
  fixedTokenWithTicker,
  generateBoxContent,
  StyledContentRightSide,
  TOKEN_DISPLAYING_IN_CARD_FIX_VALUE,
} from "../../../../../../shared";
import { Root, StyledContent } from "../styled";
import { StyledContentPrices, StyledContentPricesItem } from "./styled";
import { CURRENT_BOX_MEDIA_QUERY } from "./constants";
import { ReleaseBox } from "./release-box";
import { ProgressBars } from "./progress-bars";

interface ICurrentBoxContent {
  selected: IVestingBox;
  tokenId?: string | null;
}

export const CurrentBoxContent: FC<ICurrentBoxContent> = props => {
  const { selected, tokenId } = props;

  const theme = useTheme();
  const isMedia920 = useMediaQuery(theme.breakpoints.down(CURRENT_BOX_MEDIA_QUERY));
  const { formatMessage } = useIntl();

  const { tokensCount, tokenPrice, boxPrice } = generateBoxContent({
    price: selected.template?.price,
    content: selected.content,
    duration: selected.duration,
    cliff: selected.cliff,
    afterCliffBasisPoints: selected.afterCliffBasisPoints,
  });

  const numericTokensCount = Number(tokensCount.split(" ")[1]);
  const tokenPriceTicker = tokenPrice.split(" ")[0];
  const splitTokenPrice = Number(tokenPrice.split(" ")[1]).toFixed(4);

  return (
    <Root>
      <StyledContent container spacing={2} direction={"column"}>
        <BoxContentLeftSide
          tokenImageUrl={selected.content!.components[0].template!.imageUrl}
          tokensCount={fixedTokenWithTicker(tokensCount, TOKEN_DISPLAYING_IN_CARD_FIX_VALUE)}
          tooltipTextId="pages.vesting.currentBox.tokensInfo"
          subtitle={formatMessage({ id: "pages.vesting.currentBox.tokensCount" })}
        />

        <StyledContentRightSide size={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
          <ProgressBars vestingBox={selected} totalTokensCount={numericTokensCount} tokenId={tokenId} />

          <ReleaseBox vestingBox={selected} />
        </StyledContentRightSide>
      </StyledContent>
      <StyledContentPrices>
        <StyledContentPricesItem>
          <StyledAvatar src={selected.template!.price!.components[0].template!.imageUrl} />
          <Box className="PricesItem-content">
            <Typography variant="body1" component="p">
              {`${formatMessage({ id: "pages.vesting.currentBox.tokenPrice" })}`}
            </Typography>
            <Typography variant="body2" component="p">
              {`${tokenPriceTicker} ${splitTokenPrice}`}
            </Typography>
          </Box>
        </StyledContentPricesItem>
        <StyledContentPricesItem>
          <StyledAvatar src={selected.template!.price!.components[0].template!.imageUrl} />
          <Box className="PricesItem-content">
            <Typography variant="body1" component="p">
              {`${formatMessage({ id: "pages.vesting.currentBox.boxPrice" })}`}
            </Typography>
            <Typography variant="body2" component="p">
              {fixedTokenWithTicker(boxPrice, TOKEN_DISPLAYING_IN_CARD_FIX_VALUE)}
            </Typography>
          </Box>
        </StyledContentPricesItem>
      </StyledContentPrices>
    </Root>
  );
};
