import { SxProps, Theme } from "@mui/material";
import { StylesScheme } from "../types";
import { sizeDecreaseCalc } from "../utils";

export const buttonStyles: SxProps = {
  color: "#000000",
  textAlign: "center",
  fontSize: "14px",
  fontWeight: 500,
  padding: "12px 8px 12px 8px",
  borderRadius: "8px",

  "&:hover": {
    background: "rgba(79, 121, 134, 0.4)",
  },
};

export const firebaseLoginContainerStyles: SxProps = {
  "& .FirebaseLogin-buttonsBox": {
    "& button": {
      ...buttonStyles,
    },
  },
};

export const descriptionStyles = (theme: Theme): SxProps => ({
  margin: 0,
  color: "#000000",
  fontSize: theme.spacing(1.75),
  fontWeight: 400,
  lineHeight: "140%" /* 19.6px */,
});

export const dashboardMenuItem = (theme: any, isActive?: boolean): SxProps => ({
  textAlign: "center",
  display: "flex",
  alignItem: "center",
  gap: theme.spacing(1.25),
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 500,
  textDecoration: "none",
  cursor: "pointer",
  color: isActive ? "#4BD4FF" : "#F5F5F5",

  "& #fill": {
    fill: isActive ? "#4BD4FF" : "#F5F5F5",
  },
  "& #stroke": {
    stroke: isActive ? "#4BD4FF" : "#F5F5F5",
  },

  "&:hover": {
    color: "#4BD4FF",
    "& #fill": {
      fill: "#4BD4FF",
    },
    "& #stroke": {
      stroke: "#4BD4FF",
    },
  },
  [theme.breakpoints.down("md")]: {
    fontSize: theme.typography.pxToRem(18),
  },
});

export const cardBasic: StylesScheme = {
  background: "linear-gradient(151deg, rgba(12, 71, 79, 0.12) -1.66%, rgba(12, 101, 104, 0.12) 81.87%)",
  boxShadow: "0px 0px 11px 0px rgba(21, 174, 184, 0.34) inset, 0px 0px 3px 0px rgba(255, 255, 255, 0.50) inset",
  backdropFilter: "blur(22px)",
};

export const pageHeader = (): StylesScheme => ({
  fontSize: sizeDecreaseCalc(32, 18),
  fontWeight: 700,
  lineHeight: "140%",
  letterSpacing: "-0.12px",
  color: "#000000",
});
