import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import { VestingBoxBadge, VestingBoxBadgeValues } from "../../../shared";

export interface IRootProps {
  isItem?: boolean;
  category: VestingBoxBadge;
}

const getBackgroundColor = (category: VestingBoxBadge) => {
  switch (category) {
    case VestingBoxBadgeValues.PROMISING:
      return "#1e90ff";
    case VestingBoxBadgeValues.SUCCESSFUL:
      return "#2e8b57";
    case VestingBoxBadgeValues.TRENDING:
      return "#8a2be2";
    case VestingBoxBadgeValues.MEME:
      return "#ff8c00";
    case VestingBoxBadgeValues.STARTING:
      return "#FFAE42";
    case VestingBoxBadgeValues.SPLIT:
      return "#1e5938";
    default:
      return "transparent";
  }
};

export const Root = styled(Box, { shouldForwardProp: prop => prop !== "category" && prop !== "isItem" })<IRootProps>(({
  isItem,
  category,
  theme,
}) => {
  return {
    ...(isItem
      ? {
          position: "relative",
          top: -10,
          left: 0,
          marginLeft: 5,
          marginRight: 5,
        }
      : {
          position: "absolute",
          top: 30,
          right: -32,
          transform: "rotate(45deg)",
        }),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 150,
    borderRadius: 4,
    paddingLeft: 5,
    paddingRight: 5,
    color: theme.palette.common.white,
    backgroundColor: getBackgroundColor(category),
  };
});
