import { Box, styled } from "@mui/material";

import { sizeDecreaseCalc } from "../../utils";

export const StyledCurrentPageHeader = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: theme.spacing(2),
  margin: theme.spacing(2.5, 0, 2, 0),

  "& .MuiTypography-root": {
    fontSize: sizeDecreaseCalc(26, 16),
  },

  "& .MuiGrid-container": {
    margin: 0,
  },

  "& .MuiGrid-item": {
    display: "flex",
    justifyContent: "flex-end",
  },

  "& .MuiIconButton-root": {
    padding: 0,
    width: sizeDecreaseCalc(32, 20),
    height: sizeDecreaseCalc(32, 20),
  },

  "& h2": {
    fontSize: theme.spacing(3.25),
  },
}));
