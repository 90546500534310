import { Grid2, Grid2Props, styled } from "@mui/material";

interface IOfferAssetItem {
  isFirst?: boolean;
}

export const OfferAssetItem = styled(Grid2, {
  shouldForwardProp: prop => prop !== "isFirst",
})<Grid2Props & IOfferAssetItem>(({ theme, isFirst }) => ({
  textAlign: isFirst ? "left" : "right",

  "& svg": {
    color: "#FF0000",
  },

  "& span": {
    color: "#000",
    fontSize: theme.spacing(2),
    fontWeight: 600,
    lineHeight: "130%" /* 20.8px */,
  },

  "& .MuiButtonBase-root": { padding: 0 },

  "& .CloseOffer-btn": {
    minWidth: "100px",
    background: "#FF0000",
    border: "1px solid #A00000",
    color: "#FFFFFF",
    fontSize: theme.spacing(1.5),
    textTransform: "none",
    borderRadius: theme.spacing(3.75),
    padding: theme.spacing(0.75, 1),
    "&:hover": {
      background: "#9a0101",
    },
  },

  [theme.breakpoints.down("md")]: {
    textAlign: "left",

    "& svg": {
      width: 16,
      height: 16,
      color: "#FFFFFF",
    },
  },
}));
