import { VestingBoxCategory } from "@framework/types";

export const getBackgroundImg = (category: VestingBoxCategory) => {
  switch (category) {
    case VestingBoxCategory.PROMISING:
      return "/files/inner/promising_box.png";
    case VestingBoxCategory.SUCCESSFUL:
      return "/files/inner/successful_box.png";
    case VestingBoxCategory.TRENDING:
      return "/files/inner/trending_box.png";
    case VestingBoxCategory.MEME:
      return "/files/inner/meme_box.png";
    default:
      return "/files/inner/vesting.webp";
  }
};
