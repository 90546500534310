import { Grid2, Grid2Props, styled, Theme } from "@mui/material";

import { ContentMode, StylesScheme } from "../../types";
import { contentSideFlex } from "./index";

export interface IStyledContentLeftSideProps {
  mode: ContentMode;
}

const leftSideStylesByMode = (theme: Theme): Record<ContentMode, StylesScheme> => ({
  [ContentMode.BOX]: {
    ...contentSideFlex,
    flexDirection: "row",
    justifyContent: "space-between",
    gap: theme.spacing(5),

    "& .LeftSide-AvatarBox": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: theme.spacing(2.5),
    },

    "& .LeftSide-InfoBox": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: theme.spacing(1),
    },
  },
  [ContentMode.ASSET]: {
    ...contentSideFlex,
    gap: theme.spacing(1.5),

    "& .LeftSide-AvatarBox": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: theme.spacing(2.5),
    },

    "& .LeftSide-InfoBox": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: theme.spacing(1),
    },
  },
});

export const StyledContentLeftSide = styled(Grid2, {
  shouldForwardProp: prop => prop !== "mode",
})<IStyledContentLeftSideProps & Grid2Props>(({ theme, mode }) => ({
  ...leftSideStylesByMode(theme)[mode],

  "& .MuiTypography-body1": {
    color: "#000000",
    fontSize: theme.spacing(4),
    fontWeight: 700,
  },

  "& .MuiTypography-body2": {
    color: "#676767",
    fontSize: theme.spacing(2),
    fontWeight: 500,
    lineHeight: "20%",
  },
}));
