import React, { SVGProps } from "react";

export const ButtonBack = ({ width = 32, height = 32 }: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32" fill="none">
      <rect width="32" height="32" rx="16" transform="matrix(-1 0 0 1 32 0)" fill="white" />
      <rect width="32" height="32" rx="16" transform="matrix(-1 0 0 1 32 0)" stroke="white" />
      <rect width="32" height="32" rx="16" transform="matrix(-1 0 0 1 32 0)" fill="#4BD4FF" />
      <path d="M18 10L12 16L18 22" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
