import { Root } from "./styled";
import { MainPageCards } from "./cards";
import { MainPageHeader } from "./header";
import { HowItWorks } from "./howItWorks";
import { Faq } from "./faq";

export const Main = () => {
  return (
    <Root>
      <MainPageHeader />
      <MainPageCards />
      <HowItWorks />
      <Faq />
    </Root>
  );
};
