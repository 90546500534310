import React, { FC, SyntheticEvent, useState } from "react";
import { StyledAccordion, StyledAccordionDetails, StyledAccordionSummary } from "./styled";
import { TabIcon } from "../../assets";
import { useTheme } from "@mui/material";

export enum CustomAccordionVariant {
  FILLED = "FILLED",
  OUTLINED = "OUTLINED",
}

interface ICustomAccordionProps {
  title: React.ReactNode;
  description: React.ReactNode;
  variant?: CustomAccordionVariant;
  defaultExpanded?: boolean;
}

const isActiveByVariant = (expanded: boolean) => ({
  [CustomAccordionVariant.FILLED]: true,
  [CustomAccordionVariant.OUTLINED]: expanded,
});

export const CustomAccordion: FC<ICustomAccordionProps> = props => {
  const { title, description, variant = CustomAccordionVariant.FILLED, defaultExpanded = false } = props;
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded);
  const theme = useTheme();

  const toggleExpanded = () => (_event: SyntheticEvent) => {
    setExpanded(prevState => !prevState);
  };
  return (
    <StyledAccordion
      sx={{
        "& .MuiAccordionSummary-root": {
          padding: variant === CustomAccordionVariant.OUTLINED ? theme.spacing(1.5, 1) : theme.spacing(1),
        },
      }}
      disableGutters
      elevation={0}
      expanded={expanded}
      onChange={toggleExpanded()}
    >
      <StyledAccordionSummary
        variant={variant}
        expandIcon={<TabIcon isActive={isActiveByVariant(expanded)[variant]} />}
      >
        {title}
      </StyledAccordionSummary>
      <StyledAccordionDetails expanded={expanded}>{description}</StyledAccordionDetails>
    </StyledAccordion>
  );
};
