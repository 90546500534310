import React, { FC } from "react";
import { Grid2, Grid2Props } from "@mui/material";

import { StyledRoot } from "./styled";

interface IStyledGridTableProps extends Grid2Props {}

export const StyledGridTable: FC<IStyledGridTableProps> = props => {
  const { container: _container, size = 12, children, ...restProps } = props;

  return (
    <StyledRoot container size={size} {...restProps}>
      {children}
    </StyledRoot>
  );
};

export const StyledGridTableHeader: FC<IStyledGridTableProps> = props => {
  const { container: _container, size = 12, children, ...restProps } = props;
  return (
    <Grid2 container size={size} {...restProps}>
      {children}
    </Grid2>
  );
};
