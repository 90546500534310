import {
  Accordion,
  AccordionDetails,
  AccordionDetailsProps,
  AccordionSummary,
  accordionSummaryClasses,
  AccordionSummaryOwnProps,
  styled,
} from "@mui/material";

import { CustomAccordionVariant } from "./index";
import { StylesScheme } from "../../types";

export const StyledAccordion = styled(Accordion)(() => ({
  width: "100%",
  transition: "all 0.3s linear",
  background: "transparent",

  "&::before": {
    display: "none",
  },
}));

export const StyledAccordionDetails = styled(AccordionDetails, { shouldForwardProp: prop => prop !== "expanded" })<
  AccordionDetailsProps & { expanded: boolean }
>(({ theme, expanded }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  gap: theme.spacing(1.5),
  color: "#000000",
  opacity: 0.8,
  fontSize: theme.spacing(2),
  fontWeight: 400,
  lineHeight: "160%",
  borderLeft: "1px solid #4BD4FF",
  borderRight: "1px solid #4BD4FF",
  borderBottom: "1px solid #4BD4FF",
  borderBottomLeftRadius: expanded ? theme.spacing(1.5) : 0,
  borderBottomRightRadius: expanded ? theme.spacing(1.5) : 0,
  padding: theme.spacing(1, 1.5),
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },

  "& p": {
    margin: 0,
  },
}));

interface IStyledAccordionSummary {
  variant: CustomAccordionVariant;
}

const styledAccordionSummaryStylesByVariant = (): Record<CustomAccordionVariant, StylesScheme> => ({
  [CustomAccordionVariant.FILLED]: {
    background: "#4BD4FF",
    flexDirection: "row-reverse",
  },
  [CustomAccordionVariant.OUTLINED]: {
    flexDirection: "row",
    background: "transparent",
    border: "1px solid #4BD4FF",
  },
});

export const StyledAccordionSummary = styled(AccordionSummary, { shouldForwardProp: prop => prop !== "variant" })<
  AccordionSummaryOwnProps & IStyledAccordionSummary
>(({ theme, variant }) => ({
  ...styledAccordionSummaryStylesByVariant()[variant],
  display: "flex",
  alignItems: "center",
  color: "#000000",
  opacity: 0.8,
  fontSize: theme.spacing(2),
  fontWeight: 500,
  lineHeight: "130%",
  letterSpacing: "-0.075px",
  borderRadius: theme.spacing(1.5),

  [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]: {
    transform: variant === CustomAccordionVariant.FILLED ? "rotate(180deg)" : "none",
  },

  "&.Mui-expanded": {
    backgroundColor: "#4BD4FF",
    borderTopLeftRadius: theme.spacing(1.5),
    borderTopRightRadius: theme.spacing(1.5),
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },

  "& .MuiAccordionSummary-content": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    textAlign: "center",
    margin: 0,
    paddingLeft: variant === CustomAccordionVariant.FILLED ? theme.spacing(1.5) : 0,
    [theme.breakpoints.down("sm")]: {},
  },
}));
