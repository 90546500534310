export const PATHS = {
  Main: "/",
  Login: "/login",
  VestingBoxesBase: "/vesting",
  VestingBoxes: "/vesting/:tab",
  CurrentBox: "/vesting/:tab/:boxId",
  VestingApplication: "/vesting/application",
  VestingCreate: "/vesting/create",
  DashboardBase: "/assets-dashboard",
  Dashboard: "/assets-dashboard/:tab",
  Asset: "/asset/:tokenId/:variant",
  Error: "/error/:error",
  Message: "/message/:message",
};
