import { Box, Card, CardActionArea, CardActions, styled } from "@mui/material";
import { sizeDecreaseCalc } from "../../utils";

export const Root = styled(Card)(({ theme }) => ({
  position: "relative",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  backgroundColor: "rgb(255, 255, 255)",
  boxShadow:
    "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
  borderRadius: theme.spacing(1.25),
  textDecoration: "none",
  cursor: "pointer",

  "& .MuiCardActionArea-root": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(1),

    "& .MuiCardHeader-root": {
      padding: 0,
    },
  },

  "& .MuiCardHeader-title": {
    fontSize: theme.spacing(3),
    fontWeight: 600,
    lineHeight: "140%",
    letterSpacing: "-0.12px",
    color: "#1A1818",
    padding: 0,
  },

  "& .MuiButton-outlinedPrimary": {
    color: "rgb(25, 118, 210)",
    border: "1px solid rgb(25, 118, 210)",

    "& .MuiSvgIcon-root path": {
      fill: "rgb(25, 118, 210)",
    },
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "rgba(25, 118, 210, 0.04)",
    },
  },

  "& span": {
    textAlign: "center",
    fontSize: theme.spacing(1.5),
    fontWeight: 400,
    lineHeight: "140%",
    letterSpacing: "-0.12px",
    color: theme.palette.text.secondary,
  },
})) as typeof Card;

export const StyledAvatar = styled(Box)(() => ({
  display: "block",
  borderRadius: "50%",
})) as typeof Box;

export const StyledCardActionArea = styled(CardActionArea)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: theme.spacing(1),
  overflow: "hidden",
  position: "relative",
})) as typeof CardActionArea;

export const StyledCardActions = styled(CardActions)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  padding: theme.spacing(1.5, 2.5, 2.5, 2.5),

  "& .MuiBox-root": {
    margin: 0,
  },

})) as typeof CardActions;

export const StyledCardActionsInfo = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  "& .CardTopInfoItem-subtitle": {
    color: "#878079",
    fontSize: theme.spacing(1.5),
    fontWeight: "500",
  },

  "& .CardTopInfoItem-bold": {
    color: "#000000",
    fontSize: sizeDecreaseCalc(24, 16),
    fontWeight: "500",
  },

  "& .CardTopInfoItem-semibold": {
    color: "#000000",
    fontSize: theme.spacing(2),
    fontWeight: "500",
  },

  "& .CardTopInfoItem-green": {
    color: "#9DDD6B",
    fontSize: sizeDecreaseCalc(24, 16),
    fontWeight: "500",
  },

  "& .CardTopInfo-item": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(0.5),

    "& .MuiSvgIcon-root": {
      width: "12px !important",
      height: "12px !important",

      color: "#448327",
    },
  },
})) as typeof Box;

export const StyledCardActionsTopInfoRight = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: theme.spacing(1),

  "& .CardTopInfo-item": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(0.5),

    "& .MuiSvgIcon-root": {
      width: "12px !important",
      height: "12px !important",

      color: "#448327",
    },
  },
})) as typeof Box;
