import { Box, Grid2, styled } from "@mui/material";

export const StyledCalculationBoxRoot = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  gap: theme.spacing(1),
  background: "#FFFFFF",
  border: "1px solid #84CCFF",
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1.5),

  "& h6": {
    width: "100%",
    color: "#000000",
    fontSize: theme.spacing(2.5),
    fontWeight: 500,
    lineHeight: "130%",
    margin: 0,
  },

  "& p, span": {
    margin: 0,
    color: "#5A5A5A",
    fontSize: theme.spacing(2),
    fontWeight: 500,
    lineHeight: "140%" /* 16.8px */,
  },

  "& span": {
    color: "#000000",
  },
}));

export const StyledCalculationItem = styled(Grid2)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),

  "& span, .MuiTypography-body1": {
    margin: 0,
    fontSize: theme.spacing(2),
    color: "#5A5A5A",
    fontWeight: 500,
    lineHeight: "140%" /* 16.8px */,
  },

  "& .MuiTypography-body1, p": {
    color: "#1E293B",
  },

  "& .CalculationItem-text": {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: theme.spacing(2),
  },
}));
