import React, { FC } from "react";
import { BigNumber } from "ethers";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";

import { IAssetInfo } from "@framework/types";
import { formatUnitsR } from "@framework/exchange";

import { AMOUNT_FIX_VALUE, CustomAccordion, CustomAccordionVariant } from "../../../../../shared";
import { LockedAssetsContainer } from "../styled";

interface ILockedAssetsProps {
  assetsInfo: Array<IAssetInfo>;
}

export const LockedAssets: FC<ILockedAssetsProps> = props => {
  const { assetsInfo } = props;

  return (
    <React.Fragment>
      <Box>
        <FormattedMessage id="pages.tokens.assets.locked.title" tagName="h2" />
        <FormattedMessage id="pages.tokens.assets.locked.description" tagName="span" />
      </Box>

      <LockedAssetsContainer>
        {assetsInfo.map((asset, index) => (
          <CustomAccordion
            key={asset.symbol}
            variant={CustomAccordionVariant.OUTLINED}
            title={<LockedAssetsAccordionSummary asset={asset} />}
            description={<LockedAssetsAccordionContent asset={asset} />}
            defaultExpanded={index === 0}
          />
        ))}
      </LockedAssetsContainer>
    </React.Fragment>
  );
};

const LockedAssetsAccordionSummary = (props: { asset: IAssetInfo }) => {
  const { asset } = props;
  return (
    <Box className="LockedAsset-AccordionSummary">
      <Box className="LockedAsset-AccordionSummarySide">
        <Typography variant="body1" component="h2">
          {asset.title}
        </Typography>
        <Box className="AccordionSummary-boxTicker">{asset.symbol}</Box>
      </Box>
    </Box>
  );
};

const LockedAssetsAccordionContent = (props: { asset: IAssetInfo }) => {
  const { asset } = props;

  const totalTokensValue = Number(formatUnitsR(BigNumber.from(String(asset.amount)), 18)).toFixed(AMOUNT_FIX_VALUE);

  return (
    <Box className="LockedAsset-AccordionContent">
      <FormattedMessage
        id="pages.tokens.assets.locked.tokensValueDescription"
        tagName="span"
        values={{ strong: chunks => <strong>{chunks}</strong>, value: totalTokensValue }}
      />
      <FormattedMessage id="pages.tokens.assets.locked.tokensTitle" tagName="h6" />
      <Box className="AccordionContent-boxes">
        {asset.boxes.map(box => (
          <Box
            component={Link}
            to={`/asset/${box.id}/box`}
            className="boxTag"
            key={box.id}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography variant="body1" component="p">
              {box.title}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
