import React, { FC } from "react";
import { useIntl } from "react-intl";
import { Grid2 } from "@mui/material";

import { ISimpleButtonProps, SimpleButton } from "../../../components/buttons/simple";

interface ILoadMoreButton extends ISimpleButtonProps {
  loadMoreHandler: () => void;
}

export const LoadMoreButton: FC<ILoadMoreButton> = props => {
  const { loadMoreHandler, ...restProps } = props;
  const { formatMessage } = useIntl();

  return (
    <Grid2
      sx={{
        "& .Mui-disabled": {
          color: "#e1e9ef",
        },
      }}
      size={12}
      mt={2}
      display="flex"
      justifyContent="center"
    >
      <SimpleButton onClick={loadMoreHandler} {...restProps}>
        {formatMessage({ id: "form.buttons.loadMore" })}
      </SimpleButton>
    </Grid2>
  );
};
