import { SPLIT_BOX_PRICE_COMMISSION } from "@framework/constants";

import { UseAssetBox } from "../useAssetBox";

export const calculateOfferInfo = (assetBoxData: UseAssetBox, vendorAllowanceValue: number) => {
  const { boxPriceValue, numericBoxPriceCount, numericTokenCount, withDrawn } = assetBoxData;

  const commissionValue = SPLIT_BOX_PRICE_COMMISSION / 100;

  const boxPriceWithBenefit = numericBoxPriceCount * (1 + vendorAllowanceValue / 100);
  const tokenPrice = boxPriceWithBenefit / (numericTokenCount - withDrawn);

  const platformFee: number = boxPriceWithBenefit - boxPriceWithBenefit * (1 - commissionValue);
  let benefit: string | number = boxPriceWithBenefit - numericBoxPriceCount - boxPriceWithBenefit * commissionValue;

  if (benefit < 0) {
    benefit = "-";
  }

  return {
    tokensCount: assetBoxData.numericTokenCount - withDrawn,
    tokenPrice: `${boxPriceValue} ${tokenPrice}`,
    boxPrice: `${boxPriceValue} ${boxPriceWithBenefit}`,
    platformFee: `${platformFee}`,
    benefit,
  };
};
