import React, { SVGProps } from "react";

export const CalendarDay = ({ width = 40, height = 40 }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="calendar-day">
        <path
          id="union-1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5002 1.6665C13.4206 1.6665 14.1668 2.4127 14.1668 3.33317V4.99984H25.8335V3.33317C25.8335 2.4127 26.5797 1.6665 27.5002 1.6665C28.4206 1.6665 29.1668 2.4127 29.1668 3.33317V5.04098C33.3779 5.45909 36.6668 9.01205 36.6668 13.3332V28.3332C36.6668 32.9355 32.9359 36.6665 28.3335 36.6665H11.6668C7.06446 36.6665 3.3335 32.9355 3.3335 28.3332V13.3332C3.3335 9.01205 6.62239 5.45909 10.8335 5.04098V3.33317C10.8335 2.4127 11.5797 1.6665 12.5002 1.6665ZM6.95134 11.6665H33.049C32.3626 9.72452 30.5105 8.33317 28.3335 8.33317H11.6668C9.4898 8.33317 7.63773 9.72452 6.95134 11.6665ZM33.3335 14.9998H6.66683V28.3332C6.66683 31.0946 8.90541 33.3332 11.6668 33.3332H28.3335C31.0949 33.3332 33.3335 31.0946 33.3335 28.3332V14.9998ZM10.0002 19.9998C10.0002 19.0794 10.7464 18.3332 11.6668 18.3332H18.3335C19.254 18.3332 20.0002 19.0794 20.0002 19.9998V26.6665C20.0002 27.587 19.254 28.3332 18.3335 28.3332H11.6668C10.7464 28.3332 10.0002 27.587 10.0002 26.6665V19.9998ZM16.6668 21.6665H13.3335V24.9998H16.6668V21.6665Z"
          fill="#4BD4FF"
        />
      </g>
    </svg>
  );
};
