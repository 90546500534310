import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Grid2 } from "@mui/material";
import { DeleteOutlined } from "@mui/icons-material";

import { ListAction, ListActionVariant } from "@framework/styled";

import {
  fixedTokenWithTicker,
  formatDateFromSpecialValue,
  generateBoxContent,
  IVestingOfferResponse,
  TOKEN_PERCENTAGE_FIX_VALUE,
  useVestingContractInfo,
} from "../../../../../shared";
import { OfferAssetRoot } from "../../styled";
import { OfferAssetItem } from "../styled";
import { offersTableHeaderSchema } from "../schemas";

interface IOfferAssetProps {
  offer: IVestingOfferResponse;
  handleDelete: (item: IVestingOfferResponse) => () => void;
  isMd?: boolean;
}

export const OfferAsset: FC<IOfferAssetProps> = props => {
  const { offer, handleDelete, isMd } = props;
  const { box, tokenId, createdAt } = offer;

  const { tokensCount, boxPrice } = generateBoxContent({
    price: box.template?.price,
    content: box.content,
    duration: box.duration,
    cliff: box.cliff,
    afterCliffBasisPoints: box.afterCliffBasisPoints,
  });

  const numericTokensCount = Number(tokensCount.split(" ")[1]);
  const { releasable, releasablePercentage } = useVestingContractInfo(
    tokenId || "",
    box.template!.contract!.address,
    numericTokensCount,
  );

  const displayingBoxPrice = fixedTokenWithTicker(boxPrice, TOKEN_PERCENTAGE_FIX_VALUE);
  const displayingTokensCount = fixedTokenWithTicker(tokensCount, TOKEN_PERCENTAGE_FIX_VALUE);
  const displayingReleasable = releasable.toFixed(TOKEN_PERCENTAGE_FIX_VALUE);
  const displayingReleasablePercentage = releasablePercentage.toFixed(TOKEN_PERCENTAGE_FIX_VALUE);
  const displayingDate = formatDateFromSpecialValue(createdAt, true);

  const displayingData = [
    box.template!.title,
    displayingBoxPrice,
    displayingTokensCount,
    `${displayingReleasable} (${displayingReleasablePercentage}%)`,
    displayingDate,
    <ListAction
      key={"close_offer"}
      onClick={handleDelete(offer)}
      message="pages.tokens.closeOffer"
      className={"CloseOffer-btn"}
      dataTestId="CloseOfferButton"
      icon={DeleteOutlined}
      variant={!isMd ? ListActionVariant.iconButton : ListActionVariant.button}
    />,
  ];

  return (
    <OfferAssetRoot className="TableRow-root" container size={12} spacing={2}>
      {displayingData.map((dataItem, index, array) => {
        const isLastItem = index === array.length - 1;
        return !isMd ? (
          <OfferAssetItem isFirst={index === 0} size={isLastItem ? 1 : 13 / array.length} key={index}>
            {dataItem}
          </OfferAssetItem>
        ) : (
          <OfferAssetItem container size={12} key={index} spacing={2}>
            {!isLastItem && (
              <Grid2 size={6}>
                <FormattedMessage id={offersTableHeaderSchema[index]} tagName="span" />
              </Grid2>
            )}
            <Grid2 sx={{ textAlign: "right" }} size={isLastItem ? 12 : 6}>
              {dataItem}
            </Grid2>
          </OfferAssetItem>
        );
      })}
    </OfferAssetRoot>
  );
};
