import React, { SVGProps } from "react";

export const BcSeparatorSvg = ({ width = "12", height = "13", color }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={width} height={height} viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.57184 1.5L2.50502 1.5L5.55514 6.5L2.50502 11.5L6.06349 11.5L9.49487 6.5L6.57184 1.5Z"
        fill={color || "#0FC3C4"}
      />
    </svg>
  );
};
