import React, { SVGProps } from "react";

import styled from "@emotion/styled";

const StyledPath = styled.path`
  fill: #141734;
  fill-opacity: 0.12;
  &:hover {
    fill: #103a3f;
    fill-opacity: 0.6;
  }
`;
export const HexagonSvg = ({ width = "40", height = "46" }: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 40 46" fill="none">
      <g filter="url(#filter0_bii_2065_3324)">
        <StyledPath d="M32.8171 6.57806C36.3256 8.69737 38.0798 9.75703 39.0431 11.5026C40.0063 13.2481 40.0063 15.3674 40.0063 19.606L40.0063 26.4095C40.0063 30.6442 40.0063 32.7616 39.0445 34.505C38.0828 36.2484 36.331 37.3066 32.8274 39.423L27.1821 42.833C23.6785 44.9493 21.9267 46.0075 20.0027 46.0072C18.0787 46.007 16.3261 44.9483 12.8208 42.8309L7.18924 39.4292C3.68074 37.3099 1.92649 36.2502 0.963247 34.5047C-2.05771e-08 32.7591 -4.98776e-08 30.6398 -1.65726e-08 26.4012L3.68858e-08 19.5977C7.01603e-08 15.363 9.64959e-08 13.2456 0.961759 11.5022C1.92352 9.75882 3.67532 8.70064 7.17894 6.58429L12.8242 3.17425C16.3278 1.0579 18.0796 -0.000277814 20.0036 4.79347e-08C21.9276 0.00027791 23.6802 1.05896 27.1855 3.17633L32.8171 6.57806Z" />
      </g>
      <defs>
        <filter
          id="filter0_bii_2065_3324"
          x="-16"
          y="-16"
          width="72.0063"
          height="78.0073"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="8" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2065_3324" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2065_3324" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.999842" dy="0.999842" />
          <feGaussianBlur stdDeviation="0.499921" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.42 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_2065_3324" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.999842" dy="-0.999842" />
          <feGaussianBlur stdDeviation="0.499921" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0588235 0 0 0 0 0.890196 0 0 0 0 0.898039 0 0 0 0.42 0" />
          <feBlend mode="normal" in2="effect2_innerShadow_2065_3324" result="effect3_innerShadow_2065_3324" />
        </filter>
      </defs>
    </svg>
  );
};
