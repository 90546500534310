import React, { FC } from "react";
import { addDays, format } from "date-fns";
import { FormattedMessage, useIntl } from "react-intl";
import { Area, Bar, Brush, ComposedChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { IVestingBox } from "@framework/types";

import { formatXAxis, generateBoxContent } from "../../utils";
import { useRenderPlot } from "../../hooks";
import { Root, StyledPlotBox } from "./styled";
import { Direction } from "./types";
import { monthlyPlots } from "./constants";

interface IPlotProps {
  vestingBox: IVestingBox;
  startTimestamp: string;
  height?: number;
  startDirection?: Direction;
  infoPosition?: "top" | "bottom";
}

/**
 * This code removes error about XAxis default props. We can't handle this error and can only remove from console
 * at this moment
 * See: https://github.com/recharts/recharts/issues/3615
 * **/
const error = console.error;
console.error = (...args: any) => {
  if (/defaultProps/.test(args[0])) return;
  error(...args);
};

const brushTickerFormatter = (startTimeStamp: string, value?: number) => {
  if (!value) return "";

  const date = new Date(startTimeStamp);
  const newDate = addDays(date, value);
  return format(newDate, "dd/MM/yy");
};

export const Plot: FC<IPlotProps> = props => {
  const { vestingBox, startTimestamp, height = 300, startDirection = "row", infoPosition = "top" } = props;
  const { formatMessage } = useIntl();

  const { tokensCount } = generateBoxContent({
    price: vestingBox.template?.price,
    content: vestingBox.content,
    duration: vestingBox.duration,
    cliff: vestingBox.cliff,
    afterCliffBasisPoints: vestingBox.afterCliffBasisPoints,
  });

  const { data } = useRenderPlot({
    shape: vestingBox?.shape,
    cliff: vestingBox?.cliff,
    duration: vestingBox?.duration,
    period: vestingBox?.period,
    afterCliffBasisPoints: vestingBox?.afterCliffBasisPoints,
    growthRate: vestingBox?.growthRate,
    startTimestamp,
  });

  const numericTokensCount = Number(tokensCount.split(" ")[1]);

  const isMonthlyPlot = monthlyPlots.includes(vestingBox.shape);

  return (
    <Root direction={startDirection}>
      {infoPosition === "top" && (
        <FormattedMessage id={`pages.vesting.currentBox.aboutVesting.${vestingBox.shape}`} tagName="p" />
      )}
      <StyledPlotBox direction={startDirection}>
        <ResponsiveContainer width={"100%"} minHeight={height}>
          <ComposedChart data={data} barGap={0} barCategoryGap={0}>
            <XAxis
              dataKey="x"
              label={{ position: "insideBottom", offset: 0 }}
              tickFormatter={formatXAxis}
              tick={{ fontSize: 12, fontWeight: "bold" }}
            />
            <YAxis
              domain={[0, 100]}
              label={{ value: "%", position: "insideLeft" }}
              tick={{ fontSize: 14, fontWeight: "bold" }}
            />
            <Tooltip
              formatter={value => {
                const customValue = `${formatMessage({ id: "pages.vesting.currentBox.plotYValueName" })}: ${(numericTokensCount * Number(value)) / 100}`;
                return [customValue];
              }}
            />
            {!isMonthlyPlot && (
              <Area
                type="monotone"
                dataKey="y"
                name={formatMessage({ id: "pages.vesting.currentBox.plotYValueName" })}
                stroke={"#71D7F8"}
                fill={"#4BD4FF"}
                dot={false}
              />
            )}
            {isMonthlyPlot && (
              <Bar
                dataKey="y"
                name={formatMessage({ id: "pages.vesting.currentBox.plotYValueName" })}
                stroke={"#71D7F8"}
                fill={"#4BD4FF"}
              />
            )}
            <Brush
              height={30}
              stroke={"#2775CA"}
              fill={"#AEC8E4"}
              travellerWidth={15}
              tickFormatter={value => brushTickerFormatter(startTimestamp, value)}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </StyledPlotBox>
      {infoPosition === "bottom" && (
        <FormattedMessage id={`pages.vesting.currentBox.aboutVesting.${vestingBox.shape}`} tagName="p" />
      )}
    </Root>
  );
};
