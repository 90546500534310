import { array, object, string } from "yup";

import { urlValidationSchema } from "@dzambalaorg/yup-rules";

export const validationSchema = object().shape({
  contactDetails: string().required("form.validations.valueMissing"),
  whoRefereed: string().required("form.validations.valueMissing"),
  keyQuestionA: string().required("form.validations.valueMissing"),
  keyQuestionB: string().required("form.validations.valueMissing"),
  keyQuestionC: string().required("form.validations.valueMissing"),
  tokenSymbol: string().required("form.validations.valueMissing"),
  tokenCirculation: string().required("form.validations.valueMissing"),
  tgeDeadlines: string().required("form.validations.valueMissing"),
  projectFocusOn: string().required("form.validations.valueMissing"),
  highPotential: string(),
  painPoints: string().required("form.validations.valueMissing"),
  painSolutions: string().required("form.validations.valueMissing"),
  productAudience: string().required("form.validations.valueMissing"),
  projectBenefits: string().required("form.validations.valueMissing"),
  team: string().required("form.validations.valueMissing"),
  tokenUtility: string().required("form.validations.valueMissing"),
  tokenDistribution: string().required("form.validations.valueMissing"),
  productLaunched: string().required("form.validations.valueMissing"),
  numberOfUsers: string().required("form.validations.valueMissing"),
  tvl: string(),
  totalVolume: string(),
  nftSales: string(),
  communitySize: string().required("form.validations.valueMissing"),
  communityDemography: string().required("form.validations.valueMissing"),
  communitySupport: string().required("form.validations.valueMissing"),
  innovations: string().required("form.validations.valueMissing"),
  streamsRevenue: string().required("form.validations.valueMissing"),
  isTeamWorking: string().required("form.validations.valueMissing"),
  partners: string().required("form.validations.valueMissing"),
  mainCompetitors: string().required("form.validations.valueMissing"),
  mainInnovations: string().required("form.validations.valueMissing"),
  isProjectOpen: string().required("form.validations.valueMissing"),
  extendingVesting: string().required("form.validations.valueMissing"),
  willingToLowerPrice: string().required("form.validations.valueMissing"),
  legalTokenDocs: array().of(string()),
  constitutionalDocs: array().of(urlValidationSchema),
  antiPhishingCode: string().required("form.validations.valueMissing"),
  confirmationDocs: array().of(string()),
});
