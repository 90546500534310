import { Accordion, AccordionDetails, AccordionSummary, Box, styled } from "@mui/material";
import { pageHeader } from "../../../../shared";
import { fadedAnimation, flexStyles } from "../styles";

export const Root = styled(Box)(({ theme }) => ({
  ...flexStyles,
  width: "100vw",
  gap: theme.spacing(5),
  background: "#F2F2F2",

  "& h1": {
    ...pageHeader(),
    fontSize: theme.spacing(4.5),
    marginTop: theme.spacing(5),
    marginBottom: 0,
    textAlign: "center",
    ...fadedAnimation(""),
  },
}));

export const StyledContent = styled(Box)(({ theme }) => ({
  ...flexStyles,
  width: "80%",
  gap: 5,

  padding: theme.spacing(0, 7.5, 0, 7.5),

  borderRadius: theme.spacing(3),

  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));
export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  padding: theme.spacing(4, 0),
  transition: "all 0.3s linear",
  background: "transparent",

  "&.MuiAccordion-root:not(:last-child)": {
    borderBottom: "1px solid",
  },
  "&.Mui-expanded": {
    paddingTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2),
  },
  "&::before": {
    display: "none",
  },
}));

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  fontSize: theme.spacing(2),
  fontWeight: 400,
  lineHeight: "160%",
  padding: 0,
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },

  "& p": {
    margin: 0,
  },
}));

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(4),
  fontSize: theme.spacing(3),
  fontWeight: 700,
  lineHeight: "140%",
  letterSpacing: "-0.075px",
  padding: 0,
  zIndex: 10,

  "& .MuiAccordionSummary-content": {
    alignSelf: "center",
    margin: 0,
    [theme.breakpoints.down("sm")]: {},
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    alignSelf: "center",

    "& svg": {
      [theme.breakpoints.down("sm")]: {
        width: 28,
        height: 32,
      },
    },
  },
}));
