import { FC } from "react";
import { Grid } from "@mui/material";

import { PageHeader, ProgressOverlay } from "@dzambalaorg/mui-page-layout";
import { useCollection } from "@dzambalaorg/provider-collection";
import { StyledEmptyWrapper, StyledPagination } from "@framework/styled";
import type { ITemplate, IToken, ITokenSearchDto, IVestingBox } from "@framework/types";

import { TokenCard, PATHS, StyledListRoot } from "../../../shared";

export interface IMyTokenListProps {
  embedded?: boolean;
}

type Token = IToken & {
  template: ITemplate & { box: IVestingBox };
};

export const MyTokensList: FC<IMyTokenListProps> = props => {
  const { embedded } = props;

  const { rows, count, search, isLoading, handleChangePage } = useCollection<Token, ITokenSearchDto>({
    baseUrl: "/tokens/search",
    embedded,
    redirect: () => PATHS.Tokens,
  });

  return (
    <StyledListRoot>
      <PageHeader message="pages.tokens.title" />

      <ProgressOverlay isLoading={isLoading}>
        <Grid container spacing={2}>
          <StyledEmptyWrapper count={rows.length} isLoading={isLoading}>
            {rows.map(token => (
              <Grid item lg={4} md={6} sm={12} xs={12} key={token.id}>
                <TokenCard template={token.template} token={token} />
              </Grid>
            ))}
          </StyledEmptyWrapper>
        </Grid>
      </ProgressOverlay>

      {!!count && count > search.take && (
        <StyledPagination
          shape="rounded"
          page={search.skip / search.take + 1}
          count={Math.ceil(count / search.take)}
          onChange={handleChangePage}
        />
      )}
    </StyledListRoot>
  );
};
