import {
  Box,
  Grid2,
  LinearProgress,
  linearProgressClasses,
  LinearProgressProps,
  styled,
  SxProps,
  Theme,
} from "@mui/material";

import { contentSideFlex, pageHeader, sizeDecreaseCalc } from "../../../../shared";

const assetContainerStyles: (theme: Theme) => SxProps = theme => ({
  display: "flex",
  flexDirection: "column",
  borderRadius: theme.spacing(1),
  border: "1px solid #84CCFF",
  padding: theme.spacing(1, 1.5),
});

const boxTickerStyles: (theme: Theme) => SxProps = theme => ({
  fontWeight: 500,
  lineHeight: "130%",
  background: "#F5F5F5",
  borderRadius: theme.spacing(2),
  paddingTop: sizeDecreaseCalc(4, 2, "px"),
  paddingBottom: sizeDecreaseCalc(4, 2, "px"),
  paddingInline: sizeDecreaseCalc(18, 8, "px"),
});

export const StyledGridBlock = styled(Grid2)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  rowGap: theme.spacing(1.25),
  background: "#FFFFFF",
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1.5, 1.25),

  "& h2, .MuiTypography-body1": {
    ...pageHeader(),
    fontSize: sizeDecreaseCalc(22, 16),
    margin: 0,
  },

  "& span": {
    margin: 0,
    fontSize: sizeDecreaseCalc(14, 12),
    color: "#5A5A5A",
    fontWeight: 600,
    lineHeight: "140%",
  },

  "& h6": {
    margin: 0,
    fontSize: theme.spacing(2),
    fontWeight: 600,
    lineHeight: "140%",
  },

  "& .LockedAsset-AccordionSummary": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginRight: theme.spacing(1),

    "& .AccordionSummary-boxTicker": {
      ...boxTickerStyles(theme),
    },

    "& .LockedAsset-AccordionSummarySide": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: theme.spacing(1),
    },
  },

  "& .LockedAsset-AccordionContent": {
    "& strong": {
      color: theme.palette.text.primary,
    },

    "& .AccordionContent-boxes": {
      marginTop: theme.spacing(1),
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      gap: theme.spacing(1),

      "& .boxTag": {
        cursor: "pointer",
        padding: theme.spacing(0.5, 1),
        borderRadius: theme.spacing(2),
        border: "1px solid #4BD4FF",
        textDecoration: "none",

        "&:hover": {
          border: "1px solid #4BD4FF",
          boxShadow: "0 0 4px #4BD4FF",
        },

        "& p": {
          margin: 0,
          fontSize: theme.spacing(1.75),
          fontWeight: 500,
          lineHeight: "140%",
        },
      },
    },
  },

  "& .UnlockedAsset-Container": {
    ...assetContainerStyles(theme),

    "& .UnlockedAsset-ContainerHeader": {
      ...contentSideFlex,
      flexDirection: "row",
      justifyContent: "space-between",
      gap: theme.spacing(1),
      marginBottom: theme.spacing(2),

      "& .UnlockedAsset-boxTicker": {
        ...boxTickerStyles(theme),
      },
    },

    "& strong": {
      color: theme.palette.text.primary,
    },
  },

  "& .WithdrawalAsset-Container": {
    ...assetContainerStyles(theme),
    border: "1px solid transparent",
    padding: theme.spacing(0),

    "& .WithdrawalAsset-ContainerHeader": {
      width: "100%",
      ...contentSideFlex,
      flexDirection: "row",
      justifyContent: "space-between",

      "& .WithdrawalAsset-ContainerHeaderSide": {
        ...contentSideFlex,
        flexDirection: "row",
        justifyContent: "flex-start",
        gap: theme.spacing(1),
      },

      "& .WithdrawalAsset-boxTicker": {
        ...boxTickerStyles(theme),
      },
    },
  },
}));

export const WithdrawalLinearProgress = styled(LinearProgress)<LinearProgressProps>(({ theme }) => ({
  width: "100%",
  height: 12,
  backgroundColor: "transparent",
  borderRadius: theme.spacing(1),
  border: "1px solid #AEC8E4",
  marginTop: "8px !important",
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: "#4BD4FF",
  },
}));

export const LockedAssetsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}));
