import { Box, styled } from "@mui/material";

import { pageHeader } from "../../styles";
import { sizeDecreaseCalc } from "../../utils";

export const StyledListRoot = styled(Box)(({ theme }) => ({
  minHeight: "100vh",

  "& h2": {
    ...pageHeader(),
  },

  "& .MuiPaginationItem-root": {
    background: "rgba(0, 0, 0, 0.25)",
    color: "rgba(0, 0, 0, 0.87)",
  },

  "& form > .MuiGrid2-container > .MuiGrid2-root": {
    paddingTop: 0,

    "& .MuiPaper-elevation": {
      maxHeight: "48px",
      height: sizeDecreaseCalc(48, 38, "px"),
      borderRadius: theme.spacing(1),
      boxShadow: "none",
      marginTop: 0,
      marginBottom: theme.spacing(2),

      "&:hover": {
        border: "1px solid #4BD4FF",
        boxShadow: "0 0 4px #4BD4FF",
      },
    },
  },

  "& .BoxesFilters-grid": {
    "& .MuiAutocomplete-root": {
      margin: 0,
    },

    "& .MuiChip-root": {
      backgroundColor: "#1B5DA5",

      "& .MuiChip-label": {
        color: "#FFFFFF",
      },

      "& .MuiSvgIcon-root": {
        fill: "#FFFFFF",
      },
    },
  },

  "& .MuiOutlinedInput-root": {
    maxHeight: "48px",
    height: sizeDecreaseCalc(48, 38, "px"),
    borderRadius: theme.spacing(1),
    backgroundColor: "#FFFFFF",

    "& .MuiOutlinedInput-input": {
      maxHeight: "48px",
      height: sizeDecreaseCalc(48, 0, "px"),
    },

    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #4BD4FF",
      boxShadow: "0 0 4px #4BD4FF",
    },

    "& .DateInput-CloseIcon": {
      width: "20px",
      height: "20px",
    },
    "& svg": {
      width: "20px",
      height: "20px",
      cursor: "pointer",
    },
  },

  "& .MuiFormControl-root": {
    margin: 0,
  },
}));
