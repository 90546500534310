import { Box, styled } from "@mui/material";

import { buttonStyles, pageHeader, sizeDecreaseCalc } from "../../../shared";

export const StyledAgreementDataBox = styled(Box)(() => ({
  "& h4": {
    ...pageHeader(),
    fontSize: sizeDecreaseCalc(18, 16),
  },
  "& p": {
    fontSize: sizeDecreaseCalc(16, 14),
    fontWeight: 400,
    lineHeight: "140%",
    letterSpacing: "-0.12px",
  },
}));

export const StyledButtonsBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItem: "center",
  justifyContent: "flex-start",
  gap: theme.spacing(2),
  marginTop: theme.spacing(3),

  "& .MuiButton-root": {
    ...buttonStyles,
    textTransform: "none",
  },

  "& .AgreementDialog-AgreeBtn": {
    background: "#1B5DA5",
    color: "#FFFFFF",

    "&:hover": {
      background: "#2775CA",
    }
  },

  "& .AgreementDialog-Cancel": {
    background: "#e41b1b",
    color: "#FFFFFF",

    "&:hover": {
      background: "#fd1d1d",
    }
  },
}));
