import { FC, Fragment } from "react";
import { useNavigate } from "react-router";
import { Web3ContextType } from "@web3-react/core";

import { useApi } from "@dzambalaorg/provider-api";
import { useAllowance, useMetamask, useSystemContract } from "@dzambalaorg/react-hooks-eth";
import { ListAction, ListActionVariant } from "@framework/styled";
import { ContractType, IContract, ICreateOfferDto, ITemplate, TokenType } from "@framework/types";
import { convertTemplateToChainAsset } from "@framework/exchange";

interface ISplitTokenButtonProps {
  offer: ICreateOfferDto;
  template: ITemplate;
  className?: string;
  disabled?: boolean;
  variant?: ListActionVariant;
}

export const MakeOfferButton: FC<ISplitTokenButtonProps> = props => {
  const {
    className,
    disabled,
    variant,
    template,
    offer: { price, content, boxId, tokenId },
  } = props;
  const api = useApi();
  const navigate = useNavigate();

  const metaFnWithAllowance = useAllowance(
    async (_web3Context: Web3ContextType) => {
      return await api
        .fetchJson({
          method: "POST",
          url: "/vesting/offers",
          data: {
            price: {
              components: price.components.map(p => ({
                tokenType: p.tokenType,
                contractId: p.contractId,
                templateId: p.templateId,
                tokenId: p.tokenId,
                amount: p.amount,
              })),
            },
            content: {
              components: content.components.map(c => ({
                tokenType: c.tokenType,
                contractId: c.contractId,
                templateId: c.templateId,
                tokenId: c.tokenId,
                amount: c.amount,
              })),
            },
            templateId: template.id,
            boxId,
            tokenId,
          },
        })
        .then(() => {
          navigate(-1);
          return null;
        });
    },
    { hasApproveTx: true, isCheckOneNft: true },
  );

  const metaFnWithSystemContract = useSystemContract(
    async (_values: any, web3Context: Web3ContextType, systemContract: IContract) => {
      const asset = convertTemplateToChainAsset(template, 1);
      return metaFnWithAllowance(
        {
          assets: [{ ...asset, tokenType: TokenType.ERC721 }],
          contract: systemContract.address,
          tokenId,
        },
        web3Context,
        systemContract,
      ) as Promise<void>;
    },
  );

  const metaFn = useMetamask((web3Context: Web3ContextType) => {
    return metaFnWithSystemContract(ContractType.EXCHANGE, {}, web3Context);
  });

  const handleOffer = () => {
    void metaFn();
  };

  return (
    <Fragment>
      <ListAction
        onClick={handleOffer}
        message="pages.tokens.current.makeOffer"
        className={className}
        dataTestId="MakeOfferButton"
        disabled={disabled}
        variant={variant}
      />
    </Fragment>
  );
};
