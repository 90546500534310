import { FC } from "react";
import { Web3ContextType } from "@web3-react/core";
import { Contract } from "ethers";
import NewReleasesOutlinedIcon from "@mui/icons-material/NewReleasesOutlined";

import { useMetamask } from "@dzambalaorg/react-hooks-eth";
import { ListAction, ListActionVariant } from "@framework/styled";
import type { IToken } from "@framework/types";

import VestingReleaseABI from "@framework/abis/json/ERC721Vesting/release.json";

export interface IVestingBoxReleaseButtonProps {
  token: IToken;
  className?: string;
  disabled?: boolean;
  variant?: ListActionVariant;
}

export const VestingBoxReleaseButton: FC<IVestingBoxReleaseButtonProps> = props => {
  const { token, className, disabled, variant } = props;

  const metaRelease = useMetamask(async (web3Context: Web3ContextType) => {
    const { address } = token.template?.contract || {};

    if (!address) {
      throw new Error("unsupported token type");
    }

    const contract = new Contract(address, VestingReleaseABI, web3Context.provider?.getSigner());
    return contract.release(token.tokenId) as Promise<void>;
  });

  const handleClick = () => {
    return metaRelease();
  };

  return (
    <ListAction
      icon={NewReleasesOutlinedIcon}
      onClick={handleClick}
      message="form.tips.release"
      className={className}
      dataTestId="VestingReleaseButton"
      disabled={disabled || !token}
      variant={variant}
    />
  );
};
