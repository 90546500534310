import { ITemplate, IVestingBox } from "@framework/types";

import { generateBoxContent, TOKEN_DISPLAYING_FIX_VALUE, useVestingContractInfo } from "../../../shared";

export type UseAssetBox = {
  boxPriceValue: string;
  numericTokenCount: number;
  numericBoxPriceCount: number;
  numericTokenPriceCount: number;
  releasablePercentage: number;
  releasablePercentageForDisplaying: string;
  remainingTokensProgress: number;
  remainingTokens: string;
  releasable: number;
  tokenValue: string;
  withDrawn: number;
  withdrawnPercentage: number;
  withdrawnPercentageForDisplaying: string;
};

export const useAssetBox = (template: ITemplate & { box: IVestingBox }, tokenId: string): UseAssetBox => {
  const { tokensCount, boxPrice, tokenPrice } = generateBoxContent({
    price: template.price,
    content: template.box.content,
    duration: template.box.duration,
    cliff: template.box.cliff,
    afterCliffBasisPoints: template.box.afterCliffBasisPoints,
  });

  const numericTokenCount = Number(tokensCount.split(" ")[1]);
  const numericBoxPriceCount = Number(boxPrice.split(" ")[1]);
  const numericTokenPriceCount = Number(tokenPrice.split(" ")[1]);

  const {
    releasablePercentage,
    releasablePercentageForDisplaying,
    releasable,
    withDrawn,
    withdrawnPercentage,
    withdrawnPercentageForDisplaying,
  } = useVestingContractInfo(tokenId, template.contract!.address, numericTokenCount);

  const tokenValue = tokensCount.split(" ")[0];
  const boxPriceValue = boxPrice.split(" ")[0];
  const remainingTokensProgress = ((numericTokenCount - Number(withDrawn)) * 100) / numericTokenCount;
  const remainingTokens = (numericTokenCount - Number(withDrawn)).toFixed(TOKEN_DISPLAYING_FIX_VALUE);

  return {
    boxPriceValue,
    numericTokenCount,
    numericBoxPriceCount,
    numericTokenPriceCount,
    releasablePercentage,
    releasablePercentageForDisplaying,
    remainingTokensProgress,
    remainingTokens,
    releasable,
    tokenValue,
    withDrawn,
    withdrawnPercentage,
    withdrawnPercentageForDisplaying,
  };
};
