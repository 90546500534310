import React, { FC } from "react";
import { Box, Grid2, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { IAssetInfo } from "@framework/types";
import { AMOUNT_FIX_VALUE } from "../../../../../shared";
import { WithdrawalLinearProgress } from "../styled";

interface IWithdrawalAssetsProps {
  assetsInfo: Array<IAssetInfo>;
}

export const WithdrawalAssets: FC<IWithdrawalAssetsProps> = props => {
  const { assetsInfo } = props;
  return (
    <React.Fragment>
      <Box>
        <FormattedMessage id="pages.tokens.assets.withdrawn.title" tagName="h2" />
        <FormattedMessage id="pages.tokens.assets.withdrawn.description" tagName="span" />
      </Box>

      <Grid2 container direction="column" size={12} spacing={2}>
        {assetsInfo.map(asset => (
          <WithdrawalAsset key={asset.symbol} asset={asset} />
        ))}
      </Grid2>
    </React.Fragment>
  );
};

const WithdrawalAsset = (props: { asset: IAssetInfo }) => {
  const { asset } = props;

  const withdrawalPercentage = asset.amount ? (asset.withdrawnAmount * 100) / asset.amount : 100;

  return (
    <Grid2 className="WithdrawalAsset-Container" size={{ xl: 12, lg: 12, md: 12, sm: 12, xs: 12 }}>
      <Box className="WithdrawalAsset-ContainerHeader">
        <Box className="WithdrawalAsset-ContainerHeaderSide">
          <Typography variant="body1" component="h2">
            {asset.title}
          </Typography>
          <Box className="WithdrawalAsset-boxTicker">{asset.symbol}</Box>
        </Box>

        <Typography variant="body2" component="span">
          {`(${withdrawalPercentage.toFixed(AMOUNT_FIX_VALUE)}%)`}
        </Typography>
      </Box>
      <WithdrawalLinearProgress value={withdrawalPercentage} variant="determinate" />
    </Grid2>
  );
};
