import { formatDistanceToNowStrict, addSeconds, format, fromUnixTime } from "date-fns";

import { months } from "../constants";


export const formatXAxis = (date: string): string => {
  const parseDate = new Date(date);

  return format(parseDate, "MM/yy");
};

export const secondFromUnixConverter = (date: Date) => {
  const utcDateString = format(date, "yyyy-MM-dd'T'HH:mm:ss'Z'");
  const utcDate = new Date(utcDateString);

  return utcDate.getTime() / 1000;
};

export const getPeriodFromSecondsInDays = (seconds: number): string => {
  const now = new Date();
  const targetDate = addSeconds(now, seconds);

  return formatDistanceToNowStrict(targetDate, { unit: "day" });
};

export const formatDateFromUnixTime = (unixSeconds: number): string => {
  const date = fromUnixTime(unixSeconds);
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear().toString().slice(-2);

  return `${month} ${String(day).padStart(2, "0")}'${year}`;
};

export const formatDateForVestingPlot = (prevDate: string, coefficient: number, isMonthly: boolean = false): string => {
  const parseDate = new Date(prevDate);

  if (!isMonthly) {
    parseDate.setDate(parseDate.getDate() + coefficient);
  } else {
    parseDate.setMonth(parseDate.getMonth() + coefficient);
  }

  const day = parseDate.getDate();
  const month = months[parseDate.getMonth()];
  const year = parseDate.getFullYear();

  return `${month} ${String(day).padStart(2, "0")},${year}`;
};
