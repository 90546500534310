import { FC } from "react";
import { AppBar, useMediaQuery, useTheme } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import { MenuVariant } from "../../../shared";
import { Menu } from "../menu";
import { Sections } from "./sections";
import { StyledGrow, StyledLink, StyledToolbar } from "./styled";
import { MobileMenu } from "../landing-header/mobile-menu";

export const InnerHeader: FC = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <AppBar position="fixed">
      <StyledToolbar>
        <StyledLink component={RouterLink} to="/">
          <img src="/files/inner/full_logo.png" alt="logo" width={128} height={46} />
        </StyledLink>
        <StyledGrow />
        {!isMd && <Menu variant={MenuVariant.INNER} />}
        <Sections />
        {isMd && <MobileMenu variant={MenuVariant.INNER} />}
      </StyledToolbar>
    </AppBar>
  );
};
