import React from "react";
import { SvgIcon } from "@mui/material";
import { FormattedMessage } from "react-intl";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { Root } from "./styled";

export const ThirdStep = () => {
  return (
    <Root>
      <SvgIcon component={CheckCircleIcon} />
      <FormattedMessage id="pages.vesting.application.successfullySubmitted"/>
    </Root>
  );
};
