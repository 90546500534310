import { FC, SyntheticEvent, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Grid2, useTheme, useMediaQuery } from "@mui/material";

import { FooterLogo } from "../../../shared";
import Logo from "../../../../static/files/logo_gemunion.svg";
import {
  Root,
  StyledContent,
  StyledCopyrightWrapper,
  StyledLink,
  StyledLogo,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledWrapper,
} from "./styled";
import { legalDisclaimerParagraphs } from "./constants";

const fullYear = new Date().getFullYear();

export const Footer: FC = () => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const toggleExpanded = () => (_event: SyntheticEvent) => {
    setExpanded(prevState => !prevState);
  };

  return (
    <Root>
      <StyledWrapper>
        <StyledContent container size={12} spacing={isMd ? 2 : 5}>
          <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }} gap={1}>
            <Grid2 id={"footer_grid_item_logo"}>
              <FooterLogo />
              <StyledCopyrightWrapper>©{fullYear} Lamb OTC Market. All Rights Reserved</StyledCopyrightWrapper>
            </Grid2>

            <Grid2 id={"footer_grid_item_logo_gemunion"}>
              <StyledLink component={RouterLink} target="_blank" to="https://gemunion.io">
                <StyledLogo component={Logo} viewBox="0 0 449.91 95.5" />
              </StyledLink>
              <StyledCopyrightWrapper>
                Powered by GemUnion - Universal Web3 Constructor Framework
              </StyledCopyrightWrapper>
            </Grid2>
          </Grid2>
          <Grid2
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
            size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
          >
            <StyledCopyrightWrapper>BDOU GLOBAL SOURCING LIMITED</StyledCopyrightWrapper>
            <StyledCopyrightWrapper>
              Workshop 21, 6/F, iCITY, 111 Ta Chuen Ping Street, Kwai Chung, Hong Kong
            </StyledCopyrightWrapper>
            <StyledCopyrightWrapper>Licenсe No. 22-03-03101</StyledCopyrightWrapper>
          </Grid2>
        </StyledContent>
        <StyledAccordion disableGutters elevation={0} expanded={expanded} onChange={toggleExpanded()}>
          <StyledAccordionSummary>
            <FormattedMessage id="components.footer.legalDisclaimer.title" tagName="span" />
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            {Object.values(legalDisclaimerParagraphs).map(id => (
              <FormattedMessage key={id} tagName={"p"} id={id} values={{ year: fullYear }} />
            ))}
          </StyledAccordionDetails>
        </StyledAccordion>
      </StyledWrapper>
    </Root>
  );
};
