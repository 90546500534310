import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { BigNumber } from "ethers";

import { useApiCall } from "@dzambalaorg/react-hooks";
import { IAssetInfo } from "@framework/types";

import { getReleasableAmounts } from "../../../../shared";

export const useFetchAssetsInfo = () => {
  const [assetsInfo, setAssetsInfo] = useState<Array<IAssetInfo>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { provider } = useWeb3React();

  const { fn: fetchByQueryFn } = useApiCall<Array<IAssetInfo>>(
    api => {
      return api.fetchJson({
        url: "/vesting/boxes/assetsInfo",
      });
    },
    { success: false, error: false },
  );

  useEffect(() => {
    const fetchAssetsInfo = async () => {
      const response = (await fetchByQueryFn()) as Array<IAssetInfo>;
      const responseMap = new Map();

      for (const asset of response) {
        responseMap.set(asset.symbol, asset);

        const currentAsset = responseMap.get(asset.symbol);

        for (const token of asset.tokens) {
          const { tokenId, contract } = token;
          const releasableAmount = await getReleasableAmounts(tokenId, contract, provider);
          const releasableAmountValue = releasableAmount[0 as keyof typeof releasableAmount][3];
          const formattedReleasableAmountValue = Number(BigNumber.from(releasableAmountValue));

          currentAsset.releasableAmount = currentAsset.releasableAmount + formattedReleasableAmountValue;
        }
      }
      setAssetsInfo(Array.from(responseMap.values()));
    };

    void fetchAssetsInfo().finally(() => setIsLoading(false));
  }, []);

  return { assetsInfo, isLoading };
};
