import React, { SVGProps } from "react";

export const SecuritySvg = ({ width = "24", height = "24" }: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        d="M8.03516 9.51024C8.10229 10.3134 8.25647 11.4136 8.60533 12.5687C9.34622 15.0219 10.6551 16.7662 12.4975 17.7575C14.34 16.7662 15.6488 15.0219 16.3897 12.5687C16.7386 11.4137 16.8927 10.3135 16.9599 9.51024L12.4975 7.27905L8.03516 9.51024Z"
        fill="url(#paint0_linear_2162_3766)"
      />
      <path
        d="M19.9917 7.9691C19.9824 7.79018 19.8776 7.63006 19.7174 7.54996L12.7225 4.05252C12.5824 3.98249 12.4176 3.98249 12.2775 4.05252L5.28265 7.54996C5.1224 7.63006 5.01758 7.79018 5.00829 7.9691C5.00298 8.07093 4.88795 10.4956 5.71393 13.3107C6.20266 14.9764 6.92154 16.4311 7.85058 17.6346C9.02134 19.1513 10.5261 20.267 12.323 20.9508C12.3801 20.9725 12.44 20.9833 12.5 20.9833C12.56 20.9833 12.6199 20.9725 12.677 20.9508C14.4739 20.267 15.9787 19.1513 17.1494 17.6346C18.0785 16.4311 18.7973 14.9763 19.2861 13.3107C20.1121 10.4956 19.997 8.07093 19.9917 7.9691ZM12.7205 18.7633C12.651 18.7976 12.5755 18.8148 12.5 18.8148C12.4245 18.8148 12.3489 18.7976 12.2795 18.7633C10.064 17.6678 8.50812 15.6805 7.65524 12.8564C7.22866 11.4439 7.07465 10.1129 7.02059 9.24507C7.00815 9.04578 7.11608 8.8584 7.29467 8.76911L12.2775 6.2777C12.4176 6.20771 12.5825 6.20768 12.7225 6.2777L17.7054 8.76911C17.884 8.85837 17.992 9.04582 17.9795 9.24511C17.9254 10.113 17.7714 11.444 17.3448 12.8564C16.4919 15.6804 14.936 17.6678 12.7205 18.7633Z"
        fill="url(#paint1_linear_2162_3766)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2162_3766"
          x1="12.5374"
          y1="16.8245"
          x2="14.7851"
          y2="16.8078"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04B6BA" />
          <stop offset="1" stopColor="#92FCFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2162_3766"
          x1="12.567"
          y1="19.4711"
          x2="16.3448"
          y2="19.442"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04B6BA" />
          <stop offset="1" stopColor="#92FCFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
