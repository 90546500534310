import { SxProps, Theme } from "@mui/material";
import { BOXES_MEDIA_QUERY } from "./constants";
import { sizeDecreaseCalc } from "../../../../../shared";

export const boxesHeaderStyles = (theme: Theme): SxProps => ({
  marginBottom: sizeDecreaseCalc(16, 2, "px"),
  marginTop: sizeDecreaseCalc(16, 2, "px"),

  [theme.breakpoints.down(BOXES_MEDIA_QUERY)]: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  "& .MuiGrid-item:last-of-type": {
    [theme.breakpoints.down(BOXES_MEDIA_QUERY)]: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
    "& :first-of-type": {
      textTransform: "none",
      "&:hover": {
        background: "transparent",
      },
    },
    "& .MuiGrid-root": {
      gap: theme.spacing(2),
      [theme.breakpoints.down(BOXES_MEDIA_QUERY)]: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",

        "& .MuiButton-root:last-of-type": {
          margin: 0,
        },
      },
    },
  },
});
