import React from "react";
import { useParams } from "react-router";
import { FormattedMessage } from "react-intl";
import { Button, Grid2, useMediaQuery, useTheme } from "@mui/material";
import { FilterList } from "@mui/icons-material";

import { PageHeader, ProgressOverlay } from "@dzambalaorg/mui-page-layout";
import { useDidMountEffect } from "@dzambalaorg/react-hooks";
import { collectionActions, useCollection } from "@dzambalaorg/provider-collection";
import { useAppDispatch } from "@dzambalaorg/redux";

import { IVestingBox, IVestingBoxInMarketSearchDto, VestingBoxVariant } from "@framework/types";
import { StyledEmptyWrapper, StyledPagination } from "@framework/styled";

import {
  BoxCard,
  DESKTOP_ITEMS_COUNT,
  isOfferGuard,
  IVestingOfferResponse,
  LoadMoreButton,
  MOBILE_ITEMS_COUNT,
  PATHS,
  StyledListRoot,
  TabMenu,
  TabMode,
  VestingBoxesTabs,
} from "../../../../../shared";
import { boxesHeaderStyles } from "./styled";
import { ApplyButton } from "./apply-button";
import { BoxesFilters } from "./filters";

export const VestingBoxes = () => {
  const { tab } = useParams<{ tab: VestingBoxesTabs }>();

  const dispatch = useAppDispatch();
  const { resetState, setDidMount, setNeedRefresh } = collectionActions;

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const {
    rows,
    count,
    search,
    isLoading,
    handleChangePage,
    isFiltersOpen,
    handleToggleFilters,
    handleSearch,
    handleChangePaginationModel,
  } = useCollection<IVestingBox | IVestingOfferResponse, IVestingBoxInMarketSearchDto>({
    baseUrl: `/vesting/${tab}`,
    search: {
      query: "",
      take: isMd ? MOBILE_ITEMS_COUNT : DESKTOP_ITEMS_COUNT,
      vestingBoxCat: [],
      symbols: [],
      durations: [],
      priceFrom: "0",
      priceTo: "0",
    },
  });

  useDidMountEffect(() => {
    if (tab) {
      dispatch(resetState({}));
      dispatch(setDidMount(true));
      dispatch(setNeedRefresh(true));
    }
  }, [tab]);

  const loadMoreHandler = () => {
    handleChangePaginationModel({
      page: 0,
      pageSize: search.take * 2,
    });
  };

  return (
    <StyledListRoot>
      <PageHeader sx={boxesHeaderStyles(theme)} message="pages.vesting.boxes.title">
        <Button disableRipple startIcon={<FilterList />} onClick={handleToggleFilters} data-testid="ToggleFilterButton">
          <FormattedMessage id={`form.buttons.${isFiltersOpen ? "hideFilters" : "showFilters"}`} />
        </Button>
        <ApplyButton />
      </PageHeader>

      <BoxesFilters isFiltersOpen={isFiltersOpen} search={search} handleSearch={handleSearch} tab={tab} />

      <TabMenu<Record<string, string>, TabMode.NAVIGATE>
        tabs={VestingBoxesTabs}
        initialTab={VestingBoxVariant.STARTING}
        basicLocale="pages.vesting.boxes.tabs"
        basicPath={PATHS.VestingBoxesBase}
        mode={TabMode.NAVIGATE}
      />

      <ProgressOverlay isLoading={isLoading}>
        <Grid2 container spacing={2}>
          <StyledEmptyWrapper count={rows.length} isLoading={isLoading}>
            {rows.map(row => (
              <Grid2 size={{ lg: 4, md: 6, sm: 12, xs: 12 }} key={row.id}>
                <BoxCard
                  vestingBox={isOfferGuard(row) ? row.box : row}
                  tab={tab!}
                  offerId={isOfferGuard(row) ? row.id : undefined}
                  tokenId={isOfferGuard(row) ? row.tokenId : undefined}
                />
              </Grid2>
            ))}
          </StyledEmptyWrapper>
        </Grid2>
      </ProgressOverlay>

      {!isMd && !!count && count > search.take && (
        <StyledPagination
          shape="rounded"
          page={search.skip / search.take + 1}
          count={Math.ceil(count / search.take)}
          onChange={handleChangePage}
        />
      )}
      {isMd && rows.length !== count && <LoadMoreButton loadMoreHandler={loadMoreHandler} />}
    </StyledListRoot>
  );
};
