import React, { FC } from "react";
import { useIntl } from "react-intl";
import { Box, Typography } from "@mui/material";

import { StyledAvatar } from "@framework/styled";

import { InfoSvg } from "../../assets";
import { ContentMode } from "../../types";
import { StyledContentLeftSide } from "../styled";
import { CustomTooltip } from "../tooltip";

import { containerSizesByMode, imageSizeByMode } from "./schemas";

interface IBoxContentLeftSideProps {
  tokenImageUrl: string;
  tokensCount: string | number;
  subtitle: string;
  tooltipTextId?: string;
  mode?: ContentMode;
}

export const BoxContentLeftSide: FC<IBoxContentLeftSideProps> = props => {
  const { tokensCount, tokenImageUrl, tooltipTextId, subtitle, mode = ContentMode.BOX } = props;

  const { formatMessage } = useIntl();

  return (
    <StyledContentLeftSide size={containerSizesByMode[mode]} mode={mode}>
      <Box className="LeftSide-AvatarBox">
        <StyledAvatar src={tokenImageUrl} width={imageSizeByMode[mode]} height={imageSizeByMode[mode]} />
        <Typography variant="body1" component="p">
          {tokensCount}
        </Typography>
      </Box>
      <Box className="LeftSide-InfoBox">
        <Typography variant="body2" component="p">
          {subtitle}
        </Typography>
        {tooltipTextId && (
          <CustomTooltip title={formatMessage({ id: tooltipTextId })}>
            <InfoSvg />
          </CustomTooltip>
        )}
      </Box>
    </StyledContentLeftSide>
  );
};
