import { Box, Link, SvgIcon, styled } from "@mui/material";

export const StyledWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: theme.spacing(2),
}));

export const StyledLogo = styled(SvgIcon)(({ theme }) => ({
  width: 30,
  height: 30,
  fill: "#000000",
  "&:hover": {
    fill: theme.palette.primary.main,
  },
})) as any;

export const StyledLink = styled(Link)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 36,
  height: 36,
  borderRadius: "50%",
  background: "rgba(138, 138, 138, 0.20)",
})) as typeof Link;
