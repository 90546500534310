import { Box, styled } from "@mui/material";

import { fadedAnimation, flexStyles } from "../styles";
import { pageHeader } from "../../../../shared";

export const Root = styled(Box)(({ theme }) => ({
  ...flexStyles,
  width: "100vw",
  paddingBottom: theme.spacing(4),

  "& h1": {
    ...pageHeader(),
    fontSize: theme.spacing(4.5),
    marginTop: theme.spacing(5),
    marginBottom: 0,
    textAlign: "center",
    ...fadedAnimation(""),
  },
}));

export const StyledCardHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: theme.spacing(2.5)
}));
