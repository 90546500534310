import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";

interface IStyledButton {
  isLoggedIn: boolean;
}

export const StyledButton = styled(Button, { shouldForwardProp: prop => prop !== "isLoggedIn" })<
  ButtonProps & IStyledButton
>(({ theme, isLoggedIn }) => ({
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 500,
  color: "#F5F5F5",
  textTransform: "none",
  columnGap: theme.spacing(0.75),
  padding: 0,
  cursor: isLoggedIn ? "default" : "pointer",
  pointerEvents: isLoggedIn ? "none" : "visible",

  "& .MuiButton-icon": {
    margin: 0,
  },

  "&:hover": {
    backgroundColor: "transparent",
    color: isLoggedIn ? "#FFFFFF" : "#4BD4FF",
    "& #fill": {
      fill: isLoggedIn ? "#FFFFFF" : "#4BD4FF",
    },
  },

  [theme.breakpoints.down("md")]: {
    fontSize: theme.typography.pxToRem(18),
  },
}));
