import React, { PropsWithChildren } from "react";
import { ButtonOwnProps, useTheme } from "@mui/material";

import { simpleButtonStylesByVariant, StyledButton } from "./styled";
import { ButtonVariant } from "./types";

interface ISimpleButtonProps extends ButtonOwnProps {
  id?: string;
  component?: React.ElementType;
  target?: "_blank" | "_self" | "_parent" | "_top";
  buttonVariant?: ButtonVariant;

  [key: string]: any;
}

export const SimpleButton = ({
  buttonVariant = "primary",
  children,
  sx,
  disabled,
  ...props
}: PropsWithChildren<ISimpleButtonProps>) => {
  const theme = useTheme();
  return (
    <StyledButton
      sx={{
        ...simpleButtonStylesByVariant(theme)[buttonVariant],
        ...sx,
        opacity: disabled ? 0.5 : 1,
      }}
      {...props}
    >
      {children}
    </StyledButton>
  );
};
