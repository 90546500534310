import { FC } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { IconButton, Box as MuiBox } from "@mui/material";

import { PageHeader } from "@dzambalaorg/mui-page-layout";
import { ListActionVariant } from "@framework/styled";

import {
  BoxesAssetVariant,
  BoxWithTitle,
  ContentMode,
  Plot,
  SplitCalculation,
  StyledCurrentPageHeader,
  StyledCurrentRoot,
  AssetsDashboardTabs,
  CustomAccordion,
} from "../../../shared";
import { ButtonBack } from "../../../shared/assets/svg/button-back";
import { Breadcrumbs } from "../../../components/breadcrumbs";
import { VestingBoxReleaseButton } from "../../../components/buttons/release";
import { BoxContent } from "./content";
import { RulesDescription } from "./rules-description";
import { StyledContentWrapper } from "./styled";
import { OfferManagement } from "./offer-management";
import { ISelected } from "./useFetchToken";
import { useAssetBox } from "./useAssetBox";
import { createOfferDescription, splitRulesDescription } from "./schemas";

interface ICurrentBoxProps {
  selected: ISelected;
  variant?: BoxesAssetVariant;
}

export const Box: FC<ICurrentBoxProps> = props => {
  const { selected, variant = AssetsDashboardTabs.boxes } = props;
  const { template, tokenId } = selected;

  const assetBoxData = useAssetBox(template, tokenId);

  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const goBackHandler = () => navigate(-1);

  return (
    <StyledCurrentRoot>
      <Breadcrumbs
        paths={[`assets-dashboard.${variant}`, "tokens.current"]}
        data={[{}, { title: template.box.title }]}
      />

      <StyledCurrentPageHeader>
        <IconButton onClick={goBackHandler}>
          <ButtonBack />
        </IconButton>
        <PageHeader message="pages.tokens.current.title" data={{ title: template.box.title }} />
      </StyledCurrentPageHeader>

      <StyledContentWrapper>
        <BoxContent imageUrl={template.box.content!.components[0].template!.imageUrl || ""} assetBoxData={assetBoxData}>
          <MuiBox sx={{ width: "100%" }} className="CurrentToken-Buttons-box">
            <VestingBoxReleaseButton
              className="CurrentToken-releaseBtn"
              token={selected}
              variant={ListActionVariant.button}
            />
          </MuiBox>
        </BoxContent>

        <BoxWithTitle
          title={formatMessage({ id: "pages.tokens.current.tokenDynamicsTitle" })}
          description={formatMessage({ id: "pages.tokens.current.tokenDynamicsDescription" })}
        >
          <Plot
            vestingBox={template.box}
            startTimestamp={new Date(template.createdAt).toDateString()}
            height={400}
            startDirection="column"
            infoPosition="bottom"
          />
        </BoxWithTitle>

        <BoxWithTitle title={formatMessage({ id: "pages.tokens.current.offerManagementTitle" })}>
          <CustomAccordion
            title={formatMessage({ id: "pages.tokens.current.offerRulesTitle" })}
            description={<RulesDescription data={createOfferDescription} />}
          />
          <OfferManagement
            priceAsset={template.price!}
            contentAsset={template.box.content!}
            template={template}
            boxId={template.box.id}
            tokenId={tokenId}
            summaryData={{
              token: {
                name: assetBoxData.tokenValue,
                image: template.box.content?.components[0].contract?.imageUrl || "",
              },
              price: {
                name: assetBoxData.boxPriceValue,
                image: template.price?.components[0].contract?.imageUrl || "",
              },
            }}
            assetBoxData={assetBoxData}
          />
        </BoxWithTitle>

        <BoxWithTitle title={formatMessage({ id: "pages.tokens.current.splitManagementTitle" })}>
          <CustomAccordion
            title={formatMessage({ id: "pages.tokens.current.splitRulesTitle" })}
            description={<RulesDescription data={splitRulesDescription} />}
          />
          <SplitCalculation
            mode={ContentMode.ASSET}
            box={template.box}
            token={selected}
            releasableValue={assetBoxData.releasablePercentage}
            withDrawnValue={assetBoxData.withdrawnPercentage}
          />
        </BoxWithTitle>
      </StyledContentWrapper>
    </StyledCurrentRoot>
  );
};
