import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

export const howItWorksBlockData = [
  {
    title: "pages.main.howItWorks.benefitsTitle",
    subtitle: "pages.main.howItWorks.benefitsSubtitle",
    icon: <ShieldOutlinedIcon sx={{ color: "#2563eb" }} />,
  },
  {
    title: "pages.main.howItWorks.reputationTitle",
    subtitle: "pages.main.howItWorks.reputationSubtitle",
    icon: <SearchOutlinedIcon sx={{ color: "#22c55e" }} />,
  },
];
