import { ShapeType } from "@framework/types";

export const linearShapes = [
  ShapeType.LINEAR,
  ShapeType.LINEAR_CLIFF,
  ShapeType.LINEAR_IMMEDIATE,
  ShapeType.LINEAR_CLIFF_IMMEDIATE,
];

export const cliffImmediateShapes = [
  ShapeType.LINEAR_CLIFF_IMMEDIATE,
  ShapeType.EXPONENTIAL_CLIFF_IMMEDIATE,
  ShapeType.HYPERBOLIC_CLIFF_IMMEDIATE,
];
