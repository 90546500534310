import { Grid, styled } from "@mui/material";

import { StylesScheme } from "../../types";

export { StyledCardMedia } from "./card-media";
export { StyledListRoot } from "./list-root";
export { StyledCurrentRoot } from "./current-root";
export { StyledCurrentPageHeader } from "./pages-header";
export { StyledProgressBarBox, StyledLinearProgress } from "./progress-bar";
export { StyledContentLeftSide } from "./content-left-side";
export { StyledContentRightSide } from "./content-right-side";
export { StyledBlockRoot } from "./block-root";
export { BootstrapInput } from "./bootstrap-input";

export const StyledSpinnerBox = styled(Grid)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
}));

export const contentSideFlex: StylesScheme = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};
