import type { RouteObject } from "react-router-dom";

import { Protected } from "@dzambalaorg/common-pages";

import { PATHS } from "../../shared";
import { MyTokensList } from "./tokens";

export const exchangeRoutes: Array<RouteObject> = [
  {
    path: PATHS.Tokens,
    element: <Protected />,
    children: [{ index: true, element: <MyTokensList /> }],
  },
];
