import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Box, SvgIcon, Typography, useMediaQuery, useTheme } from "@mui/material";

import { IVestingBox } from "@framework/types";
import {
  CalendarCheck,
  CalendarClose,
  CalendarDay,
  CalendarRemove,
  Clock,
  generateBoxContent,
} from "../../../../../../../shared";
import { Root, StyledDatesItem } from "./styled";

interface ITimelineDatesProps {
  vestingBox: IVestingBox;
}

const timelineDataIcons = {
  ido: CalendarDay,
  cliffEnds: CalendarRemove,
  tge: CalendarCheck,
  end: CalendarClose,
  duration: Clock,
};

export const TimelineDates: FC<ITimelineDatesProps> = props => {
  const { vestingBox } = props;

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));

  const { tge, ido, end, cliffEnds, duration } = generateBoxContent({
    price: vestingBox.template?.price,
    content: vestingBox.content,
    duration: vestingBox.duration,
    cliff: vestingBox.cliff,
    afterCliffBasisPoints: vestingBox.afterCliffBasisPoints,
  });

  const timelineData = Object.entries({ ido, cliffEnds, tge, end, duration }).map(([key, value]) => {
    if (key === "cliffEnds" && !vestingBox.cliff) return null;

    return {
      id: `pages.vesting.currentBox.${key}`,
      value,
      icon: timelineDataIcons[key as keyof typeof timelineDataIcons],
    };
  });

  return (
    <Root
      container
      spacing={2}
      alignItems={isXs ? "flex-start" : "center"}
      justifyContent={isXs ? "flex-start" : "center"}
    >
      {timelineData.map(dataItem => {
        if (!dataItem) return null;

        return (
          <StyledDatesItem
            size={{ lg: 3, md: 3, sm: 6, xs: 12 }}
            alignItems={"center"}
            justifyContent={"center"}
            key={dataItem.id}
          >
            <SvgIcon component={dataItem.icon} />
            <Box className="DateItem-text">
              <FormattedMessage id={dataItem.id} tagName="h6" />
              <Typography variant="body1" component="p">
                {dataItem.value}
              </Typography>
            </Box>
          </StyledDatesItem>
        );
      })}
    </Root>
  );
};
