export const sizeDecreaseCalc = (maxSize: number, minSize: number, unit?: "px" | "%" | "deg") => {
  return `calc(${minSize}${unit || "px"} + (${maxSize} - ${minSize}) * ((100vw - 320px) / (1200 - 320)))`;
};

export const sizeIncreaseCalc = (
  maxSize: number,
  minSize: number,
  maxWidth: number,
  minWidth: number,
  unit?: "px" | "%",
) => {
  return `calc(${minSize}${unit || "px"} + (${maxSize} - ${minSize}) * ((100vw - ${minWidth}px) / (${maxWidth} - ${minWidth})))`;
};
