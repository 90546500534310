import React, { FC } from "react";
import { Box, SvgIcon, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

import { walletSelectors } from "@dzambalaorg/provider-wallet";
import { INetwork } from "@dzambalaorg/types-blockchain";
import { useAppSelector } from "@dzambalaorg/redux";
import { useUser } from "@dzambalaorg/provider-user";
import { StyledAvatar } from "@framework/styled";

import { getIconByChainId, WithBorderInfoBox } from "../../../../../shared";
import { ISummaryData, ISummaryItemData } from "../types";
import { StyledSummaryItem } from "./styled";

export const Summary: FC<ISummaryData> = props => {
  const { token, price } = props;
  const user = useUser<any>();
  const networks = useAppSelector<Record<number, INetwork>>(walletSelectors.networksSelector);
  const { formatMessage } = useIntl();
  const chainId = user.profile.chainId;

  const displayingData: Record<string, ISummaryItemData> = {
    chain: {
      name: networks[chainId].chainName,
      image: getIconByChainId(chainId),
    },
    token,
    price,
  };

  return (
    <WithBorderInfoBox sx={{ height: "100%" }} title={formatMessage({ id: "pages.tokens.current.summaryBlockTitle" })}>
      {Object.entries(displayingData).map(([key, value]) => {
        const { name, image } = value;
        const ImageComponent =
          typeof image === "string" ? (
            <StyledAvatar src={image} width={24} height={24} />
          ) : (
            image && <SvgIcon component={image} />
          );
        return (
          <StyledSummaryItem key={key}>
            <FormattedMessage id={`pages.tokens.current.summaryBlockItems.${key}`} tagName="p" />
            <Box className="SummaryItem-iconBox">
              <Typography variant="body1" component="span">
                {name}
              </Typography>
              {ImageComponent}
            </Box>
          </StyledSummaryItem>
        );
      })}
    </WithBorderInfoBox>
  );
};
