import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";

import { ArrowSvg, PATHS } from "../../../../shared";
import { SimpleButton } from "../../../../components/buttons/simple";
import { StyledHeaderBox } from "./styled";

export const MainPageHeader = () => {
  const { formatMessage } = useIntl();
  return (
    <StyledHeaderBox>
      <img src="/files/inner/thelamb-logo-wh.png" alt="The Lamb Logo" />
      <FormattedMessage id="pages.main.title" tagName="h1" />
      <Box className="MainPage-subtitle">
        <FormattedMessage id="pages.main.subtitle" tagName="p" />
      </Box>
      <SimpleButton component={Link} to={PATHS.VestingBoxes}>
        {formatMessage({ id: "pages.main.getStartedBtn" })}
        <ArrowSvg width={6} height={12} strokeWidth={3} />
      </SimpleButton>
    </StyledHeaderBox>
  );
};
