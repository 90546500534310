import { FC, PropsWithChildren } from "react";
import { FormattedMessage } from "react-intl";
import { DialogContent, DialogTitle } from "@mui/material";

import { CloseButton } from "../../common/close";
import { StyledDialog } from "../../styled";
import { StyledLoginWrapper } from "./styled";

interface IConnectWalletProps {
  open: boolean;
  onClose: () => void;
}

export const ConnectWallet: FC<PropsWithChildren<IConnectWalletProps>> = props => {
  const { open, onClose, children } = props;

  return (
    <StyledDialog onClose={onClose} open={open}>
      <DialogTitle>
        <FormattedMessage tagName={"span"} id="components.header.wallet.connect" />
        <CloseButton onClick={onClose} color="#000000" />
      </DialogTitle>
      <DialogContent>
        <StyledLoginWrapper>{children}</StyledLoginWrapper>
      </DialogContent>
    </StyledDialog>
  );
};
