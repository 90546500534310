import { Box, styled } from "@mui/material";

export const Root = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: theme.spacing(4),
  paddingInline: theme.spacing(2),

  "& .MuiButtonBase-root": {
    width: "100%"
  }
})) as typeof Box;
