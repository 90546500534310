import { Box, styled } from "@mui/material";
import { pageHeader, sizeDecreaseCalc } from "../../../../../../shared";

const flexStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const Root = styled(Box)(({ theme }) => ({
  width: "100%",
  ...flexStyles,
  marginTop: theme.spacing(4),

  "& form": {
    ...flexStyles,
    flexDirection: "column",

    "& .MuiGrid-container": {
      width: "70%",

      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
  },

  "& .MuiButtonBase-root": {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  }
})) as typeof Box;

export const StyledFieldBox = styled(Box)(({ theme }) => ({
  width: "70%",
  ...flexStyles,
  flexDirection: "column",
  alignItems: "flex-start",
  gap: theme.spacing(1.5),

  [theme.breakpoints.down("md")]: {
    width: "100%",
  },

  "& h5": {
    ...pageHeader(),
    fontSize: sizeDecreaseCalc(20, 16),
    margin: 0,
  },

  "& p": {
    margin: 0,
    fontSize: sizeDecreaseCalc(14, 10),
    color: theme.palette.text.secondary,
    fontWeight: 600,
    lineHeight: "140%",
  },

  "& .MuiBox-root": {
    width: "100%",
  },

  "& .MuiAutocomplete-root": {
    width: "100%",
  }
})) as typeof Box;
