import { Box, styled } from "@mui/material";

import { pageHeader, sizeDecreaseCalc, sizeIncreaseCalc } from "../../../../shared";
import { fadedAnimation, flexStyles } from "../styles";

export const StyledHeaderBox = styled(Box)(({ theme }) => ({
  ...flexStyles,
  paddingBottom: theme.spacing(4),

  "& h1": {
    ...pageHeader(),
    ...fadedAnimation(""),
    fontSize: sizeDecreaseCalc(36, 16),
    marginTop: theme.spacing(5),
    marginBottom: 0,
    textAlign: "center",
  },

  "& .MainPage-subtitle": {
    ...fadedAnimation("0.2s"),
  },

  "& p": {
    fontSize: sizeDecreaseCalc(24, 12),
    fontWeight: 400,
    lineHeight: "140%",
    letterSpacing: "-0.12px",
    color: "#000000",
    marginTop: theme.spacing(1),
    textAlign: "center",
  },

  "& img": {
    width: sizeIncreaseCalc(50, 70, theme.breakpoints.values.lg, theme.breakpoints.values.md + 1, "%"),
    height: "25%",
    objectFit: "cover",
  },

  "& .MuiButtonBase-root": {
    width: sizeDecreaseCalc(190, 140),
    height: sizeDecreaseCalc(52, 40),
    paddingInline: sizeDecreaseCalc(21, 8),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(3),
    fontSize: sizeDecreaseCalc(20, 14),
    fontWeight: 600,
    lineHeight: "140%",
    letterSpacing: "-0.12px",

    opacity: 0,
    transform: "translateY(20px)",
    animation: "fadeInUp 0.8s 0.4s forwards",
    "@keyframes fadeInUp": {
      "0%": {
        opacity: 0,
        transform: "translateY(20px)",
      },
      "100%": {
        opacity: 1,
        transform: "translateY(0)",
      },
    },
  },
}));
