import React, { SVGProps } from "react";

export const ChainSvg = ({ width = "24", height = "24" }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2692_342)">
        <path
          id={"stroke"}
          d="M14.0507 11.8107V7.1561L10.0197 4.82883L5.98877 7.1561V11.8107L10.0197 14.1379L14.0507 11.8107Z"
          stroke="white"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id={"stroke"}
          d="M16.6671 4.51553L10.0196 0.677654L3.34277 4.53251"
          stroke="white"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id={"stroke"}
          d="M10.9512 17.7512L17.6456 13.8862V6.19259"
          stroke="white"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id={"stroke"}
          d="M2.39355 6.19259V13.8862L9.0045 17.703"
          stroke="white"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id={"stroke"}
          d="M2.35455 6.19261C2.97536 6.19261 3.47863 5.68935 3.47863 5.06854C3.47863 4.44773 2.97536 3.94446 2.35455 3.94446C1.73374 3.94446 1.23047 4.44773 1.23047 5.06854C1.23047 5.68935 1.73374 6.19261 2.35455 6.19261Z"
          stroke="white"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id={"stroke"}
          d="M17.6456 6.19261C18.2664 6.19261 18.7696 5.68935 18.7696 5.06854C18.7696 4.44773 18.2664 3.94446 17.6456 3.94446C17.0248 3.94446 16.5215 4.44773 16.5215 5.06854C16.5215 5.68935 17.0248 6.19261 17.6456 6.19261Z"
          stroke="white"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id={"stroke"}
          d="M9.96392 19.4132C10.5847 19.4132 11.088 18.9099 11.088 18.2891C11.088 17.6683 10.5847 17.165 9.96392 17.165C9.34311 17.165 8.83984 17.6683 8.83984 18.2891C8.83984 18.9099 9.34311 19.4132 9.96392 19.4132Z"
          stroke="white"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id={"stroke"}
          d="M5.98877 7.15608L10.0197 9.48334L14.0507 7.15608"
          stroke="white"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id={"stroke"}
          d="M10.0195 9.48335V14.1379"
          stroke="white"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2692_342">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
