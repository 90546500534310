import React, { FC } from "react";
import { useIntl } from "react-intl";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

import { IVestingBox } from "@framework/types";
import { StyledAvatar } from "@framework/styled";

import { BoxContentLeftSide, StyledContentRightSide, generateBoxContent } from "../../../../../../shared";
import { Root, StyledContent } from "../styled";
import { StyledContentPrices, StyledContentPricesItem } from "./styled";
import { CURRENT_BOX_MEDIA_QUERY } from "./constants";
import { ReleaseBox } from "./release-box";
import { ProgressBars } from "./progress-bars";

interface ICurrentBoxContent {
  selected: IVestingBox;
}

export const CurrentBoxContent: FC<ICurrentBoxContent> = props => {
  const { selected } = props;

  const theme = useTheme();
  const isMedia920 = useMediaQuery(theme.breakpoints.down(CURRENT_BOX_MEDIA_QUERY));
  const { formatMessage } = useIntl();

  const { tokensCount, tokenPrice, boxPrice } = generateBoxContent({
    price: selected.template?.price,
    content: selected.content,
    duration: selected.duration,
    cliff: selected.cliff,
    afterCliffBasisPoints: selected.afterCliffBasisPoints,
  });

  const numericTokensCount = Number(tokensCount.split(" ")[1]);
  const tokenPriceTicker = tokenPrice.split(" ")[0];
  const splitTokenPrice = Number(tokenPrice.split(" ")[1]).toFixed(4);

  return (
    <Root>
      <StyledContent container spacing={2} direction={"row"}>
        <BoxContentLeftSide
          tokenImageUrl={selected.content!.components[0].template!.imageUrl}
          tokensCount={tokensCount}
          tooltipTextId="pages.vesting.currentBox.tokensInfo"
          subtitle={formatMessage({ id: "pages.vesting.currentBox.tokensCount" })}
        />

        <StyledContentRightSide size={{ xs: 12, sm: 8, md: 8, lg: 8 }}>
          <StyledContentPrices>
            <StyledContentPricesItem>
              <StyledAvatar src={selected.template!.price!.components[0].template!.imageUrl} />
              <Box className="PricesItem-content">
                <Typography variant="body1" component="p">
                  {`${formatMessage({ id: "pages.vesting.currentBox.tokenPrice" })}`}
                </Typography>
                <Typography variant="body2" component="p">
                  {`${tokenPriceTicker} ${splitTokenPrice}`}
                </Typography>
              </Box>
            </StyledContentPricesItem>
            <StyledContentPricesItem>
              <StyledAvatar src={selected.template!.price!.components[0].template!.imageUrl} />
              <Box className="PricesItem-content">
                <Typography variant="body1" component="p">
                  {`${formatMessage({ id: "pages.vesting.currentBox.boxPrice" })}`}
                </Typography>
                <Typography variant="body2" component="p">
                  {boxPrice}
                </Typography>
              </Box>
            </StyledContentPricesItem>
          </StyledContentPrices>
          {!isMedia920 && (
            <React.Fragment>
              <ProgressBars vestingBox={selected} totalTokensCount={numericTokensCount} />

              <ReleaseBox vestingBox={selected} />
            </React.Fragment>
          )}
        </StyledContentRightSide>
      </StyledContent>
      {isMedia920 && (
        <Box sx={{ width: "100%", marginTop: 2 }}>
          <ProgressBars vestingBox={selected} totalTokensCount={numericTokensCount} />

          <ReleaseBox vestingBox={selected} />
        </Box>
      )}
    </Root>
  );
};
