import { VestingBoxCategory } from "@framework/types";

import { IVestingOfferResponse } from "../types";

export const getBackgroundImg = (category: VestingBoxCategory) => {
  switch (category) {
    case VestingBoxCategory.PROMISING:
      return "/files/inner/promising_box.png";
    case VestingBoxCategory.SUCCESSFUL:
      return "/files/inner/successful_box.png";
    case VestingBoxCategory.TRENDING:
      return "/files/inner/trending_box.png";
    case VestingBoxCategory.MEME:
      return "/files/inner/meme_box.png";
    default:
      return "/files/inner/vesting.webp";
  }
};

export const isOfferGuard = (obj: any): obj is IVestingOfferResponse => {
  return "box" in obj && "owner" in obj && "templateId" in obj && "tokenId" in obj;
};

export const fixedTokenWithTicker = (tokenWithTicker: string, fixedValue: number) => {
  return `${tokenWithTicker.split(" ")[0]} ${Number(tokenWithTicker.split(" ")[1]).toFixed(fixedValue)}`;
};
