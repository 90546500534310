import { Box, styled } from "@mui/material";

import { pageHeader, sizeDecreaseCalc } from "../../../../../shared";

export const Root = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",

  "& h2": {
    ...pageHeader(),
  },

  "& h4": {
    ...pageHeader(),
    margin: 0,
    color: "#6C6C6C",
    fontSize: sizeDecreaseCalc(22, 16),
    fontWeight: 500,
    lineHeight: "140%",
    textAlign: "center",
  },
}));
