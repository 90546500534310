import React, { MouseEvent } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";

import { useAppDispatch } from "@dzambalaorg/redux";
import { walletActions } from "@dzambalaorg/provider-wallet";
import { useUser } from "@dzambalaorg/provider-user";
import { IUser } from "@framework/types";

import { PATHS } from "../../../../../../shared";
import { SimpleButton } from "../../../../../../components/buttons/simple";
import { BOXES_MEDIA_QUERY } from "../constants";

export const ApplyButton = () => {
  const dispatch = useAppDispatch();
  const { setIsDialogOpen } = walletActions;

  const user = useUser<IUser>();
  const isUserAuthenticated = user.isAuthenticated();

  const { formatMessage } = useIntl();
  const theme = useTheme();
  const isMedia615 = useMediaQuery(theme.breakpoints.down(BOXES_MEDIA_QUERY));

  const onClickHandler = (event: MouseEvent<HTMLButtonElement>) => {
    if (!isUserAuthenticated) {
      event.preventDefault();
      dispatch(setIsDialogOpen(true));
    }
  };

  const buttonText = isUserAuthenticated
    ? formatMessage({ id: "pages.vesting.boxes.applyForVesting" })
    : formatMessage({ id: "components.header.wallet.connect" });

  return (
    <SimpleButton
      component={Link}
      onClick={onClickHandler}
      to={PATHS.VestingApplication}
      buttonVariant={isMedia615 ? "link" : "primary"}
    >
      {buttonText}
    </SimpleButton>
  );
};
