import { useLayoutEffect } from "react";
import { collectionActions } from "@dzambalaorg/provider-collection";
import { useUser } from "@dzambalaorg/provider-user";
import { useAppDispatch } from "@dzambalaorg/redux";
import { TokenMetadata } from "@framework/types";

import { AssetsDashboardTabs, BoxesAssetVariant } from "../../../shared";

const searchParamsByVariant: Record<BoxesAssetVariant, (...args: any) => Record<string, any>> = {
  [AssetsDashboardTabs.boxes]: () => ({
    metadata: {
      [TokenMetadata.SPLIT_ID]: "SPLIT_ID",
    },
  }),
  [AssetsDashboardTabs.offers]: ({ account }) => ({
    account,
  }),
};

export const useChangeAssetsTab = (variant: BoxesAssetVariant) => {
  const dispatch = useAppDispatch();
  const { setSearch } = collectionActions;
  const user = useUser<any>();

  useLayoutEffect(() => {
    dispatch(setSearch(searchParamsByVariant[variant]({ account: user.profile.wallet })));
  }, [variant]);
};
