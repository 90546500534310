export const PATHS = {
  Main: "/",
  Dashboard: "/dashboard",
  Login: "/login",
  VestingBoxes: "/vesting/boxes",
  CurrentBox: "/vesting/box/:boxId",
  VestingApplication: "/vesting/application",
  VestingCreate: "/vesting/create",
  Tokens: "/tokens",
  Token: "/token/:tokenId",
  Error: "/error/:error",
  Message: "/message/:message",
};
