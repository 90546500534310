import React, { SVGProps } from "react";

export const InfoSvg = ({ width = 16, height = 16 }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.33333 8.33333L8.36978 8.31556C8.48376 8.25861 8.61167 8.23553 8.73837 8.24903C8.86507 8.26253 8.98524 8.31206 9.08466 8.39174C9.18408 8.47143 9.25858 8.57794 9.29935 8.69866C9.34011 8.81937 9.34543 8.94924 9.31467 9.07289L8.68533 11.5938C8.65435 11.7175 8.6595 11.8475 8.70017 11.9683C8.74084 12.0892 8.81532 12.1959 8.91479 12.2757C9.01425 12.3555 9.13452 12.4051 9.26133 12.4186C9.38814 12.4322 9.51616 12.409 9.63022 12.352L9.66667 12.3333M17 9C17 10.0506 16.7931 11.0909 16.391 12.0615C15.989 13.0321 15.3997 13.914 14.6569 14.6569C13.914 15.3997 13.0321 15.989 12.0615 16.391C11.0909 16.7931 10.0506 17 9 17C7.94943 17 6.90914 16.7931 5.93853 16.391C4.96793 15.989 4.08601 15.3997 3.34315 14.6569C2.60028 13.914 2.011 13.0321 1.60896 12.0615C1.20693 11.0909 1 10.0506 1 9C1 6.87827 1.84285 4.84344 3.34315 3.34315C4.84344 1.84285 6.87827 1 9 1C11.1217 1 13.1566 1.84285 14.6569 3.34315C16.1571 4.84344 17 6.87827 17 9ZM9 5.66667H9.00711V5.67378H9V5.66667Z"
        stroke="#2775CA"
        strokeWidth="1.46667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
