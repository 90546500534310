import { useEffect, useState } from "react";
import { useParams } from "react-router";

import { useApiCall } from "@dzambalaorg/react-hooks";
import { type ITemplate, IToken, IVestingBox } from "@framework/types";

import { BoxesAssetVariant } from "../../../shared";

export type ISelected = Omit<IToken, "template"> & {
  template: ITemplate & { box: IVestingBox };
};

type Params = { tokenId: string; variant: BoxesAssetVariant };

export const useFetchToken = () => {
  const { tokenId, variant } = useParams<Params>();
  const [selected, setSelected] = useState<ISelected | null>(null);

  const { fn: getContractFn, isLoading } = useApiCall(
    api =>
      api.fetchJson({
        url: `/tokens/${tokenId}`,
      }),
    {
      success: false,
      error: false,
    },
  );

  const getBox = async () => {
    const token = await getContractFn();
    if (token) {
      setSelected(token);
    }
  };

  useEffect(() => {
    void getBox();
  }, []);

  return {
    selected,
    isLoading,
    variant,
  };
};
