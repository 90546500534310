import { Children, cloneElement, FC, PropsWithChildren, ReactElement } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useWeb3React } from "@web3-react/core";
import { FormattedMessage } from "react-intl";

import { usePopup } from "@dzambalaorg/provider-popup";
import { useUser } from "@dzambalaorg/provider-user";
import { useAppDispatch, useAppSelector } from "@dzambalaorg/redux";
import {
  walletActions,
  walletSelectors,
  useWallet,
  WALLET_MENU_POPUP_TYPE,
  TConnectors,
} from "@dzambalaorg/provider-wallet";
import { MetaMaskIcon, ParticleIcon, WalletConnectIcon } from "@dzambalaorg/mui-icons";

import { WalletMenuDialog } from "../../dialogs/wallet";
import { ConnectWallet } from "../../dialogs/connect";
import { StyledButton } from "./styled";
import { StyledBadge, StyledCircle } from "../network/styled";
import { ConnectSvg } from "../../../shared";
import { CloseButton } from "../close";

const IconByConnector = {
  [TConnectors.METAMASK]: <MetaMaskIcon viewBox="0 0 60 60" />,
  [TConnectors.WALLETCONNECT]: <WalletConnectIcon viewBox="0 0 60 60" />,
  [TConnectors.PARTICLE]: <ParticleIcon />,
};

export const WalletButton: FC<PropsWithChildren> = props => {
  const { children } = props;

  const { isOpenPopup, openPopup, closePopup } = usePopup();
  const { chainId } = useWeb3React();
  const { profile } = useUser<any>();
  const { closeConnectWalletDialog } = useWallet();
  const isDialogOpen = useAppSelector<boolean>(walletSelectors.isDialogOpenSelector);
  const activeConnector = useAppSelector<TConnectors>(walletSelectors.activeConnectorSelector);
  const dispatch = useAppDispatch();
  const { setIsDialogOpen } = walletActions;

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpenWalletMenuDialog = () => {
    openPopup(WALLET_MENU_POPUP_TYPE);
  };

  const handleOpenConnectWalletDialog = () => {
    dispatch(setIsDialogOpen(true));
  };

  const handleCloseWalletDialog = () => {
    closePopup();
  };

  const isChainValid = !chainId || profile?.chainId === chainId;

  const walletDisplaying = !isSm
    ? `${profile?.wallet.slice(0, 6)}...${profile?.wallet.slice(-4)}`
    : `${profile?.wallet.slice(0, 4)}...${profile?.wallet.slice(-4)}`;

  return (
    <Box>
      <StyledBadge badgeContent={<StyledCircle />} invisible={isChainValid}>
        <StyledButton
          isLoggedIn={!!profile?.wallet}
          color="inherit"
          onClick={handleOpenConnectWalletDialog}
          data-testid="OpenWalletOptionsDialog"
        >
          {!profile?.wallet ? <ConnectSvg /> : IconByConnector[activeConnector]}
          <FormattedMessage
            tagName={"span"}
            id={profile?.wallet ? "components.header.menu.inner.connected" : "components.header.menu.inner.connect"}
            values={{ wallet: walletDisplaying }}
          />
        </StyledButton>
      </StyledBadge>
      {profile?.wallet && <CloseButton onClick={handleOpenWalletMenuDialog} />}
      <ConnectWallet onClose={closeConnectWalletDialog} open={isDialogOpen}>
        {Children.map(children as Array<any>, (button: ReactElement) =>
          cloneElement(button, {
            onTokenVerified: closeConnectWalletDialog,
          }),
        )}
      </ConnectWallet>
      <WalletMenuDialog onClose={handleCloseWalletDialog} open={isOpenPopup(WALLET_MENU_POPUP_TYPE)} />
    </Box>
  );
};
