import React, { FC, PropsWithChildren } from "react";

import { StyledBoxWithTitleRoot } from "./styled";
import { Typography } from "@mui/material";

interface IBoxWithTitleProps extends PropsWithChildren {
  title: string;
  description?: string;
}

export const BoxWithTitle: FC<IBoxWithTitleProps> = props => {
  const { title, description, children } = props;
  return (
    <StyledBoxWithTitleRoot>
      <Typography variant="body1" component="h4">
        {title}
      </Typography>
      {description && (
        <Typography variant="body1" component="p">
          {description}
        </Typography>
      )}
      {children}
    </StyledBoxWithTitleRoot>
  );
};
