export const faqData: Array<{
  question: string;
  answers: { [key: string]: string };
  expanded: "whatIs" | "relationship" | "functionality";
}> = [
  {
    question: "pages.landing.faq.questions.whatIs.question",
    answers: {
      0: "pages.landing.faq.questions.whatIs.answers.0",
      1: "pages.landing.faq.questions.whatIs.answers.1",
      2: "pages.landing.faq.questions.whatIs.answers.2",
      3: "pages.landing.faq.questions.whatIs.answers.3",
      4: "pages.landing.faq.questions.whatIs.answers.4",
    },
    expanded: "whatIs",
  },
  {
    question: "pages.landing.faq.questions.relationship.question",
    answers: {
      0: "pages.landing.faq.questions.relationship.answers.0",
    },
    expanded: "relationship",
  },
  {
    question: "pages.landing.faq.questions.functionality.question",
    answers: {
      0: "pages.landing.faq.questions.functionality.answers.0",
      1: "pages.landing.faq.questions.functionality.answers.1",
      2: "pages.landing.faq.questions.functionality.answers.2",
      3: "pages.landing.faq.questions.functionality.answers.3",
    },
    expanded: "functionality",
  },
];
