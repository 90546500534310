import React from "react";

import { FormWrapper } from "@dzambalaorg/mui-form";
import { TextInput } from "@dzambalaorg/mui-inputs-core";
import { DateInput } from "@dzambalaorg/mui-inputs-picker";
import type { IApplyVestingGeneral } from "@framework/types";

import { Root } from "./styled";
import { validationSchema } from "./validation";
import { firstStepFormEmpty } from "./empty";
import { StepperActiveSteps, useStepperContext } from "../context";

export const FirstStepForm = () => {
  const { setFirstStepData, handleNext } = useStepperContext();

  const onSubmit = async (data: IApplyVestingGeneral) => {
    setFirstStepData(data);
    handleNext(StepperActiveSteps.SECOND);
  };

  return (
    <Root>
      <FormWrapper
        sx={{ minWidth: "320px", display: "flex", flexDirection: "column", gap: 3, paddingInline: "16px" }}
        initialValues={firstStepFormEmpty}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        submit={"next"}
      >
        <DateInput name="date" variant="outlined" textFieldSlotProps={{ required: true, width: "100%" }} format="dd-MM-yyyy" />
        <TextInput name="projectName" required variant="outlined" />
        <TextInput name="fullTokenName" required variant="outlined" />
        <TextInput name="tokenTicker" required variant="outlined" />
        <TextInput name="email" required variant="outlined" />
      </FormWrapper>
    </Root>
  );
};
