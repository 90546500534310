import { Grid2, styled } from "@mui/material";
import { contentSideFlex } from "../../../../shared";

export const gridItemsSizes = { xl: 6, lg: 6, md: 6, sm: 12, xs: 12 };

export const StyledRoot = styled(Grid2)(({ theme }) => ({
  width: "100%",

  "& h6": {
    width: "100%",
    color: "#000000",
    fontSize: theme.spacing(2),
    fontWeight: 500,
    lineHeight: "130%",
    margin: 0,
  },

  "& .OfferManagement-offerBox": {
    ...contentSideFlex,
    flexDirection: "row",
    alignItems: "flex-end",
    gap: theme.spacing(3),
  },

  "& .OfferManagement-offerBtn": {
    minWidth: "158px",
    background: "#285513",
    border: "1px solid #285513",
    color: "#FFFFFF",
    fontSize: theme.spacing(2),
    textTransform: "none",
    borderRadius: theme.spacing(3.75),
    padding: theme.spacing(1, 2.25),
    "&:hover": {
      background: "#448327",
    },
  },
}));
