import React from "react";
import { useIntl } from "react-intl";
import { Step, StepLabel } from "@mui/material";

import { StyledStepper } from "./styled";
import { useStepperContext } from "../context";

const steps = [
  "pages.vesting.application.step_1",
  "pages.vesting.application.step_2",
  "pages.vesting.application.step_3",
];

export const FormStepper = () => {
  const { formatMessage } = useIntl();
  const { activeStep } = useStepperContext();

  return (
    <StyledStepper sx={{ width: "100%", marginTop: 3 }} activeStep={activeStep}>
      {steps.map((id, index, array) => {
        const completed = activeStep === array.length - 1  || index < activeStep;
        return (
          <Step key={id} completed={completed}>
            <StepLabel>{formatMessage({ id })}</StepLabel>
          </Step>
        );
      })}
    </StyledStepper>
  );
};
