import { FormattedMessage, useIntl } from "react-intl";
import { Button, Grid, useTheme } from "@mui/material";
import { FilterList } from "@mui/icons-material";

import { CommonSearchForm } from "@dzambalaorg/mui-form-search";
import { AutocompleteInput } from "@dzambalaorg/mui-inputs-core";
import { PageHeader, ProgressOverlay } from "@dzambalaorg/mui-page-layout";
import { useCollection } from "@dzambalaorg/provider-collection";

import { IVestingBox, IVestingBoxInMarketSearchDto, VestingBoxCategory } from "@framework/types";
import { StyledEmptyWrapper, StyledPagination } from "@framework/styled";

import { PATHS, BoxCard, StyledListRoot } from "../../../../../shared";
import { boxesHeaderStyles } from "./styled";
import { ApplyButton } from "./apply-button";

export const VestingBoxes = () => {
  const { rows, count, search, isLoading, handleChangePage, isFiltersOpen, handleToggleFilters, handleSearch } =
    useCollection<IVestingBox, IVestingBoxInMarketSearchDto>({
      baseUrl: PATHS.VestingBoxes,
      search: {
        query: "",
        vestingBoxCat: [],
      },
    });

  const theme = useTheme();
  const { formatMessage } = useIntl();

  return (
    <StyledListRoot>
      <PageHeader sx={boxesHeaderStyles(theme)} message="pages.vesting.boxes.title">
        <Button startIcon={<FilterList />} onClick={handleToggleFilters} data-testid="ToggleFilterButton">
          <FormattedMessage id={`form.buttons.${isFiltersOpen ? "hideFilters" : "showFilters"}`} />
        </Button>
        <ApplyButton />
      </PageHeader>

      <CommonSearchForm
        onSubmit={handleSearch}
        initialValues={search}
        open={isFiltersOpen}
        testId="VestingboxSearchForm"
      >
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item xs={12} md={6} lg={6} className="BoxesFilters-autocomplete">
            <AutocompleteInput
              multiple
              readOnly
              variant="outlined"
              name="vestingBoxCat"
              label=""
              options={Object.entries(VestingBoxCategory).map(([key, value]) => ({
                key,
                value: formatMessage({ id: `enums.vestingBoxCat.${value}` }),
              }))}
            />
          </Grid>
        </Grid>
      </CommonSearchForm>

      <ProgressOverlay isLoading={isLoading}>
        <Grid container spacing={2}>
          <StyledEmptyWrapper count={rows.length} isLoading={isLoading}>
            {rows.map(vestingBox => (
              <Grid item lg={4} md={6} sm={12} xs={12} key={vestingBox.id}>
                <BoxCard vestingBox={vestingBox} />
              </Grid>
            ))}
          </StyledEmptyWrapper>
        </Grid>
      </ProgressOverlay>

      {!!count && count > search.take && (
        <StyledPagination
          shape="rounded"
          page={search.skip / search.take + 1}
          count={Math.ceil(count / search.take)}
          onChange={handleChangePage}
        />
      )}
    </StyledListRoot>
  );
};
