import { IVestingBox, ShapeType } from "@framework/types";

import { plotDataByShape, Time, timeValues, TPlotConfig } from "../index";

export type BoxPlotValues = Partial<
  Pick<IVestingBox, "shape" | "cliff" | "duration" | "period" | "afterCliffBasisPoints" | "growthRate"> & {
    startTimestamp: string;
  }
>;

export const useRenderPlot = (boxValues: BoxPlotValues) => {
  const {
    shape = ShapeType.LINEAR,
    cliff = 0,
    duration = timeValues[Time.YEAR],
    period = 1,
    afterCliffBasisPoints = 0,
    growthRate = 100,
    startTimestamp = new Date().toDateString(),
  } = boxValues;

  const durationInDays = duration / 86400;
  const cliffInDays = cliff / 86400;
  const periodInDays = period ? period / 86400 : timeValues[Time.ONE_DAY] / 86400;
  const immediateUnlockPercentage = afterCliffBasisPoints / 100;
  const immediateUnlockPercentageRestPercent = immediateUnlockPercentage ? 100 - immediateUnlockPercentage : 0;
  const nthGrowthRate = growthRate / 100;

  const config: TPlotConfig = {
    duration: durationInDays + cliffInDays,
    cliff: cliffInDays,
    period: periodInDays,
    growthRate: nthGrowthRate,
    immediateUnlockPercentage,
    immediateUnlockPercentageRestPercent,
    afterCliffBasisPoints,
    startTimestamp,
  };

  const options = {
    xDomainLimit: durationInDays + cliffInDays,
    yDomainLimit: 100,
    functionType: shape.split("_")[0],
    cliff: cliff || 0,
    duration,
    period: period || 1,
    afterCliffBasisPoints: afterCliffBasisPoints || 0,
    data: plotDataByShape[shape](config),
  };

  return { data: plotDataByShape[shape](config) };
};
