import { Box } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { aboutBlockData } from "./schemas";
import { StyledCard, StyledCardBox } from "./styled";

export const MainPageCards = () => {
  return (
    <Box className="MainPage-cardBoxWrapper" id="aboutUs">
      <StyledCardBox container mt={4} spacing={2}>
        {aboutBlockData.map(({ title, subtitle, icon }, index) => (
          <StyledCard key={title} index={index} size={{ lg: 6, md: 6, xs: 12 }}>
            {icon}
            <FormattedMessage id={title} tagName="h4" />
            {subtitle && <FormattedMessage id={subtitle} tagName="p" />}
          </StyledCard>
        ))}
      </StyledCardBox>
    </Box>
  );
};
