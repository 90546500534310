import { Box, BoxProps, styled } from "@mui/material";
import { Direction } from "./types";

interface IRoot {
  direction: Direction;
}

export const Root = styled(Box, { shouldForwardProp: prop => prop !== "direction" })<BoxProps & IRoot>(
  ({ theme, direction }) => ({
    width: "100%",
    display: "flex",
    flexDirection: direction,
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
    padding: theme.spacing(1.5, 2, 1.5, 0),
    border: "2px solid #AEC8E4",
    borderRadius: theme.spacing(1.25),

    [theme.breakpoints.down("lg")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },

    "& h4": {
      margin: 0,
      paddingLeft: theme.spacing(2),
      color: "#000000",
      fontSize: theme.spacing(2.5),
      fontWeight: 500,
      lineHeight: "140%",
    },

    "& h5": {
      margin: 0,
      paddingLeft: theme.spacing(2),
      fontSize: theme.spacing(2.5),
      fontWeight: 500,
      lineHeight: "140%",
    },

    "& p": {
      flexBasis: "30%",
      margin: 0,
      marginLeft: direction === "column" ? theme.spacing(2) : 0,
      [theme.breakpoints.down("lg")]: {
        marginLeft: theme.spacing(2),
      },
    },

    "& .recharts-surface": {
      width: "50%",
    },

    "& .recharts-layer": {
      "& .recharts-curve": {
        stroke: "#57D5FD !important",
      },

      "& .recharts-active-dot": {
        "& path": {
          stroke: "#71D7F8",
        },
      },
    },

    "& .recharts-brush": {
      "& :first-of-type": {
        rx: 3,
        ry: 3,
      },
      "& .recharts-brush-slide": {
        fillOpacity: 0.8,
        fill: "#2775CA",
      },
      "& .recharts-brush-traveller": {
        "& rect": {
          fill: "#1B5DA5",
        },
        "& line": {
          stroke: "none",
        },
      },
      "& .recharts-brush-texts": {
        "& .recharts-text": {
          fill: "#000000",
        },
      },
    },
  }),
);

export const StyledPlotBox = styled(Box, { shouldForwardProp: prop => prop !== "direction" })<BoxProps & IRoot>(
  ({ theme, direction }) => ({
    width: direction === "column" ? "100%" : "70%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: theme.spacing(2),

    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  }),
);
