import { FC, PropsWithChildren, useState } from "react";
import { Box, styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#AEC8E4",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: theme.spacing(1.75),
    fontWeight: 400,
    lineHeight: 1.43,
    color: "#000000",
    backgroundColor: "#FFFFFF",
    border: "1px solid #AEC8E4",
    padding: theme.spacing(1),
  },
}));

export const CustomTooltip: FC<
  PropsWithChildren<{
    title: string;
  }>
> = props => {
  const [isOpen, setIsOpen] = useState(false);
  const { title, children } = props;

  const toggleOpen = () => setIsOpen(prevState => !prevState);

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  return (
    <BootstrapTooltip open={isOpen} title={title}>
      <Box onClick={toggleOpen} onMouseEnter={onOpen} onMouseLeave={onClose} sx={{ display: "flex" }}>
        {children}
      </Box>
    </BootstrapTooltip>
  );
};
