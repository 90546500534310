import { Box, BoxProps, styled, SxProps } from "@mui/material";

import { MenuVariant } from "../../../shared";

interface IRootProps {
  menuVariant: MenuVariant;
}

export const stylesByMenuVariant = ({
  theme,
}: any): {
  [Key in MenuVariant]: SxProps;
} => ({
  [MenuVariant.LANDING]: {
    width: "50%",
    maxWidth: "308px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      flexDirection: "column",
      alignItems: "flex-end",
      rowGap: theme.spacing(3),
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(3),
    },
  },
  [MenuVariant.INNER]: {
    minWidth: "336px",
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    columnGap: theme.spacing(4),
    borderRadius: "100px",
    padding: theme.spacing(3.125, 2.5),

    [theme.breakpoints.down("md")]: {
      width: "100%",
      flexDirection: "column",
      alignItems: "flex-end",
      rowGap: theme.spacing(3),
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(3),
    },
  },
});

export const Root = styled(Box, { shouldForwardProp: prop => prop !== "menuVariant" })<BoxProps & IRootProps>(
  ({ theme, menuVariant }) => ({
    ...stylesByMenuVariant({ theme })[menuVariant],
  }),
);
