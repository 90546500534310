import React from "react";
import { useFormContext } from "react-hook-form";
import { Grid2 } from "@mui/material";

import { DateInput } from "@dzambalaorg/mui-inputs-picker";

import { ClearIcon } from "../../../../shared";

export const TgeRange = () => {
  const form = useFormContext<any>();
  const tgeRangeStart = form.watch("tgeRangeStart");
  const tgeRangeEnd = form.watch("tgeRangeEnd");

  return (
    <Grid2 container spacing={1} size={{ xs: 12, md: 4, lg: 4 }} alignItems="flex-end">
      <Grid2 size={{ xs: 12, md: 6, lg: 6 }} className="BoxesFilters-grid">
        <DateInput
          name="tgeRangeStart"
          variant="outlined"
          closeOnSelect={true}
          textFieldSlotProps={{
            InputProps: {
              endAdornment: tgeRangeStart ? <ClearIcon form={form} name="tgeRangeStart" /> : null,
            },
          }}
        />
      </Grid2>

      <Grid2 size={{ xs: 12, md: 6, lg: 6 }} className="BoxesFilters-grid">
        <DateInput
          name="tgeRangeEnd"
          variant="outlined"
          closeOnSelect={true}
          textFieldSlotProps={{
            InputProps: { endAdornment: tgeRangeEnd ? <ClearIcon form={form} name="tgeRangeEnd" /> : null },
          }}
        />
      </Grid2>
    </Grid2>
  );
};
