import { FC, PropsWithChildren } from "react";
import { SnackbarProvider } from "notistack";
import { Global } from "@emotion/react";

import { ApiProviderFirebase } from "@dzambalaorg/provider-api-firebase";
import { UserProviderFirebase } from "@dzambalaorg/provider-user-firebase";
import { layoutSlice, ThemeProvider } from "@dzambalaorg/provider-theme";
import { LicenseProvider } from "@dzambalaorg/provider-license";
import { LocalizationProvider, localizationSlice } from "@dzambalaorg/provider-localization";
import { collectionSlice } from "@dzambalaorg/provider-collection";
import { PopupProvider } from "@dzambalaorg/provider-popup";
import { PickerProvider } from "@dzambalaorg/provider-picker";
import { WalletProvider, walletSlice } from "@dzambalaorg/provider-wallet";
import { createStore, ReduxProvider } from "@dzambalaorg/redux";
import { EnabledLanguages, ns } from "@framework/constants";
import { i18n } from "@framework/localization-market-ui";

import { Signal } from "../components/signal";
import { themeProps } from "../components/theme";
import { globalStyles } from "../components/globalStyles";
import { customErrors, PATHS } from "../shared";

export const Providers: FC<PropsWithChildren> = props => {
  const { children } = props;
  return (
    <ReduxProvider store={createStore([collectionSlice, localizationSlice, layoutSlice, walletSlice])}>
      <ApiProviderFirebase baseUrl={process.env.BE_URL} storageName={ns}>
        <LicenseProvider licenseKey={process.env.GEMUNION_API_KEY}>
          <UserProviderFirebase redirectUrl={`${PATHS.VestingBoxesBase}/boxes`}>
            <ThemeProvider {...themeProps}>
              <Global styles={globalStyles} />
              <LocalizationProvider i18n={i18n} defaultLanguage={EnabledLanguages.EN}>
                <SnackbarProvider>
                  <PopupProvider>
                    <WalletProvider customProjectErrors={customErrors}>
                      <PickerProvider>
                        <Signal />
                        {children}
                      </PickerProvider>
                    </WalletProvider>
                  </PopupProvider>
                </SnackbarProvider>
              </LocalizationProvider>
            </ThemeProvider>
          </UserProviderFirebase>
        </LicenseProvider>
      </ApiProviderFirebase>
    </ReduxProvider>
  );
};
