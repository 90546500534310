export enum Distribution {
  TokensPerSecond = "tokensPerSecond",
  TokensPerMinute = "tokensPerMinute",
  TokensPerHour = "tokensPerHour",
  TokensPerDay = "tokensPerDay",
  TokensPerMonth = "tokensPerMonth",
  TokensPerYear = "tokensPerYear",
}

const FIVE_DAYS = 5;
const YEAR_IN_SECONDS = 31104000;

export const setDistributions = (vestingDurationInSeconds: number) => {
  if (vestingDurationInSeconds >= YEAR_IN_SECONDS) {
    return Object.values(Distribution) as Array<Distribution>;
  } else {
    return Object.values(Distribution).filter(d => d !== Distribution.TokensPerYear) as Array<Distribution>;
  }
}

export type TokenDistribution = {
  [Key in Distribution]: (totalTokens: number, vestingDurationInSeconds: number) => number;
};

export const gweiMultiplier: {
  [Key in Distribution]: {
    value: number;
    text: string;
  };
} = {
  [Distribution.TokensPerSecond]: { value: 1e9, text: "Gwei" },
  [Distribution.TokensPerMinute]: { value: 1e9, text: "Gwei" },
  [Distribution.TokensPerHour]: { value: 1e9, text: "Gwei" },
  [Distribution.TokensPerDay]: { value: 1e9, text: "Gwei" },
  [Distribution.TokensPerMonth]: { value: 1, text: "" },
  [Distribution.TokensPerYear]: { value: 1, text: "" },
};

export const calculateTokenDistribution: TokenDistribution = {
  [Distribution.TokensPerSecond]: (totalTokens, vestingDurationInSeconds) => totalTokens / vestingDurationInSeconds,
  [Distribution.TokensPerMinute]: (totalTokens, vestingDurationInSeconds) =>
    (totalTokens / vestingDurationInSeconds) * 60,
  [Distribution.TokensPerHour]: (totalTokens, vestingDurationInSeconds) =>
    (totalTokens / vestingDurationInSeconds) * 60 * 60,
  [Distribution.TokensPerDay]: (totalTokens, vestingDurationInSeconds) =>
    (totalTokens / vestingDurationInSeconds) * 60 * 60 * 24,
  [Distribution.TokensPerMonth]: (totalTokens, vestingDurationInSeconds) =>
    (totalTokens / vestingDurationInSeconds) * 60 * 60 * 24 * 30,
  [Distribution.TokensPerYear]: (totalTokens, vestingDurationInSeconds) =>
    (totalTokens / vestingDurationInSeconds) * (60 * 60 * 24 * 30 * 12 + FIVE_DAYS),
};
