import React, { FC, PropsWithChildren } from "react";
import { FormattedMessage } from "react-intl";
import { Box, BoxProps, Typography } from "@mui/material";

import { StyledCalculationBoxRoot, StyledCalculationItem } from "./styled";

interface IWithBorderInfoBoxProps extends BoxProps {
  title: string;
  data?: Record<string, string | number>;
}

export const WithBorderInfoBox: FC<PropsWithChildren<IWithBorderInfoBoxProps>> = props => {
  const { title, data, children, ...restBoxProps } = props;
  return (
    <StyledCalculationBoxRoot {...restBoxProps}>
      <Typography variant="body1" component="h6">
        {title}
      </Typography>
      {data &&
        Object.entries(data).map(([key, value]) => {
          return (
            <StyledCalculationItem key={key}>
              <Box className="CalculationItem-text">
                <FormattedMessage
                  id={`pages.vesting.currentBox.calculationItems.${key}`}
                  tagName="p"
                  values={{ span: chunks => <span>{chunks}</span>, value }}
                />
              </Box>
            </StyledCalculationItem>
          );
        })}
      {children}
    </StyledCalculationBoxRoot>
  );
};
