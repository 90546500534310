import React, { FC, useMemo, useState } from "react";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { IToken, TVestingTemplate } from "@framework/types";

import { VestingBoxCategoryBadge } from "../../../components/common/badge";
import { StyledCardMedia } from "../styled";
import { PATHS } from "../../constants";
import { getBackgroundImg, sizeDecreaseCalc, Vesting, VestingBox } from "../../utils";
import { useVestingContractInfo } from "../../hooks";
import { pageHeader } from "../../styles";
import { BoxesAssetVariant } from "../../types";
import { Root, StyledCardActionArea, StyledCardActions, StyledCardActionsInfo } from "./styled";

interface IBoxCard {
  template: TVestingTemplate;
  token: IToken;
  variant: BoxesAssetVariant;
}

export const TokenCard: FC<IBoxCard> = React.memo(props => {
  const { template, token, variant } = props;

  const [isHovered, setIsHovered] = useState<boolean>(false);
  const { formatMessage } = useIntl();

  const vestingBox = useMemo(() => {
    return new VestingBox(template.box, template.price!);
  }, []);

  const { tge } = vestingBox.getBoxInfo(template.createdAt);

  const { releasable, withdrawn } = useVestingContractInfo(token.tokenId, token.template!.contract!.address);

  const vesting = useMemo(() => {
    return new Vesting(template.box, template.price!, releasable, withdrawn);
  }, [releasable, withdrawn]);

  const backgroundImg = template.box.backgroundImg || getBackgroundImg(template.box.vestingBoxCat);

  return (
    <Root
      component={Link}
      to={`${PATHS.Asset.replace(":tokenId", `${token?.id}`).replace(":variant", `${variant}`)}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <StyledCardActionArea
        sx={{
          transform: isHovered ? "scale(1.04)" : "scale(1.0)",
          transition: "transform 0.3s ease-in-out",
        }}
      >
        <VestingBoxCategoryBadge category={template.box.vestingBoxVariant} />
        <StyledCardMedia className="BoxCard-media" component="img" image={backgroundImg} />
      </StyledCardActionArea>
      <StyledCardActions>
        <Typography
          sx={{
            ...pageHeader(),
            fontSize: sizeDecreaseCalc(20, 16),
          }}
          variant="body1"
          component="h5"
        >
          {`${template.title} #${token.tokenId}`}
        </Typography>
        <StyledCardActionsInfo sx={{ paddingTop: 1.25 }}>
          <span className="CardTopInfoItem-semibold">{formatMessage({ id: "pages.vesting.currentBox.tge" })}</span>
          <span className="CardTopInfoItem-semibold">{formatMessage({ id: "pages.tokens.releasable" })}</span>
        </StyledCardActionsInfo>
        <StyledCardActionsInfo>
          <span className="CardTopInfoItem-bold">{tge}</span>
          <span className="CardTopInfoItem-green">{`${vesting.getReleasablePercentageDisplaying()}%`}</span>
        </StyledCardActionsInfo>
      </StyledCardActions>
    </Root>
  );
});
