import { Grid2, styled } from "@mui/material";

import { contentSideFlex } from "./index";

export const StyledContentRightSide = styled(Grid2)(({ theme }) => ({
  ...contentSideFlex,
  alignItems: "flex-start",

  "& .CurrentToken-Buttons-box": {
    ...contentSideFlex,
    flexDirection: "row",
    gap: theme.spacing(2),
    paddingTop: theme.spacing(1.5),

    "& .MuiButtonBase-root": {
      padding: theme.spacing(1.5, 2.5),
      borderRadius: theme.spacing(3.75),
      color: "#FFFFFF !important",
      textAlign: "center",
      fontSize: theme.spacing(2),
      fontWeight: 500,
      lineHeight: "135%" /* 24.3px */,

      "&.CurrentToken-releaseBtn": {
        minWidth: "158px",
        textTransform: "none",
        background: "#1B5DA5",
        border: "1px solid #1B5DA5",
        "&:hover": {
          background: "#288efd",
        },
      },
    },
  },
}));
