import { Box, Grid2, styled, SxProps, Typography } from "@mui/material";
import { descriptionStyles, sizeDecreaseCalc } from "../../../../../shared";

export const pageHeaderStyles: SxProps = {
  "& .MuiGrid-item": { margin: 0 },
  "& .MuiTypography-root": {
    fontSize: sizeDecreaseCalc(36, 18),
  },
};

export const Root = styled(Box)(({ theme }) => ({
  gap: theme.spacing(1.5),
  marginBottom: theme.spacing(3),
}));

export const StyledContent = styled(Grid2)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  gap: theme.spacing(2.5),
  marginBottom: theme.spacing(3),
}));

export const StyledImage = styled(Box)(({ theme }) => ({
  display: "block",
  width: "100%",
  height: 263,
  border: "none",
  objectFit: "cover",
  borderRadius: theme.spacing(1.25),
})) as typeof Box;

export const StyledHeaderBox = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "space-between",

  "& .MuiGrid-container": {
    width: "fit-content",
  },

  "& .MuiButtonBase-root": {
    width: sizeDecreaseCalc(120, 100),
    height: sizeDecreaseCalc(47, 36),
    padding: theme.spacing(1.5, 2.5),
    borderRadius: theme.spacing(3.75),
    border: "1px solid #285513",
    background: "#285513",
    color: "#FFFFFF !important",
    textAlign: "center",
    fontSize: sizeDecreaseCalc(16, 12),
    fontWeight: 500,
    lineHeight: "135%" /* 24.3px */,
    textTransform: "none",

    "&:hover": {
      background: "#448327",
    },
  },
})) as typeof Box;

export const StyledDescription = styled(Typography)(({ theme }) => ({
  "& h4": {
    width: "100%",
    color: "#000000",
    fontSize: theme.spacing(3),
    fontWeight: 500,
    lineHeight: "140%",
    margin: 0,
    borderBottom: "1px solid #4BD4FF",
  },

  "& .DraftEditor-root": {
    ...descriptionStyles(theme),
    marginTop: theme.spacing(1.5),
  },
})) as typeof Typography;
