import React, { SVGProps } from "react";

export const StepsShape = ({ width = "66", height = "28" }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={width} height={height} viewBox="0 0 66 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M49.0001 9.3399H33.0001L33.0002 18.1699L17.0001 18.1699L16.9999 27.4999L65.4892 27.4999L65.4895 0.499512L49.0001 0.499901L49.0001 9.3399Z"
        fill="url(#paint0_linear_2749_3865)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.5 0.99989L65.4893 0.999512L65.4893 -0.000488281L48.5 -8.65161e-05V8.8399H32.4999L32.5 17.6699H16.4999V26.4998L0.510421 26.4991L0.510376 27.4991L17.4999 27.4998V18.6699H33.5L33.4999 9.8399H49.5V0.99989Z"
        fill="#71FFFF"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2749_3865"
          x1="63.4999"
          y1="-9.0001"
          x2="63.4999"
          y2="27.9999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#71FFFF" stopOpacity="0.6" />
          <stop offset="1" stopColor="#71FFFF" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
