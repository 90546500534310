export enum TabMode {
  SEARCH = "search",
  NAVIGATE = "navigate",
}

type MappedSpecificTabProps = {
  [TabMode.SEARCH]: {
    searchKey: string;
    handleSearch: (values: any) => Promise<void>;
  };
  [TabMode.NAVIGATE]: {
    basicPath: string;
  };
};

export type TabMenuProps<T extends Record<string, string>, M extends TabMode> = {
  tabs: T;
  initialTab: T[keyof T];
  basicLocale: string;
  mode: TabMode;
} & MappedSpecificTabProps[M];

export function isSearchMode<T extends Record<string, string>>(
  props: TabMenuProps<T, TabMode>,
): props is TabMenuProps<T, TabMode.SEARCH> {
  return props.mode === TabMode.SEARCH;
}

export function isNavigateMode<T extends Record<string, string>>(
  props: TabMenuProps<T, TabMode>,
): props is TabMenuProps<T, TabMode.NAVIGATE> {
  return props.mode === TabMode.NAVIGATE;
}
