import { Box, LinearProgress, linearProgressClasses, LinearProgressProps, styled } from "@mui/material";

import { contentSideFlex } from "./index";

interface IStyledLinearProgressProps {
  content: string | number;
  backgroundColor: string;
  barColor: string;
  indexerColor: string;
  value: number;
}

export const StyledProgressBarBox: any = styled(Box)(({ theme }) => ({
  width: "100%",
  ...contentSideFlex,
  alignItems: "flex-stat",
  marginBottom: theme.spacing(1.5),

  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },

  "& .MuiTypography-body1": {
    color: "#000000",
    fontSize: theme.spacing(2),
    fontWeight: 500,
    lineHeight: "130%" /* 22.4px */,
  },

  "& .ProgressBar-tooltip_box": {
    ...contentSideFlex,
    flexDirection: "row",
    justifyContent: "flex-start",
    gap: theme.spacing(2),
  },
}));

export const StyledLinearProgress = styled(LinearProgress, {
  shouldForwardProp: prop =>
    prop !== "content" && prop !== "backgroundColor" && prop !== "barColor" && prop !== "indexerColor",
})<LinearProgressProps & IStyledLinearProgressProps>(
  ({ theme, content, backgroundColor, barColor, indexerColor, value }) => ({
    width: "100%",
    height: 25,
    backgroundColor,
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: barColor,
      "&::before": {
        content: `"${content}"`,
        position: "absolute",
        right: value < 10 ? -10 : 0,
        top: 0,
        marginRight: value < 10 ? 0 : theme.spacing(1),
        transform: value < 10 ? "translateX(100%)" : "none",
        color: value < 10 ? "#000000" : "#FFFFFF",
        fontWeight: 700,
      },
      "&::after": {
        content: '""',
        position: "absolute",
        right: 0,
        top: 0,
        width: theme.spacing(0.5),
        height: "100%",
        backgroundColor: indexerColor,
      },
    },
  }),
);
