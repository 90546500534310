import React, { SVGProps } from "react";

export const SolutionsSvg = ({ width = "24", height = "24" }: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        d="M20.4928 8.5011C20.3389 8.5011 20.187 8.4314 20.0872 8.2991C18.7559 6.5326 16.9395 5.23561 14.8344 4.5484C14.2131 4.34555 13.5689 4.1986 12.9195 4.11159C12.9192 4.11155 12.9189 4.11152 12.9186 4.11145C10.0982 3.73399 7.29154 4.46953 5.01559 6.18277C4.79169 6.35129 4.47366 6.30641 4.30516 6.08259C4.13666 5.85877 4.18151 5.5407 4.40534 5.37221C6.89592 3.49735 9.96739 2.69248 13.0543 3.10599C13.0549 3.10606 13.0554 3.10613 13.0559 3.1062C13.7657 3.20143 14.4701 3.36214 15.1494 3.5839C17.4528 4.33595 19.4405 5.75525 20.8976 7.68848C21.0662 7.91223 21.0215 8.23031 20.7977 8.39893C20.7063 8.46782 20.5991 8.5011 20.4928 8.5011Z"
        fill="url(#paint0_linear_2162_3779)"
      />
      <path
        d="M14.4527 20.0997C14.3641 20.0997 14.2744 20.0765 14.1927 20.0277C13.9523 19.8838 13.874 19.5723 14.0179 19.3319C14.258 18.9306 14.4758 18.504 14.6653 18.0641C15.2362 16.7387 15.4161 15.6245 15.4577 15.3146C15.714 13.4011 14.5691 11.5778 12.7353 10.9791C12.5041 10.9037 12.264 10.8489 12.0217 10.8165C10.9746 10.6761 9.93145 10.9499 9.08482 11.5873C8.25892 12.209 7.71114 13.11 7.54237 14.1243C7.43022 14.7985 7.07414 15.4045 6.53972 15.8306C6.32058 16.0053 6.00147 15.9693 5.82675 15.7502C5.65209 15.5311 5.68805 15.212 5.90715 15.0373C6.24523 14.7677 6.47048 14.3844 6.54144 13.9579C6.75317 12.6855 7.43972 11.5557 8.47457 10.7767C9.5356 9.97796 10.8431 9.63496 12.1564 9.81083C12.4598 9.85148 12.7605 9.92003 13.0502 10.0146C15.3493 10.7651 16.7847 13.0508 16.4633 15.4493C16.4174 15.7917 16.2193 17.0211 15.5972 18.4654C15.39 18.9463 15.1516 19.4131 14.8886 19.8527C14.7936 20.0116 14.6253 20.0997 14.4527 20.0997ZM20.2816 13.2686C20.0506 13.2686 19.8417 13.1098 19.7877 12.875C19.1218 9.98219 16.9558 7.61306 14.135 6.69215C13.6438 6.53178 13.1342 6.41557 12.6206 6.34675C10.391 6.04802 8.17196 6.62969 6.3723 7.98443C5.82536 8.39615 5.32987 8.87088 4.89959 9.39539C4.72182 9.61203 4.40217 9.64355 4.18557 9.46587C3.96898 9.28818 3.93739 8.96851 4.11509 8.75193C4.59654 8.16506 5.15065 7.63413 5.76205 7.17388C7.77611 5.65771 10.2597 5.00689 12.7553 5.34116C13.33 5.41817 13.9002 5.5482 14.4499 5.72768C16.0279 6.24282 17.4486 7.16471 18.5586 8.39368C19.6462 9.59786 20.4131 11.0688 20.7765 12.6475C20.8393 12.9205 20.6689 13.1928 20.3959 13.2556C20.3576 13.2644 20.3193 13.2686 20.2816 13.2686Z"
        fill="url(#paint1_linear_2162_3779)"
      />
      <path
        d="M17.6409 18.5164C17.5878 18.5164 17.5338 18.508 17.4807 18.4903C17.2149 18.4019 17.071 18.1146 17.1595 17.8488C17.502 16.8192 17.6415 16.0069 17.6941 15.6143C18.0949 12.6228 16.3045 9.77199 13.4369 8.83577C13.0757 8.71784 12.7009 8.63234 12.323 8.58171C10.6847 8.36222 9.0538 8.78991 7.73074 9.78593C6.44049 10.7573 5.58444 12.1667 5.32031 13.7544C5.27432 14.0308 5.01292 14.2174 4.73662 14.1715C4.46024 14.1256 4.27349 13.8642 4.31945 13.5879C4.62653 11.7421 5.62131 10.104 7.12053 8.97534C8.65797 7.81793 10.5534 7.32102 12.4577 7.57608C12.8967 7.6349 13.3321 7.73419 13.7518 7.87126C17.0845 8.95934 19.1655 12.2725 18.6997 15.7491C18.6423 16.1771 18.491 17.0606 18.1222 18.1691C18.0514 18.3818 17.8534 18.5163 17.6409 18.5164Z"
        fill="url(#paint2_linear_2162_3779)"
      />
      <path
        d="M11.4878 20.3159C11.3632 20.3159 11.2385 20.2704 11.1406 20.1784C10.9363 19.9867 10.9263 19.6656 11.1181 19.4614C12.8252 17.6434 13.1845 15.279 13.2198 15.0153C13.3477 14.0603 12.6748 13.1792 11.7197 13.0513C10.7649 12.9232 9.88364 13.5962 9.75566 14.5513C9.75306 14.5708 9.42978 16.5755 8.05835 17.5086C7.82672 17.6663 7.51115 17.6062 7.35355 17.3746C7.19595 17.143 7.25595 16.8274 7.48758 16.6698C8.30115 16.1163 8.67438 14.8549 8.75118 14.408C8.95772 12.9029 10.3477 11.8438 11.8544 12.0457C13.364 12.2479 14.4276 13.6406 14.2254 15.1501C14.1983 15.3518 14.0837 16.0825 13.7433 17.0188C13.2987 18.2414 12.6644 19.2969 11.8576 20.1559C11.7578 20.2622 11.6229 20.3159 11.4878 20.3159Z"
        fill="url(#paint3_linear_2162_3779)"
      />
      <path
        d="M9.04296 19.4607C8.88069 19.4607 8.72123 19.3831 8.62308 19.2388C8.46548 19.0072 8.52548 18.6916 8.75711 18.534C10.6153 17.2697 10.9827 14.7404 10.9862 14.715C11.0243 14.4379 11.2794 14.2434 11.557 14.281C11.8343 14.3187 12.0288 14.5733 11.9917 14.8506C11.9754 14.9724 11.5666 17.8497 9.32789 19.3729C9.2405 19.4323 9.14124 19.4607 9.04296 19.4607Z"
        fill="url(#paint4_linear_2162_3779)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2162_3779"
          x1="12.6764"
          y1="8.01128"
          x2="16.904"
          y2="7.89867"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04B6BA" />
          <stop offset="1" stopColor="#92FCFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2162_3779"
          x1="12.4697"
          y1="18.778"
          x2="16.6981"
          y2="18.7362"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04B6BA" />
          <stop offset="1" stopColor="#92FCFF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2162_3779"
          x1="11.6031"
          y1="17.5364"
          x2="15.2427"
          y2="17.4947"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04B6BA" />
          <stop offset="1" stopColor="#92FCFF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2162_3779"
          x1="10.789"
          y1="19.5773"
          x2="12.5481"
          y2="19.5644"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04B6BA" />
          <stop offset="1" stopColor="#92FCFF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2162_3779"
          x1="10.2811"
          y1="18.9991"
          x2="11.1528"
          y2="18.9941"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04B6BA" />
          <stop offset="1" stopColor="#92FCFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
