import {
  Box,
  BoxProps,
  CardMedia,
  CardMediaProps,
  Grid,
  Grid2,
  LinearProgress,
  linearProgressClasses,
  LinearProgressProps,
  styled,
} from "@mui/material";
import { pageHeader } from "../../styles";
import { StylesScheme } from "../../types";
import { sizeDecreaseCalc } from "../../utils";

export interface IStyledCardMediaProps extends CardMediaProps {
  height?: number;
}

interface IStyledLinearProgressProps {
  content: string | number;
  backgroundColor: string;
  barColor: string;
  indexerColor: string;
  value: number;
}

export const StyledCardMedia = styled(CardMedia, {
  shouldForwardProp: prop => prop !== "height",
})<IStyledCardMediaProps>(({ theme, height = 150 }) => ({
  height,
  objectFit: "cover",
  borderRadius: theme.spacing(1.25),
}));

export const StyledListRoot = styled(Box)(({ theme }) => ({
  minHeight: "100vh",

  "& h2": {
    ...pageHeader(),
  },

  "& .MuiPaginationItem-root": {
    background: "rgba(0, 0, 0, 0.25)",
    color: "rgba(0, 0, 0, 0.87)",
  },

  "& form > .MuiGrid-container > .MuiGrid-item": {
    paddingTop: 0,

    "& .MuiPaper-elevation": {
      borderRadius: theme.spacing(1),
    },
  },

  "& .BoxesFilters-autocomplete": {
    "& .MuiOutlinedInput-root": {
      borderRadius: theme.spacing(1),
      backgroundColor: "#FFFFFF",
    },

    "& .MuiChip-root": {
      backgroundColor: "#1B5DA5",

      "& .MuiChip-label": {
        color: "#FFFFFF",
      },

      "& .MuiSvgIcon-root": {
        fill: "#FFFFFF",
      },
    },
  },
}));

interface IStyledCurrentRoot {
  isLoading?: boolean;
}

export const StyledCurrentRoot = styled(Box, { shouldForwardProp: prop => prop !== "isLoading" })<
  BoxProps & IStyledCurrentRoot
>(({ theme, isLoading }) => ({
  color: theme.palette.text.secondary,
  minHeight: isLoading ? "100vh" : undefined,

  "& h2": {
    ...pageHeader(),
    fontSize: theme.spacing(4.5),
  },

  "& .MuiBreadcrumbs-separator > svg > path": {
    fill: "#4BD4FF",
  },

  "& .MuiBreadcrumbs-ol > .MuiBreadcrumbs-li > p": {
    color: "#050505",
    fontSize: theme.spacing(1.5),
    fontWeight: 600,
    lineHeight: "140%",
    textDecoration: "none",
  },

  "& .MuiCardContent-root": {
    "& .MuiTypography-body1": {
      color: theme.palette.text.secondary,
      fontSize: theme.spacing(2),
      fontWeight: 600,
      lineHeight: "140%",
    },
    padding: 0,
    paddingLeft: theme.spacing(2),
  },

  "& .MuiCardActions-root": {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    paddingLeft: theme.spacing(2),
  },

  "& text": {
    fill: theme.palette.text.secondary,
  },

  "& #function-plot": {
    width: "100%",
  },

  "& .MuiButton-outlinedPrimary": {
    color: "rgb(25, 118, 210)",
    border: "1px solid rgb(25, 118, 210)",

    "&:hover": {
      textDecoration: "none",
      backgroundColor: "rgba(25, 118, 210, 0.04)",
    },
  },

  "& .DraftEditor-root": {
    marginTop: theme.spacing(3),
  },
}));

export const StyledSpinnerBox = styled(Grid)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
}));

export const StyledCurrentPageHeader = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: theme.spacing(2),
  margin: theme.spacing(2.5, 0, 2, 0),

  "& .MuiTypography-root": {
    fontSize: sizeDecreaseCalc(26, 16),
  },

  "& .MuiGrid-container": {
    margin: 0,
  },

  "& .MuiGrid-item": {
    display: "flex",
    justifyContent: "flex-end",
  },

  "& .MuiIconButton-root": {
    padding: 0,
    width: sizeDecreaseCalc(32, 20),
    height: sizeDecreaseCalc(32, 20),
  },

  "& h2": {
    fontSize: theme.spacing(3.25),
  },
}));

export const contentSideFlex: StylesScheme = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

export const StyledProgressBarBox: any = styled(Box)(({ theme }) => ({
  width: "70%",
  ...contentSideFlex,
  alignItems: "flex-stat",
  marginBottom: theme.spacing(1.5),

  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },

  "& .MuiTypography-body1": {
    color: "#000000",
    fontSize: theme.spacing(2),
    fontWeight: 500,
    lineHeight: "130%" /* 22.4px */,
  },

  "& .ProgressBar-tooltip_box": {
    ...contentSideFlex,
    flexDirection: "row",
    justifyContent: "flex-start",
    gap: theme.spacing(2),
  },
})) as typeof Box;

export const StyledLinearProgress = styled(LinearProgress, {
  shouldForwardProp: prop =>
    prop !== "content" && prop !== "backgroundColor" && prop !== "barColor" && prop !== "indexerColor",
})<LinearProgressProps & IStyledLinearProgressProps>(
  ({ theme, content, backgroundColor, barColor, indexerColor, value }) => ({
    width: "100%",
    height: 25,
    backgroundColor,
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: barColor,
      "&::before": {
        content: `"${content}"`,
        position: "absolute",
        right: value < 10 ? -10 : 0,
        top: 0,
        marginRight: value < 10 ? 0 : theme.spacing(1),
        transform: value < 10 ? "translateX(100%)" : "none",
        color: value < 10 ? "#000000" : "#FFFFFF",
        fontWeight: 700,
      },
      "&::after": {
        content: '""',
        position: "absolute",
        right: 0,
        top: 0,
        width: theme.spacing(0.5),
        height: "100%",
        backgroundColor: indexerColor,
      },
    },
  }),
);

export const StyledContentLeftSide = styled(Grid2)(({ theme }) => ({
  ...contentSideFlex,

  "& .MuiTypography-body1": {
    color: "#000000",
    fontSize: theme.spacing(4),
    fontWeight: 700,
    lineHeight: "130%" /* 62.4px */,
  },

  "& .MuiTypography-body2": {
    color: "#676767",
    fontSize: theme.spacing(2),
    fontWeight: 500,
    lineHeight: "130%" /* 62.4px */,
  },

  "& .LeftSide-InfoBox": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(2.5),
  },
}));

export const StyledContentRightSide = styled(Grid2)(({ theme }) => ({
  ...contentSideFlex,

  "& .CurrentToken-Buttons-box": {
    ...contentSideFlex,
    flexDirection: "row",
    gap: theme.spacing(2),
    paddingTop: theme.spacing(1.5),

    "& .MuiButtonBase-root": {
      padding: theme.spacing(1.5, 2.5),
      borderRadius: theme.spacing(3.75),
      color: "#FFFFFF !important",
      textAlign: "center",
      fontSize: theme.spacing(2),
      fontWeight: 500,
      lineHeight: "135%" /* 24.3px */,

      "&.CurrentToken-splitBtn": {
        background: "#285513",
        border: "1px solid #285513",
        "&:hover": {
          background: "#448327",
        },
      },

      "&.CurrentToken-releaseBtn": {
        background: "#1B5DA5",
        border: "1px solid #1B5DA5",
        "&:hover": {
          background: "#288efd",
        },
      },
    },
  },
}));
