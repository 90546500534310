import { FC } from "react";
import { FormattedMessage } from "react-intl";

import { Root } from "./styled";
import { VestingBoxBadge } from "../../../shared";

interface IRarityBadgeProps {
  isItem?: boolean;
  category: VestingBoxBadge;
}

export const VestingBoxCategoryBadge: FC<IRarityBadgeProps> = props => {
  const { isItem, category } = props;

  if (!category) {
    return null;
  }

  return (
    <Root category={category} isItem={isItem} data-testid="RarityBadge">
      <FormattedMessage id={`enums.vestingBoxBadge.${category}`} />
    </Root>
  );
};
