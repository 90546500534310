import { ElementType } from "react";

const descriptionRulesWithTag = (tagName: ElementType) => ({
  tagName,
});

export const splitRulesDescription = {
  splitRulesDescription_1: descriptionRulesWithTag("p"),
  splitRulesDescription_2: descriptionRulesWithTag("p"),
  splitRulesDescription_3: descriptionRulesWithTag("p"),
  splitRulesDescription_4: descriptionRulesWithTag("p"),
  splitRulesDescription_5: descriptionRulesWithTag("p"),
  splitRulesDescription_6: descriptionRulesWithTag("span"),
  splitRulesDescription_7: descriptionRulesWithTag("span"),
  splitRulesDescription_8: descriptionRulesWithTag("p"),
  splitRulesDescription_9: descriptionRulesWithTag("p"),
  splitRulesDescription_10: descriptionRulesWithTag("p"),
};

export const createOfferDescription = {
  offerRulesDescription_1: descriptionRulesWithTag("p"),
  offerRulesDescription_2: descriptionRulesWithTag("p"),
};
