import { Box, styled } from "@mui/material";

import { contentSideFlex } from "../../../../../shared";

export const StyledSummaryItem = styled(Box)(({ theme }) => ({
  width: "100%",
  ...contentSideFlex,
  flexDirection: "row",
  justifyContent: "space-between",

  "& .SummaryItem-iconBox": {
    ...contentSideFlex,
    flexDirection: "row",
    gap: theme.spacing(1),
  },
}));
