import React, { FC } from "react";
import { BigNumber } from "ethers";
import { FormattedMessage, IntlFormatters, useIntl } from "react-intl";
import { Box, Grid2, Typography } from "@mui/material";

import { IAssetInfo } from "@framework/types";
import { formatUnitsR } from "@framework/exchange";

import { AMOUNT_FIX_VALUE, formatDateFromSpecialValue } from "../../../../../shared";

interface ILockedAssetsProps {
  assetsInfo: Array<IAssetInfo>;
}

interface IUnlockedAssetProps {
  asset: IAssetInfo;
  formatMessage: IntlFormatters["formatMessage"];
}

export const UnlockedAssets: FC<ILockedAssetsProps> = props => {
  const { assetsInfo } = props;
  const { formatMessage } = useIntl();

  return (
    <React.Fragment>
      <Box>
        <FormattedMessage id="pages.tokens.assets.unlocked.title" tagName="h2" />
        <FormattedMessage id="pages.tokens.assets.unlocked.description" tagName="span" />
      </Box>

      <Grid2 container size={12} spacing={1}>
        {assetsInfo.map(asset => (
          <UnlockedAsset key={asset.symbol} asset={asset} formatMessage={formatMessage} />
        ))}
      </Grid2>
    </React.Fragment>
  );
};

const UnlockedAsset: FC<IUnlockedAssetProps> = props => {
  const { asset, formatMessage } = props;

  const totalTokensValue = Number(formatUnitsR(BigNumber.from(String(asset.releasableAmount)), 18)).toFixed(
    AMOUNT_FIX_VALUE,
  );

  const formatterReleasedDate = asset.releasedLastDate
    ? formatDateFromSpecialValue(asset.releasedLastDate, true)
    : formatMessage({ id: "pages.tokens.assets.unlocked.notReleased" });

  return (
    <Grid2 className="UnlockedAsset-Container" size={{ xl: 6, lg: 6, md: 6, sm: 12, xs: 12 }}>
      <Box className="UnlockedAsset-ContainerHeader">
        <Typography variant="body1" component="h2">
          {asset.title}
        </Typography>
        <Box className="UnlockedAsset-boxTicker">{asset.symbol}</Box>
      </Box>
      <FormattedMessage
        id="pages.tokens.assets.unlocked.tokensValueDescription"
        tagName="span"
        values={{ strong: chunks => <strong>{chunks}</strong>, value: totalTokensValue }}
      />
      <FormattedMessage
        id="pages.tokens.assets.unlocked.lastReleaseDate"
        tagName="span"
        values={{
          strong: chunks => <strong>{chunks}</strong>,
          value: formatterReleasedDate,
        }}
      />
    </Grid2>
  );
};
