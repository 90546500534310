import React from "react";
import { FormattedMessage } from "react-intl";

import { PageHeader } from "@dzambalaorg/mui-page-layout";

import { StepperActiveSteps, useStepperContext } from "./context";
import { Root } from "./styled";
import { FormStepper } from "./stepper";
import { FirstStepForm } from "./first-step-form";
import { SecondStepForm } from "./second-step-form";
import { ThirdStep } from "./third-step";

export { StepperProvider } from "./context";

export const VestingApplication = () => {
  const { activeStep } = useStepperContext();

  return (
    <Root>
      <PageHeader sx={{ justifyContent: "center" }} message="pages.vesting.application.title" />
      <FormattedMessage id="pages.vesting.application.subtitle" tagName="h4" />

      <FormStepper />

      {activeStep === StepperActiveSteps.FIRST && <FirstStepForm />}
      {activeStep === StepperActiveSteps.SECOND && <SecondStepForm />}
      {activeStep === StepperActiveSteps.THIRD && <ThirdStep />}
    </Root>
  );
};
