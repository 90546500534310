import { FC } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { Box, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";

import { PageHeader } from "@dzambalaorg/mui-page-layout";
import { ListActionVariant } from "@framework/styled";

import {
  BoxContentLeftSide,
  CustomTooltip,
  generateBoxContent,
  InfoSvg,
  Plot,
  progressBarColors,
  StyledContentRightSide,
  StyledCurrentPageHeader,
  StyledCurrentRoot,
  StyledLinearProgress,
  StyledProgressBarBox,
  TOKEN_DISPLAYING_FIX_VALUE,
  TOKEN_PERCENTAGE_FIX_VALUE,
  useVestingContractInfo,
} from "../../../../../shared";
import { Breadcrumbs } from "../../../../../components/breadcrumbs";
import { ButtonBack } from "../../../../../shared/assets/svg/button-back";
import { VestingBoxReleaseButton } from "../../../../../components/buttons/release";
import { SplitTokenButton } from "../../../../../components/buttons/split";
import { StyledContent } from "./styled";
import { ISelected } from "./useFetchToken";

interface ICurrentBoxProps {
  selected: ISelected;
}

export const CurrentBox: FC<ICurrentBoxProps> = props => {
  const { selected } = props;
  const { template, tokenId } = selected;
  const { box } = template;

  const { progressOrange, progressBlue, progressGreen } = progressBarColors;

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const goBackHandler = () => navigate(-1);

  const { tokensCount } = generateBoxContent({
    price: template.price,
    content: template.box.content,
    duration: box.duration,
    cliff: box.cliff,
    afterCliffBasisPoints: box.afterCliffBasisPoints,
  });

  const tokenValue = tokensCount.split(" ")[0];
  const tokenCount = Number(tokensCount.split(" ")[1]);

  const { releasablePercentage, releasablePercentageForDisplaying, releasable, withDrawn } = useVestingContractInfo(
    tokenId,
    template!.contract!.address,
    tokenCount,
  );

  const remainingTokensProgress = ((tokenCount - Number(withDrawn)) * 100) / tokenCount;
  const remainingTokens = (tokenCount - Number(withDrawn)).toFixed(TOKEN_DISPLAYING_FIX_VALUE);
  const withdrawnTokensProgress = (Number(withDrawn) * 100) / tokenCount;

  return (
    <StyledCurrentRoot>
      <Breadcrumbs paths={["tokens", "tokens.current"]} data={[{}, { title: box.title }]} />

      <StyledCurrentPageHeader>
        <IconButton onClick={goBackHandler}>
          <ButtonBack />
        </IconButton>
        <PageHeader message="pages.tokens.current.title" data={{ title: box.title }} />
      </StyledCurrentPageHeader>

      <StyledContent container spacing={2} direction={!isMd ? "row" : "column"} alignItems={"center"}>
        <BoxContentLeftSide
          tokenImageUrl={template.box.content!.components[0].template!.imageUrl || ""}
          tokensCount={withDrawn}
          subtitle={formatMessage(
            { id: "pages.tokens.current.contentSubtitle" },
            { token: tokenValue, value: tokenCount },
          )}
          tooltipTextId="pages.tokens.current.balanceTooltip"
        />

        <StyledContentRightSide size={{ xs: 12, sm: 8, md: 8, lg: 8 }}>
          <StyledProgressBarBox>
            <Typography variant="body1" component="p">
              {`${formatMessage(
                { id: "pages.tokens.current.tokensInBox" },
                {
                  token: tokenValue,
                  value: remainingTokens,
                },
              )}`}
            </Typography>
            <StyledLinearProgress
              {...progressOrange}
              content={`${remainingTokensProgress.toFixed(TOKEN_PERCENTAGE_FIX_VALUE)}%` as never}
              variant="determinate"
              value={remainingTokensProgress}
            />
          </StyledProgressBarBox>

          <StyledProgressBarBox>
            <Typography variant="body1" component="p">
              {`${formatMessage(
                { id: "pages.tokens.current.withdrawn" },
                {
                  token: tokenValue,
                  value: withDrawn,
                },
              )}`}
            </Typography>

            <StyledLinearProgress
              {...progressBlue}
              content={`${withdrawnTokensProgress}%` as never}
              variant="determinate"
              value={withdrawnTokensProgress}
            />
          </StyledProgressBarBox>

          <StyledProgressBarBox>
            <Box className="ProgressBar-tooltip_box">
              <Typography variant="body1" component="p">
                {`${formatMessage(
                  { id: "pages.tokens.current.releasable" },
                  {
                    token: tokenValue,
                    value: releasable,
                  },
                )}`}
              </Typography>
              <CustomTooltip title={formatMessage({ id: "pages.tokens.current.releasableProgressTooltip" })}>
                <InfoSvg />
              </CustomTooltip>
            </Box>
            <StyledLinearProgress
              {...progressGreen}
              content={`${releasablePercentageForDisplaying}%` as never}
              variant="determinate"
              value={Number(releasablePercentage)}
            />
          </StyledProgressBarBox>

          <Box className="CurrentToken-Buttons-box">
            <VestingBoxReleaseButton
              className="CurrentToken-releaseBtn"
              token={selected}
              variant={ListActionVariant.button}
            />
            {/*<SplitTokenButton*/}
            {/*  // disabled={true}*/}
            {/*  className="CurrentToken-splitBtn"*/}
            {/*  token={selected}*/}
            {/*  variant={ListActionVariant.button}*/}
            {/*/>*/}
          </Box>
        </StyledContentRightSide>
      </StyledContent>

      <Plot
        vestingBox={template.box}
        startTimestamp={new Date(template.createdAt).toDateString()}
        height={500}
        startDirection="column"
      />
    </StyledCurrentRoot>
  );
};
