import { FC } from "react";
import { Link as RouterLink } from "react-router-dom";

import { useAppDispatch } from "@dzambalaorg/redux";
import { collectionActions } from "@dzambalaorg/provider-collection";

import { MenuVariant } from "../../../../shared";
import { IMenuVariant } from "../types";
import type { IMenuItem } from "../use-menu-items";
import { DropdownMenuItem } from "../dropdown";
import { StyledLink } from "./styled";

export interface IMenuItemProps extends IMenuVariant {
  menuItem: IMenuItem;
  withBackground?: boolean;
  onCloseMenu?: () => void;
}

export const MenuItem: FC<IMenuItemProps> = props => {
  const { menuItem, withBackground, variant } = props;
  const dispatch = useAppDispatch();
  const { resetState } = collectionActions;

  const Svg = menuItem.Svg;

  const isActive =
    variant === MenuVariant.LANDING
      ? location.hash === menuItem.link
      : menuItem.paths
        ? menuItem.paths.includes(location.pathname)
        : location.pathname + location.hash === menuItem.link;

  const onClickHandler = () => {
    if (props.onCloseMenu) {
      props.onCloseMenu();
    }
    dispatch(resetState(void 0));
  };

  if (!menuItem.link || menuItem.children?.length) {
    return <DropdownMenuItem menuItem={menuItem} withBackground />;
  }

  if (menuItem.external) {
    return (
      <StyledLink
        href={menuItem.link}
        target="_blank"
        rel="noreferrer"
        variant={variant}
        withBackground={withBackground}
      >
        {menuItem.title}
      </StyledLink>
    );
  }

  return (
    <StyledLink
      component={RouterLink}
      to={menuItem.link}
      variant={variant}
      isActive={isActive}
      withBackground={withBackground}
      onClick={onClickHandler}
    >
      {Svg && <Svg />}
      {menuItem.title}
    </StyledLink>
  );
};
