import { useState } from "react";
import { useParams } from "react-router";

import { useApiCall } from "@dzambalaorg/react-hooks";
import { IVestingBox } from "@framework/types";

import { PATHS, useReFetch, IVestingOfferResponse, VestingBoxesTabs } from "../../../../../shared";

export const useFetchBox = () => {
  const { boxId, tab } = useParams<{ boxId: string; tab: VestingBoxesTabs }>();
  const [selected, setSelected] = useState<IVestingBox | IVestingOfferResponse | null>(null);

  const { fn: getResponseFn, isLoading } = useApiCall(
    api =>
      api.fetchJson({
        url: `${PATHS.VestingBoxesBase}/${tab}/${boxId}`,
      }),
    {
      success: false,
      error: false,
    },
  );

  const getBox = async () => {
    const response = await getResponseFn();
    if (response) {
      setSelected(response);
    }
  };

  useReFetch(getBox);

  return {
    selected,
    isLoading,
  };
};
