import React, { SVGProps } from "react";

export const MainLogo = ({ width = 290, height = 208 }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={width} height={height} viewBox="0 0 290 208" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2162_4059)">
        <path
          d="M0.146179 207.726C0.232552 207.552 12.6271 190.586 27.6848 169.966L55.0507 132.48H69.5614C81.1209 132.48 84.0001 132.537 83.8273 132.754C83.7265 132.87 75.4923 144.147 65.5594 157.749L47.4786 182.499L68.784 182.572C88.8657 182.6 90.1181 182.6 90.4349 182.254C90.622 182.037 96.0779 174.297 102.556 165.027C109.048 155.771 114.936 147.396 115.656 146.472C128.137 130.299 151.745 127.368 167.883 140.061C170.244 141.923 173.411 145.288 175.34 147.988C176.261 149.259 182.091 157.562 188.31 166.442L199.61 182.6H242.48L241.775 181.633C241.386 181.07 233.454 170.24 224.198 157.547C214.927 144.855 206.995 134.025 206.607 133.476L205.901 132.465L220.412 132.494H234.879L262.044 169.735C277.001 190.196 289.381 207.206 289.611 207.48L290 208L247.735 207.928C218.065 207.856 205.009 207.755 204.001 207.61C194.385 206.065 186.798 202.845 180.09 197.43C177.744 195.567 174.807 192.622 173.094 190.499C172.417 189.647 166.774 181.691 160.57 172.825C154.365 163.959 148.996 156.392 148.65 155.988C146.793 153.995 143.367 153.908 141.467 155.814C141.179 156.103 136.025 163.395 129.965 172.002C121.385 184.275 116.203 191.332 114.418 193.17C106.975 200.925 97.9349 205.632 87.1671 207.379L84.3456 207.841L42.1811 207.914C6.4946 207.971 0.0454106 207.957 0.189366 207.697"
          fill="url(#paint0_linear_2162_4059)"
        />
        <path
          d="M60.6191 117.043C61.2093 115.743 64.4195 108.87 67.7305 101.722L73.719 88.7838L77.1163 88.8127L80.5425 88.8704L86.3007 101.245C89.4821 108.047 92.6779 114.92 93.3977 116.494L94.7508 119.339H84.8611L81.0607 110.92C78.9734 106.27 77.2027 102.487 77.1451 102.487C77.0732 102.487 75.3313 106.256 73.2583 110.877L69.4867 119.237L64.5059 119.295L59.525 119.339L60.6047 117.014L60.6191 117.043Z"
          fill="white"
        />
        <path
          d="M99.3464 104.091V88.7995H103.996H108.675V100.135V111.47H115.297H121.933V115.427V119.369H110.647H99.3464V104.091Z"
          fill="white"
        />
        <path
          d="M142.934 102.59C144.172 102.041 144.748 101.117 144.748 99.7593C144.748 99.225 144.604 98.4886 144.432 98.1709C143.798 96.9147 141.581 96.3082 137.752 96.2938H135.823V103.196L138.889 103.109C141.408 103.023 142.099 102.936 142.906 102.59M126.48 104.091V88.7995H135.348C145.051 88.7995 145.684 88.8572 148.146 90.0413C150.636 91.2254 152.608 93.5357 153.458 96.2504C153.976 97.8966 154.048 100.813 153.659 102.517C152.954 105.42 150.766 108.207 148.203 109.391C145.857 110.488 144.763 110.69 140.127 110.777L135.823 110.878V119.354H126.495V104.077L126.48 104.091Z"
          fill="white"
        />
        <path
          d="M159.476 104.091V88.7995H164.039H168.602V94.5177V100.236H174.101H179.601V94.5177V88.7995H184.164H188.727V104.091V119.369H184.164H179.601V113.347V107.311H174.101H168.602V113.347V119.369H164.039H159.476V104.091Z"
          fill="white"
        />
        <path
          d="M193.494 119.238C193.494 119.137 196.647 112.264 200.519 103.99L207.501 88.9002L210.927 88.8425L214.339 88.8136L221.09 103.297C224.776 111.282 227.971 118.141 228.187 118.617L228.547 119.397H218.686L214.886 110.979C212.784 106.329 211.028 102.546 210.956 102.546C210.898 102.546 209.156 106.315 207.084 110.935L203.326 119.296L198.432 119.354C195.711 119.383 193.509 119.325 193.509 119.253"
          fill="white"
        />
        <path
          d="M141.565 75.6583C131.273 74.6909 121.887 69.5359 115.697 61.5217C114.963 60.5687 109.046 52.208 102.554 42.9232C96.09 33.6672 90.6342 25.913 90.4326 25.6964C90.1159 25.3643 88.8635 25.3499 68.7674 25.3787L47.4764 25.4365L65.5428 50.2009C75.5045 63.8321 83.7243 75.0807 83.8251 75.2252C83.9978 75.4129 81.1187 75.4706 69.5591 75.4706H55.0484L27.5386 37.7537L0 0.0801067L39.6596 0.0223472C79.7655 -0.0498521 84.0266 0.0223472 88.0286 0.715461C95.6294 2.01505 102.568 5.01854 108.672 9.61042C111.22 11.5309 114.919 15.1409 116.949 17.639C117.827 18.7364 123.557 26.8083 129.704 35.5877C135.865 44.3816 141.134 51.8181 141.422 52.1069C143.279 53.9552 146.417 53.9986 148.303 52.208C148.734 51.8037 154.133 44.2516 160.308 35.4145C166.455 26.6061 172.084 18.6498 172.804 17.7545C179.67 8.97506 190.381 2.70816 201.883 0.701021C205.885 0.0223472 210.16 -0.0498521 250.237 0.0223472L289.897 0.0801067L262.517 37.5227C247.488 58.1139 235.093 75.0952 235.021 75.2396C234.892 75.4417 231.825 75.4851 220.395 75.4417L205.942 75.384L224.081 50.5474C234.057 36.8729 242.219 25.6242 242.219 25.5231C242.219 25.4365 232.632 25.3499 220.928 25.3499H199.608L188.308 41.5081C173.581 62.5325 174.056 61.9116 171.508 64.5397C166.945 69.2615 161.532 72.5249 155.227 74.3588C151.21 75.514 145.639 76.0627 141.565 75.6583Z"
          fill="url(#paint1_linear_2162_4059)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2162_4059"
          x1="146.367"
          y1="201.247"
          x2="219.291"
          y2="198.816"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04B6BA" />
          <stop offset="1" stopColor="#92FCFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2162_4059"
          x1="146.243"
          y1="69.042"
          x2="219.177"
          y2="66.6084"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04B6BA" />
          <stop offset="1" stopColor="#92FCFF" />
        </linearGradient>
        <clipPath id="clip0_2162_4059">
          <rect width="290" height="208" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
