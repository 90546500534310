import { useParams } from "react-router";
import React from "react";

import { PageHeader } from "@dzambalaorg/mui-page-layout";

import { PATHS, StyledListRoot, TabMenu, TabMode, AssetsDashboardTabs } from "../../../shared";
import { BoxesAssets } from "./boxes-assets";
import { OffersAssets } from "./offers-assets";

export const AssetsDashboard = () => {
  const { tab } = useParams<{ tab: AssetsDashboardTabs }>();

  const renderContent: Record<AssetsDashboardTabs, React.JSX.Element> = {
    [AssetsDashboardTabs.boxes]: <BoxesAssets variant={AssetsDashboardTabs.boxes} />,
    [AssetsDashboardTabs.offers]: <OffersAssets variant={AssetsDashboardTabs.offers} />,
    // [AssetsDashboardTabs.transactionHistory]: <div>{AssetsDashboardTabs.transactionHistory}</div>,
  };

  return (
    <StyledListRoot>
      <PageHeader message="pages.tokens.title" />

      <TabMenu<Record<string, string>, TabMode.NAVIGATE>
        tabs={AssetsDashboardTabs}
        initialTab={AssetsDashboardTabs.boxes}
        basicLocale="pages.tokens.tabs"
        basicPath={PATHS.DashboardBase}
        mode={TabMode.NAVIGATE}
      />
      <React.Fragment key={tab}>{tab && renderContent[tab]}</React.Fragment>
    </StyledListRoot>
  );
};
