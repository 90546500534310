import { Grid2, styled } from "@mui/material";

export const StyledRoot = styled(Grid2)(({ theme }) => ({
  gap: theme.spacing(1),
  padding: theme.spacing(1, 1.5),
  borderRadius: theme.spacing(1),
  border: "1px solid #84CCFF",
  background: "#FFF",

  color: "#000",
  fontSize: theme.spacing(2),
  fontWeight: 400,
  lineHeight: "130%" /* 20.8px */,
}));
