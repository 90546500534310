import { CardMedia, CardMediaProps, styled } from "@mui/material";

export interface IStyledCardMediaProps extends CardMediaProps {
  height?: number;
}

export const StyledCardMedia = styled(CardMedia, {
  shouldForwardProp: prop => prop !== "height",
})<IStyledCardMediaProps>(({ theme, height = 150 }) => ({
  height,
  objectFit: "cover",
  borderRadius: theme.spacing(1.25),
}));
