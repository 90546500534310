import React, { SVGProps } from "react";

export const CalendarRemove = ({ width = 40, height = 40 }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="calendar-remove">
        <path
          id="union-1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.0002 2.1665C13.9206 2.1665 14.6668 2.9127 14.6668 3.83317V5.49984H26.3335V3.83317C26.3335 2.9127 27.0797 2.1665 28.0002 2.1665C28.9206 2.1665 29.6668 2.9127 29.6668 3.83317V5.54098C33.8779 5.95909 37.1668 9.51205 37.1668 13.8332V28.8332C37.1668 33.4355 33.4359 37.1665 28.8335 37.1665H12.1668C7.56446 37.1665 3.8335 33.4355 3.8335 28.8332V13.8332C3.8335 9.51205 7.12239 5.95909 11.3335 5.54098V3.83317C11.3335 2.9127 12.0797 2.1665 13.0002 2.1665ZM7.45134 12.1665H33.549C32.8626 10.2245 31.0105 8.83317 28.8335 8.83317H12.1668C9.9898 8.83317 8.13773 10.2245 7.45134 12.1665ZM33.8335 15.4998H7.16683V28.8332C7.16683 31.5946 9.40541 33.8332 12.1668 33.8332H28.8335C31.5949 33.8332 33.8335 31.5946 33.8335 28.8332V15.4998ZM13.8335 24.6665C13.8335 23.746 14.5797 22.9998 15.5002 22.9998H25.5002C26.4206 22.9998 27.1668 23.746 27.1668 24.6665C27.1668 25.587 26.4206 26.3332 25.5002 26.3332H15.5002C14.5797 26.3332 13.8335 25.587 13.8335 24.6665Z"
          fill="#4BD4FF"
        />
      </g>
    </svg>
  );
};
