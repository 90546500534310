import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { format } from "date-fns";
import { Button, Grid2 } from "@mui/material";
import { FilterList } from "@mui/icons-material";

import { CommonSearchForm } from "@dzambalaorg/mui-form-search";
import { AutocompleteInput } from "@dzambalaorg/mui-inputs-core";
import { ITokenSearchDto, TgeEvents, VestingBoxVariant } from "@framework/types";

import { AssetsDashboardTabs } from "../../../../shared";
import { TgeRange } from "./TgeRange";

interface IAssetsFiltersProps {
  isFiltersOpen: boolean;
  search: ITokenSearchDto;
  handleSearch: (values: ITokenSearchDto) => Promise<void>;
  handleToggleFilters: () => void;
  tab?: AssetsDashboardTabs;
}

export const AssetsFilters: FC<IAssetsFiltersProps> = props => {
  const { isFiltersOpen, search, tab, handleSearch, handleToggleFilters } = props;

  const { formatMessage } = useIntl();

  const onHandleSearch = async ({
    vestingBoxVariant,
    tgeEvent,
    tgeRangeStart,
    tgeRangeEnd,
    ...restValues
  }: ITokenSearchDto) => {
    await handleSearch({
      ...restValues,
      vestingBoxVariant: vestingBoxVariant || undefined,
      tgeEvent: tgeEvent || undefined,
      tgeRangeStart: tgeRangeStart && tgeEvent !== TgeEvents.TODAY ? format(tgeRangeStart, "yyyy-MM-dd HH:mm:ss") : "",
      tgeRangeEnd: tgeRangeEnd && tgeEvent !== TgeEvents.TODAY ? format(tgeRangeEnd, "yyyy-MM-dd HH:mm:ss") : "",
    });
  };

  return (
    <React.Fragment>
      <Grid2 container size={12} direction="row" justifyContent="flex-end">
        <Button
          disableRipple
          sx={{
            textTransform: "none",
            boxSizing: "border-box",
            "&:hover": {
              background: "transparent",
            },
          }}
          startIcon={<FilterList />}
          onClick={handleToggleFilters}
          data-testid="ToggleFilterButton"
        >
          <FormattedMessage id={`form.buttons.${isFiltersOpen ? "hideFilters" : "showFilters"}`} />
        </Button>
      </Grid2>
      <CommonSearchForm
        hasSearchField={false}
        key={tab}
        onSubmit={onHandleSearch}
        initialValues={search}
        open={isFiltersOpen}
        testId="AssetsSearchForm"
      >
        <Grid2 container spacing={1} alignItems="flex-end" sx={{ marginTop: 1 }}>
          <Grid2 size={{ xs: 12, md: 4, lg: 4 }} className="BoxesFilters-grid">
            <AutocompleteInput
              key={tab}
              variant="outlined"
              name="vestingBoxVariant"
              options={Object.entries(VestingBoxVariant)
                .filter(([_, value]) => value !== VestingBoxVariant.OFFERED)
                .map(([key, value]) => ({
                  key,
                  value: formatMessage({ id: `enums.vestingBoxBadge.${value}` }),
                }))}
            />
          </Grid2>

          <Grid2 size={{ xs: 12, md: 4, lg: 4 }} className="BoxesFilters-grid">
            <AutocompleteInput
              key={tab}
              variant="outlined"
              name="tgeEvent"
              options={Object.entries(TgeEvents).map(([key, value]) => ({
                key,
                value: formatMessage({ id: `enums.vestingBoxBadge.${value}` }),
              }))}
            />
          </Grid2>

          <TgeRange />
        </Grid2>
      </CommonSearchForm>
    </React.Fragment>
  );
};
