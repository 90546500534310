import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { isNavigateMode, isSearchMode, TabMenuProps, TabMode } from "./types";
import { useAppDispatch } from "@dzambalaorg/redux";
import { collectionActions } from "@dzambalaorg/provider-collection";

export const useTabMenu = <T extends Record<string, string>, M extends TabMode>(props: TabMenuProps<T, M>) => {
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const params = useParams<{ tab: T[keyof T] }>();
  const dispatch = useAppDispatch();
  const { setRows } = collectionActions;

  const searchValues = search.values();

  const setInitialTab = (initialTab: T[keyof T]) => {
    if (isSearchMode(props)) {
      return search.get(props.searchKey) || initialTab;
    } else if (isNavigateMode(props)) {
      return params.tab || initialTab;
    } else {
      return initialTab;
    }
  };

  const handleChangeTab = (newValue: T[keyof T]): void => {
    if (isSearchMode(props)) {
      void props.handleSearch({ [props.searchKey]: newValue });
    }

    if (isNavigateMode(props)) {
      dispatch(setRows([]));
      navigate(`${props.basicPath}/${newValue}`);
    }
  };
  return {
    searchValues,
    pathParams: params,
    handleChangeTab,
    setInitialTab,
  };
};
