import { ComponentType, FC, Fragment, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { useUser } from "@dzambalaorg/provider-user";
import { AgreementDialog } from "../dialogs/agreement";

function withCustomProps<P extends Record<string, any>>(WrappedComponent: ComponentType<P>) {
  const HOC: FC<P> = props => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const user = useUser<any>();
    const { formatMessage } = useIntl();

    const isAuthenticated = user.isAuthenticated();

    useEffect(() => {
      if (isAuthenticated && !user.profile?.isConsent) {
        setIsOpen(true);
      }
    }, [isAuthenticated, user.profile]);

    const handleCloseDialog = useCallback(() => {
      const shouldClose = window.confirm(formatMessage({ id: "form.hints.disconnect" }));
      if (shouldClose) {
        user.logOut().then(() => {
          setIsOpen(false);
        });
      }
    }, [isOpen]);

    const handleChangeAgreement = async (isConsent: boolean) => {
      if (isConsent) {
        user.setProfile({ isConsent }).then(() => {
          setIsOpen(false);
        });
      } else {
        const shouldClose = window.confirm(formatMessage({ id: "form.hints.disconnect" }));
        if (shouldClose) {
          user.logOut().then(() => {
            setIsOpen(false);
          });
        }
      }
    };

    return (
      <Fragment>
        <WrappedComponent {...props} />
        <AgreementDialog open={isOpen} onClose={handleCloseDialog} handleAgreement={handleChangeAgreement} />
      </Fragment>
    );
  };

  HOC.displayName = `WithCustomProps(${WrappedComponent.displayName || WrappedComponent.name || "Component"})`;

  return HOC;
}

export default withCustomProps;
