import { ShapeType } from "@framework/types";

import { Time, TPlotConfig } from "../types";
import {
  formatDateForVestingPlot,
  generateExponentialSteppedData,
  generateHyperbolicSteppedData,
  generateLinearSteppedData,
} from "../utils";

export const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const timeValues: {
  [Key in Time]: number;
} = {
  [Time.ONE_DAY]: 86400,
  [Time.TWO_DAYS]: 172800,
  [Time.SEVEN_DAYS]: 604800,
  [Time.MONTH]: 2592000,
  [Time.THREE_MONTH]: 7776000,
  [Time.SIX_MONTH]: 15552000,
  [Time.YEAR]: 31104000,
  [Time.TWO_YEARS]: 62208000,
};

export const plotDataByShape: {
  [Key in ShapeType]: (config: TPlotConfig) => Array<any>;
} = {
  [ShapeType.LINEAR]: ({ duration, startTimestamp }) => {
    let prevDate = startTimestamp;
    const data = [];

    for (let x = 0; x <= duration; x += 1) {
      const y = (x / duration) * 100;
      const formattedDate = formatDateForVestingPlot(prevDate, x > 0 ? 1 : x);
      prevDate = formattedDate;

      data.push({ x: formattedDate, y });
    }

    return data;
  },
  [ShapeType.LINEAR_CLIFF]: ({ duration, cliff, startTimestamp }) => {
    let prevDate = startTimestamp;
    const data = [];

    for (let x = 0; x <= cliff; x++) {
      const formattedDate = formatDateForVestingPlot(prevDate, x > 0 ? 1 : x);
      prevDate = formattedDate;
      data.push({ x: formattedDate, y: 0 });
    }

    for (let x = cliff; x <= duration; x++) {
      const y = ((x - cliff) / (duration - cliff)) * 100;
      const formattedDate = formatDateForVestingPlot(prevDate, x > 0 ? 1 : x);
      prevDate = formattedDate;
      data.push({ x: formattedDate, y });
    }

    return data;
  },
  [ShapeType.LINEAR_IMMEDIATE]: ({
    duration,
    immediateUnlockPercentage,
    immediateUnlockPercentageRestPercent,
    startTimestamp,
  }) => {
    let prevDate = startTimestamp;
    const data = [];

    for (let x = 0; x <= duration; x++) {
      const y = immediateUnlockPercentage + (x / duration) * immediateUnlockPercentageRestPercent;
      const formattedDate = formatDateForVestingPlot(prevDate, x > 0 ? 1 : x);
      prevDate = formattedDate;
      data.push({ x: formattedDate, y });
    }

    return data;
  },
  [ShapeType.LINEAR_CLIFF_IMMEDIATE]: ({
    duration,
    cliff,
    immediateUnlockPercentage,
    immediateUnlockPercentageRestPercent,
    startTimestamp,
  }) => {
    let prevDate = startTimestamp;
    const data = [];

    for (let x = 0; x <= cliff; x++) {
      const formattedDate = formatDateForVestingPlot(prevDate, x > 0 ? 1 : x);
      prevDate = formattedDate;
      data.push({ x: formattedDate, y: 0 });
    }

    for (let x = cliff; x <= duration; x++) {
      const y = ((x - cliff) / (duration - cliff)) * immediateUnlockPercentageRestPercent + immediateUnlockPercentage;
      const formattedDate = formatDateForVestingPlot(prevDate, x > 0 ? 1 : x);
      prevDate = formattedDate;
      data.push({ x: formattedDate, y });
    }

    return data;
  },
  [ShapeType.EXPONENTIAL]: ({ duration, growthRate, startTimestamp }) => {
    let prevDate = startTimestamp;
    const data = [];

    for (let x = 0; x <= duration; x++) {
      const y = Math.pow(x / duration, growthRate) * 100;
      const formattedDate = formatDateForVestingPlot(prevDate, x > 0 ? 1 : x);
      prevDate = formattedDate;
      data.push({ x: formattedDate, y });
    }

    return data;
  },
  [ShapeType.EXPONENTIAL_CLIFF]: ({ duration, cliff, growthRate, startTimestamp }) => {
    let prevDate = startTimestamp;
    const data = [];

    for (let x = 0; x <= cliff; x++) {
      const formattedDate = formatDateForVestingPlot(prevDate, x > 0 ? 1 : x);
      prevDate = formattedDate;
      data.push({ x: formattedDate, y: 0 });
    }

    for (let x = cliff; x <= duration; x++) {
      const y = Math.pow((x - cliff) / (duration - cliff), growthRate) * 100;
      const formattedDate = formatDateForVestingPlot(prevDate, x > 0 ? 1 : x);
      prevDate = formattedDate;
      data.push({ x: formattedDate, y });
    }

    return data;
  },
  [ShapeType.EXPONENTIAL_IMMEDIATE]: ({
    duration,
    immediateUnlockPercentage,
    immediateUnlockPercentageRestPercent,
    growthRate,
    startTimestamp,
  }) => {
    let prevDate = startTimestamp;
    const data = [];

    for (let x = 0; x <= duration; x++) {
      const y = immediateUnlockPercentage + Math.pow(x / duration, growthRate) * immediateUnlockPercentageRestPercent;
      const formattedDate = formatDateForVestingPlot(prevDate, x > 0 ? 1 : x);
      prevDate = formattedDate;
      data.push({ x: formattedDate, y });
    }

    return data;
  },
  [ShapeType.EXPONENTIAL_CLIFF_IMMEDIATE]: ({
    duration,
    cliff,
    growthRate,
    immediateUnlockPercentage,
    immediateUnlockPercentageRestPercent,
    startTimestamp,
  }) => {
    let prevDate = startTimestamp;
    const data = [];

    for (let x = 0; x <= duration; x++) {
      let y = 0;

      if (x < cliff) {
        y = 0;
      } else {
        const exponentialPart = (x - cliff) / (duration - cliff);
        // y = immediateUnlockPercentage + Math.pow(exponentialPart, growthRate) * immediateUnlockPercentageRestPercent;
        y = immediateUnlockPercentage + (exponentialPart ** growthRate) * immediateUnlockPercentageRestPercent;
      }
      const formattedDate = formatDateForVestingPlot(prevDate, x > 0 ? 1 : x);
      prevDate = formattedDate;

      data.push({ x: formattedDate, y });
    }

    return data;
  },
  [ShapeType.HYPERBOLIC]: ({ duration, startTimestamp }) => {
    let prevDate = startTimestamp;
    const data = [];

    for (let x = 0; x <= duration; x++) {
      const hyperbolaValue = (x / duration / (x / duration + 1)) * 100 * 2;
      const formattedDate = formatDateForVestingPlot(prevDate, x > 0 ? 1 : x);
      prevDate = formattedDate;
      data.push({ x: formattedDate, y: hyperbolaValue });
    }

    return data;
  },
  [ShapeType.HYPERBOLIC_CLIFF]: ({ duration, cliff, startTimestamp }) => {
    let prevDate = startTimestamp;
    const data = [];

    for (let x = 0; x <= duration; x++) {
      let y = 0;

      if (x < cliff) {
        y = 0;
      } else {
        const hyperbolaPart = (x - cliff) / (duration - cliff);
        y = (hyperbolaPart / (hyperbolaPart + 1)) * 100 * 2;
      }
      const formattedDate = formatDateForVestingPlot(prevDate, x > 0 ? 1 : x);
      prevDate = formattedDate;

      data.push({ x: formattedDate, y });
    }

    return data;
  },
  [ShapeType.HYPERBOLIC_IMMEDIATE]: ({
    duration,
    immediateUnlockPercentage,
    immediateUnlockPercentageRestPercent,
    startTimestamp,
  }) => {
    let prevDate = startTimestamp;
    const data = [];

    for (let x = 0; x <= duration; x++) {
      const hyperbolaPart = x / duration / (x / duration + 1);
      const y = immediateUnlockPercentage + hyperbolaPart * immediateUnlockPercentageRestPercent * 2;
      const formattedDate = formatDateForVestingPlot(prevDate, x > 0 ? 1 : x);
      prevDate = formattedDate;

      data.push({ x: formattedDate, y });
    }

    return data;
  },
  [ShapeType.HYPERBOLIC_CLIFF_IMMEDIATE]: ({ duration, cliff, immediateUnlockPercentage, startTimestamp }) => {
    let prevDate = startTimestamp;
    const data = [];

    for (let x = 0; x <= duration; x++) {
      let y = 0;

      if (x <= cliff) {
        y = immediateUnlockPercentage;
      } else {
        const hyperbolaPart = (x - cliff) / (duration - cliff) / ((x - cliff) / (duration - cliff) + 1);
        y = immediateUnlockPercentage + hyperbolaPart * (100 - immediateUnlockPercentage) * 2;
      }
      const formattedDate = formatDateForVestingPlot(prevDate, x > 0 ? 1 : x);
      prevDate = formattedDate;

      data.push({ x: formattedDate, y });
    }

    return data;
  },
  [ShapeType.LINEAR_MONTHLY_STEPS]: ({ duration, period, startTimestamp }) => generateLinearSteppedData({ duration, period, startTimestamp }),
  [ShapeType.EXPONENTIAL_MONTHLY_STEPS]: ({ duration, period, growthRate, startTimestamp }) =>
    generateExponentialSteppedData({ duration, period, growthRate, startTimestamp }),
  [ShapeType.HYPERBOLIC_MONTHLY_STEPS]: ({ duration, period, startTimestamp }) => generateHyperbolicSteppedData({ duration, period, startTimestamp }),
};
