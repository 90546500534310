import React, { FC, PropsWithChildren } from "react";
import { useIntl } from "react-intl";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

import {
  BoxContentLeftSide,
  BoxWithTitle,
  ContentMode,
  CustomTooltip,
  InfoSvg,
  progressBarColors,
  StyledContentRightSide,
  StyledLinearProgress,
  StyledProgressBarBox,
  TOKEN_PERCENTAGE_FIX_VALUE,
} from "../../../../shared";
import { StyledContent } from "../styled";
import { UseAssetBox } from "../useAssetBox";

interface IStartingBoxContent {
  imageUrl: string;
  assetBoxData: UseAssetBox;
}

export const BoxContent: FC<PropsWithChildren<IStartingBoxContent>> = props => {
  const {
    imageUrl,
    assetBoxData: {
      withdrawnPercentage,
      releasablePercentage,
      releasablePercentageForDisplaying,
      releasable,
      numericTokenCount,
      remainingTokensProgress,
      tokenValue,
      remainingTokens,
      withDrawn,
      withdrawnPercentageForDisplaying,
    },
    children,
  } = props;
  const { progressOrange, progressBlue, progressGreen } = progressBarColors;

  const { formatMessage } = useIntl();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <BoxWithTitle title={formatMessage({ id: "pages.tokens.current.contentTitle" })}>
      <StyledContent container spacing={2} direction={!isMd ? "row" : "column"} alignItems={"center"}>
        <BoxContentLeftSide
          tokenImageUrl={imageUrl}
          tokensCount={withDrawn}
          subtitle={formatMessage(
            { id: "pages.tokens.current.contentSubtitle" },
            { token: tokenValue, value: numericTokenCount },
          )}
          tooltipTextId="pages.tokens.current.balanceTooltip"
          mode={ContentMode.ASSET}
        />

        <StyledContentRightSide size={{ xs: 12, sm: 12, md: 8, lg: 8, xl: 8 }}>
          <StyledProgressBarBox>
            <Typography variant="body1" component="p">
              {`${formatMessage(
                { id: "pages.tokens.current.tokensInBox" },
                {
                  token: tokenValue,
                  value: remainingTokens,
                },
              )}`}
            </Typography>
            <StyledLinearProgress
              {...progressOrange}
              content={`${remainingTokensProgress.toFixed(TOKEN_PERCENTAGE_FIX_VALUE)}%` as never}
              variant="determinate"
              value={remainingTokensProgress}
            />
          </StyledProgressBarBox>

          <StyledProgressBarBox>
            <Typography variant="body1" component="p">
              {`${formatMessage(
                { id: "pages.tokens.current.withdrawn" },
                {
                  token: tokenValue,
                  value: withDrawn,
                },
              )}`}
            </Typography>

            <StyledLinearProgress
              {...progressBlue}
              content={`${withdrawnPercentageForDisplaying}%` as never}
              variant="determinate"
              value={withdrawnPercentage}
            />
          </StyledProgressBarBox>

          <StyledProgressBarBox>
            <Box className="ProgressBar-tooltip_box">
              <Typography variant="body1" component="p">
                {`${formatMessage(
                  { id: "pages.tokens.current.releasable" },
                  {
                    token: tokenValue,
                    value: releasable,
                  },
                )}`}
              </Typography>
              <CustomTooltip title={formatMessage({ id: "pages.tokens.current.releasableProgressTooltip" })}>
                <InfoSvg />
              </CustomTooltip>
            </Box>
            <StyledLinearProgress
              {...progressGreen}
              content={`${releasablePercentageForDisplaying}%` as never}
              variant="determinate"
              value={releasablePercentage}
            />
          </StyledProgressBarBox>

          {children}
        </StyledContentRightSide>
      </StyledContent>
    </BoxWithTitle>
  );
};
