import { Button, styled } from "@mui/material";
import { StylesScheme } from "../../../shared";
import { ButtonVariant } from "./types";

export const StyledButton = styled(Button)(() => ({
  height: 48,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 100,
  textTransform: "none",
  textAlign: "left",
  padding: "10px 21px",
  fontSize: 12,
  color: "#e1e9ef",
}));

export const simpleButtonStylesByVariant = (
  theme: any,
): {
  [Key in ButtonVariant]: StylesScheme;
} => ({
  primary: {
    width: 190,
    border: "1px solid #BEBEBE",
    background: "#1B5DA5",
    "&:hover": {
      border: "1px solid #4F7986",
      background: "#2775CA",
    },
    fontSize: theme.spacing(2),
  },
  secondary: {
    width: 158,
    border: "1px solid #676767",
    background: "rgba(12, 27, 39, 0.10)",
    backdropFilter: " blur(9.5625px)",
    "&:hover": {
      border: "1px solid #E7F0FA",
      background: "rgba(79, 121, 134, 0.10)",
      backdropFilter: "blur(9.6px)",
    },
    [theme.breakpoints.down("md")]: {
      width: 190,
    },
  },
  link: {
    fontSize: theme.spacing(1.75),
    color: "#1975d2",
    padding: 0,
    margin: 0,
  }
});
