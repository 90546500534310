import { object, string } from "yup";

import { emailValidationSchema } from "@dzambalaorg/yup-rules";

export const validationSchema = object().shape({
  date: string().required("form.validations.valueMissing"),
  projectName: string().required("form.validations.valueMissing"),
  fullTokenName: string().required("form.validations.valueMissing"),
  tokenTicker: string().required("form.validations.valueMissing"),
  email: emailValidationSchema,
});
