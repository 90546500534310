import React from "react";
import { FormattedMessage } from "react-intl";

import { FormWrapper } from "@dzambalaorg/mui-form";
import { AutocompleteInput, TextArea, TextInput } from "@dzambalaorg/mui-inputs-core";
import { useApi } from "@dzambalaorg/provider-api";
import {
  EmailType,
  IApplyVesting,
  IApplyVestingBusiness,
  IApplyVestingCommunity,
  IApplyVestingFiles,
  IApplyVestingGeneral,
  IApplyVestingProduct,
  IApplyVestingProject,
  IApplyVestingQuestions,
  IApplyVestingToken,
} from "@framework/types";

import { FileInput } from "../../../../../../components/inputs/file-input";
import { StepperActiveSteps, useStepperContext } from "../context";
import { Root, StyledFieldBox } from "./styled";
import { questionOptions } from "./constants";
import { secondStepFormEmpty } from "./empty";
import { validationSchema } from "./validation";

interface ISecondStepForm
  extends IApplyVestingCommunity,
    IApplyVestingFiles,
    IApplyVestingGeneral,
    IApplyVestingProduct,
    IApplyVestingProject,
    IApplyVestingQuestions,
    IApplyVestingToken,
    IApplyVestingBusiness {
  contactDetails: string;
  whoRefereed: string;
  antiPhishingCode: string;
}

export const SecondStepForm = () => {
  const { firstStepData, handleNext } = useStepperContext();
  const api = useApi();

  const handleConfirm = async (values: ISecondStepForm, form: any) => {
    const preparedSubmitData: IApplyVesting = {
      general: firstStepData,
      contactDetails: values.contactDetails,
      whoRefereed: values.whoRefereed,
      questions: {
        keyQuestionA: values.keyQuestionA,
        keyQuestionB: values.keyQuestionB,
        keyQuestionC: values.keyQuestionC,
      },
      token: {
        tokenSymbol: values.tokenSymbol,
        tokenCirculation: values.tokenCirculation,
        tgeDeadlines: values.tgeDeadlines,
        tokenUtility: values.tokenUtility,
        tokenDistribution: values.tokenDistribution,
      },
      project: {
        projectFocusOn: values.projectFocusOn,
        highPotential: values.highPotential,
        painPoints: values.painPoints,
        painSolutions: values.painSolutions,
        productAudience: values.productAudience,
        projectBenefits: values.projectBenefits,
        team: values.team,
      },
      product: {
        productLaunched: values.productLaunched,
        numberOfUsers: values.numberOfUsers,
        tvl: values.tvl,
        totalVolume: values.totalVolume,
        nftSales: values.nftSales,
      },
      community: {
        communitySize: values.communitySize,
        communityDemography: values.communityDemography,
        communitySupport: values.communitySupport,
      },
      business: {
        innovations: values.innovations,
        streamsRevenue: values.streamsRevenue,
        isTeamWorking: values.isTeamWorking,
        partners: values.partners,
        mainCompetitors: values.mainCompetitors,
        mainInnovations: values.mainInnovations,
        isProjectOpen: values.isProjectOpen,
        extendingVesting: values.extendingVesting,
        willingToLowerPrice: values.willingToLowerPrice,
      },
      files: {
        legalTokenDocs: values.legalTokenDocs,
        constitutionalDocs: values.constitutionalDocs,
        confirmationDocs: values.confirmationDocs,
      },
      antiPhishingCode: values.antiPhishingCode,
    };

    await api
      .fetchJson({
        url: `/emails/${EmailType.APPLY_VESTING}`,
        method: "POST",
        data: preparedSubmitData,
      })
      .then(() => {
        form.reset();
        handleNext(StepperActiveSteps.THIRD)
        return null;
      });
  };

  return (
    <Root>
      <FormWrapper
        sx={{ minWidth: "320px", display: "flex", flexDirection: "column", gap: 3 }}
        initialValues={secondStepFormEmpty}
        onSubmit={handleConfirm}
        validationSchema={validationSchema}
        submit={"applyVesting"}
      >
        <StyledFieldBox>
          <FormattedMessage id="pages.vesting.application.contactDetailsTitle" tagName="h5" />
          <FormattedMessage id="pages.vesting.application.contactDetailsSubtitle_1" tagName="p" />
          <FormattedMessage id="pages.vesting.application.contactDetailsSubtitle_2" tagName="p" />
          <FormattedMessage id="pages.vesting.application.contactDetailsSubtitle_3" tagName="p" />
          <FormattedMessage id="pages.vesting.application.contactDetailsSubtitle_4" tagName="p" />
          <TextInput multiline name="contactDetails" required variant="outlined" placeholder={""} />
        </StyledFieldBox>

        <StyledFieldBox>
          <FormattedMessage id="pages.vesting.application.whoRefereedTitle" tagName="h5" />
          <FormattedMessage id="pages.vesting.application.whoRefereedSubtitle" tagName="p" />
          <TextInput multiline name="whoRefereed" required variant="outlined" placeholder={""} />
        </StyledFieldBox>

        <StyledFieldBox>
          <FormattedMessage id="pages.vesting.application.keyQuestionATitle" tagName="h5" />
          <FormattedMessage id="pages.vesting.application.keyQuestionASubtitle" tagName="p" />
          <TextInput multiline name="keyQuestionA" required variant="outlined" placeholder={""} />
        </StyledFieldBox>

        <StyledFieldBox>
          <FormattedMessage id="pages.vesting.application.keyQuestionBTitle" tagName="h5" />
          <FormattedMessage id="pages.vesting.application.keyQuestionBSubtitle" tagName="p" />
          <TextInput multiline name="keyQuestionB" required variant="outlined" placeholder={""} />
        </StyledFieldBox>

        <StyledFieldBox>
          <FormattedMessage id="pages.vesting.application.keyQuestionCTitle" tagName="h5" />
          <FormattedMessage id="pages.vesting.application.keyQuestionCSubtitle" tagName="p" />
          <TextInput multiline name="keyQuestionC" required variant="outlined" placeholder={""} />
        </StyledFieldBox>

        <StyledFieldBox>
          <FormattedMessage id="pages.vesting.application.tokenSymbolTitle" tagName="h5" />
          <TextInput multiline name="tokenSymbol" required variant="outlined" placeholder={""} />
        </StyledFieldBox>

        <StyledFieldBox>
          <FormattedMessage id="pages.vesting.application.tokenCirculationTitle" tagName="h5" />
          <AutocompleteInput name="tokenCirculation" options={questionOptions} variant="outlined" placeholder={""} />
        </StyledFieldBox>

        <StyledFieldBox>
          <FormattedMessage id="pages.vesting.application.tgeDeadlinesTitle" tagName="h5" />
          <TextInput multiline name="tgeDeadlines" required variant="outlined" placeholder={""} />
        </StyledFieldBox>

        <StyledFieldBox>
          <FormattedMessage id="pages.vesting.application.projectFocusOnTitle" tagName="h5" />
          <FormattedMessage id="pages.vesting.application.summary" tagName="p" />
          <TextArea multiline name="projectFocusOn" required variant="outlined" placeholder={""} />
        </StyledFieldBox>

        <StyledFieldBox>
          <FormattedMessage id="pages.vesting.application.highPotentialTitle" tagName="h5" />
          <FormattedMessage id="pages.vesting.application.summary" tagName="p" />
          <TextArea multiline name="highPotential" variant="outlined" placeholder={""} />
        </StyledFieldBox>

        <StyledFieldBox>
          <FormattedMessage id="pages.vesting.application.painPointsTitle" tagName="h5" />
          <FormattedMessage id="pages.vesting.application.summary" tagName="p" />
          <TextArea multiline name="painPoints" required variant="outlined" placeholder={""} />
        </StyledFieldBox>

        <StyledFieldBox>
          <FormattedMessage id="pages.vesting.application.painSolutionsTitle" tagName="h5" />
          <FormattedMessage id="pages.vesting.application.summary" tagName="p" />
          <TextArea multiline name="painSolutions" required variant="outlined" placeholder={""} />
        </StyledFieldBox>

        <StyledFieldBox>
          <FormattedMessage id="pages.vesting.application.productAudienceTitle" tagName="h5" />
          <FormattedMessage id="pages.vesting.application.summary" tagName="p" />
          <TextArea multiline name="productAudience" required variant="outlined" placeholder={""} />
        </StyledFieldBox>

        <StyledFieldBox>
          <FormattedMessage id="pages.vesting.application.projectBenefitsTitle" tagName="h5" />
          <FormattedMessage id="pages.vesting.application.summary" tagName="p" />
          <TextArea multiline name="projectBenefits" required variant="outlined" placeholder={""} />
        </StyledFieldBox>

        <StyledFieldBox>
          <FormattedMessage id="pages.vesting.application.teamTitle" tagName="h5" />
          <FormattedMessage id="pages.vesting.application.teamSubtitle_a" tagName="p" />
          <FormattedMessage id="pages.vesting.application.teamSubtitle_b" tagName="p" />
          <FormattedMessage id="pages.vesting.application.teamSubtitle_c" tagName="p" />
          <TextArea multiline name="team" required variant="outlined" placeholder={""} />
        </StyledFieldBox>

        <StyledFieldBox>
          <FormattedMessage id="pages.vesting.application.tokenUtilityTitle" tagName="h5" />
          <FormattedMessage id="pages.vesting.application.tokenomics" tagName="p" />
          <TextArea multiline name="tokenUtility" required variant="outlined" placeholder={""} />
        </StyledFieldBox>

        <StyledFieldBox>
          <FormattedMessage id="pages.vesting.application.tokenDistributionTitle" tagName="h5" />
          <FormattedMessage id="pages.vesting.application.tokenomics" tagName="p" />
          <TextArea multiline name="tokenDistribution" required variant="outlined" placeholder={""} />
        </StyledFieldBox>

        <StyledFieldBox>
          <FormattedMessage id="pages.vesting.application.productLaunchedTitle" tagName="h5" />
          <FormattedMessage id="pages.vesting.application.productLaunchedSubtitle" tagName="p" />
          <AutocompleteInput name="productLaunched" options={questionOptions} variant="outlined" placeholder={""} />
        </StyledFieldBox>

        <StyledFieldBox>
          <FormattedMessage id="pages.vesting.application.numberOfUsersTitle" tagName="h5" />
          <FormattedMessage id="pages.vesting.application.approximateStatistics" tagName="p" />
          <TextInput multiline name="numberOfUsers" required variant="outlined" placeholder={""} />
        </StyledFieldBox>

        <StyledFieldBox>
          <FormattedMessage id="pages.vesting.application.tvlTitle" tagName="h5" />
          <FormattedMessage id="pages.vesting.application.approximateStatistics" tagName="p" />
          <TextInput multiline name="tvl" variant="outlined" placeholder={""} />
        </StyledFieldBox>

        <StyledFieldBox>
          <FormattedMessage id="pages.vesting.application.totalVolumeTitle" tagName="h5" />
          <FormattedMessage id="pages.vesting.application.approximateStatistics" tagName="p" />
          <TextInput multiline name="totalVolume" variant="outlined" placeholder={""} />
        </StyledFieldBox>

        <StyledFieldBox>
          <FormattedMessage id="pages.vesting.application.nftSalesTitle" tagName="h5" />
          <FormattedMessage id="pages.vesting.application.approximateStatistics" tagName="p" />
          <TextInput multiline name="nftSales" variant="outlined" placeholder={""} />
        </StyledFieldBox>

        <StyledFieldBox>
          <FormattedMessage id="pages.vesting.application.communitySizeTitle" tagName="h5" />
          <FormattedMessage id="pages.vesting.application.approximateStatistics" tagName="p" />
          <TextInput multiline name="communitySize" required variant="outlined" placeholder={""} />
        </StyledFieldBox>

        <StyledFieldBox>
          <FormattedMessage id="pages.vesting.application.communityDemographyTitle" tagName="h5" />
          <FormattedMessage id="pages.vesting.application.approximateStatistics" tagName="p" />
          <TextInput multiline name="communityDemography" required variant="outlined" placeholder={""} />
        </StyledFieldBox>

        <StyledFieldBox>
          <FormattedMessage id="pages.vesting.application.communitySupportTitle" tagName="h5" />
          <FormattedMessage id="pages.vesting.application.communitySupportSubtitle" tagName="p" />
          <TextArea multiline name="communitySupport" required variant="outlined" placeholder={""} />
        </StyledFieldBox>

        <StyledFieldBox>
          <FormattedMessage id="pages.vesting.application.innovationsTitle" tagName="h5" />
          <FormattedMessage id="pages.vesting.application.businessModel" tagName="p" />
          <TextArea multiline name="innovations" required variant="outlined" placeholder={""} />
        </StyledFieldBox>

        <StyledFieldBox>
          <FormattedMessage id="pages.vesting.application.streamsRevenueTitle" tagName="h5" />
          <FormattedMessage id="pages.vesting.application.businessModel" tagName="p" />
          <TextArea multiline name="streamsRevenue" required variant="outlined" placeholder={""} />
        </StyledFieldBox>

        <StyledFieldBox>
          <FormattedMessage id="pages.vesting.application.isTeamWorkingTitle" tagName="h5" />
          <FormattedMessage id="pages.vesting.application.isTeamWorkingSubtitle" tagName="p" />
          <AutocompleteInput name="isTeamWorking" options={questionOptions} variant="outlined" placeholder={""} />
        </StyledFieldBox>

        <StyledFieldBox>
          <FormattedMessage id="pages.vesting.application.partnersTitle" tagName="h5" />
          <FormattedMessage id="pages.vesting.application.partnersSubtitle" tagName="p" />
          <TextArea multiline name="partners" required variant="outlined" placeholder={""} />
        </StyledFieldBox>

        <StyledFieldBox>
          <FormattedMessage id="pages.vesting.application.mainCompetitorsTitle" tagName="h5" />
          <FormattedMessage id="pages.vesting.application.mainCompetitors" tagName="p" />
          <TextInput multiline name="mainCompetitors" required variant="outlined" placeholder={""} />
        </StyledFieldBox>

        <StyledFieldBox>
          <FormattedMessage id="pages.vesting.application.mainInnovationsTitle" tagName="h5" />
          <FormattedMessage id="pages.vesting.application.mainCompetitors" tagName="p" />
          <TextInput multiline name="mainInnovations" required variant="outlined" placeholder={""} />
        </StyledFieldBox>

        <StyledFieldBox>
          <FormattedMessage id="pages.vesting.application.isProjectOpenTitle" tagName="h5" />
          <AutocompleteInput name="isProjectOpen" options={questionOptions} variant="outlined" placeholder={""} />
        </StyledFieldBox>

        <StyledFieldBox>
          <FormattedMessage id="pages.vesting.application.extendingVestingTitle" tagName="h5" />
          <FormattedMessage id="pages.vesting.application.tokenomicsAdjustments" tagName="p" />
          <AutocompleteInput name="extendingVesting" options={questionOptions} variant="outlined" placeholder={""} />
        </StyledFieldBox>

        <StyledFieldBox>
          <FormattedMessage id="pages.vesting.application.willingToLowerPriceTitle" tagName="h5" />
          <FormattedMessage id="pages.vesting.application.tokenomicsAdjustments" tagName="p" />
          <AutocompleteInput name="willingToLowerPrice" options={questionOptions} variant="outlined" placeholder={""} />
        </StyledFieldBox>

        <StyledFieldBox>
          <FormattedMessage id="pages.vesting.application.legalTokenDocsTitle" tagName="h5" />
          <FileInput name="legalTokenDocs" />
        </StyledFieldBox>

        <StyledFieldBox>
          <FormattedMessage id="pages.vesting.application.constitutionalDocsTitle" tagName="h5" />
          <FileInput name="constitutionalDocs" />
        </StyledFieldBox>

        <StyledFieldBox>
          <FormattedMessage id="pages.vesting.application.antiPhishingCodeTitle" tagName="h5" />
          <TextInput multiline name="antiPhishingCode" required variant="outlined" placeholder={""} />
        </StyledFieldBox>

        <StyledFieldBox>
          <FormattedMessage id="pages.vesting.application.confirmationDocsTitle" tagName="h5" />
          <FileInput name="confirmationDocs" />
        </StyledFieldBox>
      </FormWrapper>
    </Root>
  );
};
