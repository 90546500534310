import { Box, styled } from "@mui/material";

export const Root = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: theme.spacing(3),
  padding: theme.spacing(10, 2, 2, 2),

  fontSize: theme.spacing(3),
  fontWeight: 700,
  lineHeight: "140%",
  letterSpacing: "-0.12px",
  color: "#000000",

  "& .MuiSvgIcon-root": {
    width: 200,
    height: 200,
    fill: "#448327"
  }
}));
