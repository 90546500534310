import { useState } from "react";
import { BigNumber, Contract, utils } from "ethers";
import { Web3ContextType } from "@web3-react/core";

import { useMetamask } from "@dzambalaorg/react-hooks-eth";

import ReleasableAbi from "@framework/abis/json/ERC721Vesting/releasable.json";
import WithdrawnAmountsAbi from "@framework/abis/json/ERC721Vesting/getWithdrawnAmounts.json";

import { TOKEN_DISPLAYING_FIX_VALUE, TOKEN_PERCENTAGE_FIX_VALUE } from "../constants";
import { useReFetch } from "./useRefetch";

export const useVestingContractInfo = (tokenId: string, tokenAddress: string, tokensCount: number) => {
  const [releasable, setReleasable] = useState<number>(0);
  const [withDrawn, setWithDrawn] = useState<number>(0);

  const releasableMetaFn = useMetamask(
    (web3Context: Web3ContextType) => {
      if (!tokenId || !tokenAddress) {
        return Promise.reject();
      }
      const contract = new Contract(tokenAddress, ReleasableAbi, web3Context.provider?.getSigner());
      return contract.releasable(tokenId);
    },
    { success: false, error: false },
  );

  const withdrawAmountMetaFn = useMetamask(
    (web3Context: Web3ContextType) => {
      if (!tokenId || !tokenAddress) {
        return Promise.reject();
      }
      const contract = new Contract(tokenAddress, WithdrawnAmountsAbi, web3Context.provider?.getSigner());
      return contract.getWithdrawnAmounts(tokenId);
    },
    { success: false, error: false },
  );

  const getVestingContractInfo = async () => {
    return Promise.all([releasableMetaFn(), withdrawAmountMetaFn()]).then(
      ([releasableResponse, withdrawAmountResponse]) => {
        /** releasable response has type Array<[tokenType, tokenAddress, tokenId, amount]> **/
        const releasableAmount = releasableResponse[0 as keyof typeof releasableResponse][3];
        const formattedReleasableValue = Number(utils.formatUnits(BigNumber.from(releasableAmount), 18)).toFixed(
          TOKEN_DISPLAYING_FIX_VALUE,
        );
        setReleasable(Number(formattedReleasableValue));

        /** getWithdrawnAmounts response has type Array<[tokenType, tokenAddress, tokenId, amount]> **/
        const withdrawAmount = withdrawAmountResponse[0 as keyof typeof withdrawAmountResponse][3];
        const formattedWithdrawAmountValue = Number(utils.formatUnits(BigNumber.from(withdrawAmount), 18)).toFixed(
          TOKEN_DISPLAYING_FIX_VALUE,
        );
        setWithDrawn(Number(formattedWithdrawAmountValue));
      },
    );
  };

  useReFetch(getVestingContractInfo);

  const releasablePercentage = ((releasable / (tokensCount - withDrawn)) * 100).toFixed(5);
  const releasablePercentageForDisplaying = Number(releasablePercentage).toFixed(TOKEN_PERCENTAGE_FIX_VALUE);

  return {
    releasable,
    releasablePercentage,
    releasablePercentageForDisplaying,
    withDrawn,
  };
};
