import { Box, styled } from "@mui/material";

import { flexStyles } from "./styles";


export const Root = styled(Box)(({ theme }) => ({
  ...flexStyles,

  "& .MainPage-cardBoxWrapper": {
    display: "flex",
    justifyContent: "center",
    width: "100vw",
    background: "#F2F2F2",
    paddingBottom: theme.spacing(4),
  },
}));
