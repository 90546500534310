import { Grid2, styled } from "@mui/material";

export const StyledContent = styled(Grid2)(({ theme }) => ({
  marginBottom: theme.spacing(5),
  paddingTop: theme.spacing(3),

  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}));
