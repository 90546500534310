import { Box, BoxProps, styled } from "@mui/material";

interface StyledFileIconBoxProps {
  imageUrl: string;
}

export const StyledFileCardRoot = styled(Box, { shouldForwardProp: prop => prop !== "imageUrl" })<
  BoxProps & StyledFileIconBoxProps
>(({ theme, imageUrl }) => ({
  position: "relative",
  width: "85px !important",
  height: "85px !important",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: theme.spacing(1.25),
  border: "1px solid #0B1723",
  background: "transparent",
  backgroundImage: `url(${imageUrl})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
}));

export const StyledFileIconBox = styled(Box)(() => ({
  width: "fit-content !important",
  height: "fit-content !important",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  "& .MuiSvgIcon-root": {
    fill: "#2775CA",
  },
})) as typeof Box;

export const StyledFileIconButton = styled(Box)(() => ({
  position: "absolute",
  top: 3,
  left: 0,
  width: "15px",
  height: "15px",
  cursor: "pointer",

  "& .MuiSvgIcon-root": {
    fill: "#2775CA",
  },
})) as typeof Box;
