import React, { FC, MouseEvent, useState } from "react";
import { ListItemText, MenuItem, useMediaQuery, useTheme } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

import type { INetwork } from "@dzambalaorg/types-blockchain";
import { ProgressOverlay } from "@dzambalaorg/mui-page-layout";
import { useUser } from "@dzambalaorg/provider-user";
import { useAppSelector } from "@dzambalaorg/redux";
import { walletSelectors, SANDBOX_CHAINS } from "@dzambalaorg/provider-wallet";

import { ChainSvg, getIconByChainId } from "../../../shared";
import {
  spinnerMixin,
  StyledBadge,
  StyledCircle,
  StyledListItemIcon,
  StyledMenu,
  StyledMenuTrigger,
  StyledSvgIcon,
} from "./styled";

export const NetworkButton: FC = () => {
  const user = useUser<any>();
  const [anchor, setAnchor] = useState<Element | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const networks = useAppSelector<Record<number, INetwork>>(walletSelectors.networksSelector);

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  const handleMenuOpen = (event: MouseEvent): void => {
    setAnchor(event.currentTarget);
  };

  const handleMenuClose = (): void => {
    setAnchor(null);
  };

  const handleSelectNetwork = (chainId: number) => async () => {
    setIsLoading(true);
    handleMenuClose();
    await user.setProfile({ chainId });
    setIsLoading(false);
  };

  if (!user?.profile || !Object.keys(networks).length) {
    return null;
  }

  const { chainId } = user.profile;
  const isSandbox = SANDBOX_CHAINS.includes(chainId);
  const chainIcon = getIconByChainId(chainId) || ChainSvg;

  return (
    <React.Fragment>
      <StyledMenuTrigger
        isAnchor={!!anchor}
        onClick={handleMenuOpen}
        endIcon={anchor ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      >
        <ProgressOverlay wrapperSx={{ minHeight: 0 }} spinnerSx={spinnerMixin} isLoading={isLoading}>
          <StyledBadge color="primary" badgeContent={<StyledCircle />} invisible={!isSandbox}>
            <StyledSvgIcon component={chainIcon} viewBox="0 0 60 60" />
          </StyledBadge>
        </ProgressOverlay>
        {!isSm && <span>{networks[chainId].chainName}</span>}
      </StyledMenuTrigger>

      <StyledMenu id="select-chainId" anchorEl={anchor} open={!!anchor} onClose={handleMenuClose}>
        {Object.values(networks)
          .sort((a, b) => Number(a.order) - Number(b.order))
          .map(network => {
            const chainIcon = getIconByChainId(network.chainId) || ChainSvg;
            return (
              <MenuItem
                key={network.chainId}
                selected={network.chainId === chainId}
                onClick={handleSelectNetwork(network.chainId)}
                color="inherit"
              >
                <StyledListItemIcon>
                  <StyledSvgIcon component={chainIcon} viewBox="0 0 60 60" />
                </StyledListItemIcon>
                <ListItemText>{network.chainName}</ListItemText>
              </MenuItem>
            );
          })}
      </StyledMenu>
    </React.Fragment>
  );
};
