import { Box, styled } from "@mui/material";
import { contentSideFlex } from "../../../../shared";

export const StyledAccordion = styled(Box)(({ theme }) => ({
  width: "100%",
  ...contentSideFlex,
  alignItems: "flex-start",
  gap: theme.spacing(1),

  "& p": {
    position: "relative",
    "&::before": {
      content: "'-'",
      marginRight: theme.spacing(1),
      color: "inherit",
      fontSize: "inherit",
    },
  },

  "& span": {
    position: "relative",
    "&::before": {
      content: "'\\2022'",
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      color: "inherit",
      fontSize: "inherit",
    },
  },
}));
