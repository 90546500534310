import { Grid2, styled } from "@mui/material";

export const OfferAssetRoot = styled(Grid2)(({ theme }) => ({
  display: "flex",
  padding: theme.spacing(1.25, 0),

  [theme.breakpoints.down("md")]: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1, 1.5),
    borderRadius: theme.spacing(1),
    border: "1px solid #84CCFF",
    background: "#FFF",

    color: "#000",
    fontSize: theme.spacing(2),
    fontWeight: 400,
    lineHeight: "130%" /* 20.8px */,
  },
}));
