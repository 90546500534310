import React, { ChangeEvent, FC, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Box, Grid2, Typography } from "@mui/material";

import { IVestingBox } from "@framework/types";
import { ListActionVariant } from "@framework/styled";

import { SplitTokenButton } from "../../../components/buttons/split";
import { ISelected } from "../../../pages/exchange/current-asset-box/useFetchToken";
import { ContentMode } from "../../types";
import { TBoxContent } from "../../utils";
import { BootstrapInput, StyledBlockRoot } from "../styled";
import { CustomSlider } from "../slider";
import { WithBorderInfoBox } from "../with-border-info-box";
import { StyledCalculationBox, StyledSlidesBox } from "./styled";
import { CalculationSlides } from "./types";
import { calculationSlidesMaxValues, splitCalculation } from "./utils";
import { calculationSlidesInitialValue, splitCalculationLayout } from "./schemas";
import { CustomTooltip } from "../tooltip";
import { InfoSvg } from "../../assets";
import { CustomInput } from "../custom-input";

interface ISplitCalculationProps {
  box: IVestingBox;
  token?: ISelected;
  mode?: ContentMode;
  releasableValue?: number;
  withDrawnValue?: number;
}

export const SplitCalculation: FC<ISplitCalculationProps> = props => {
  const { mode = ContentMode.BOX, box, token, withDrawnValue, releasableValue } = props;

  const [calculationSlides, setCalculationSlides] = useState<CalculationSlides>(
    () => calculationSlidesInitialValue[mode],
  );

  const [vendorAllowanceValue, setVendorAllowanceValue] = useState("0");

  const { formatMessage } = useIntl();

  const onSliderChange = (event: any, value: number | number[]) => {
    const sliderName = event.target.name;

    if (!Array.isArray(value)) {
      setCalculationSlides(prev => ({ ...prev, [sliderName]: value }));
    }
  };

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setVendorAllowanceValue(event.target.value);
  };

  const preparedCalculationConfig = {
    split: calculationSlides.split,
    releasable: releasableValue || calculationSlides.releasable || 0,
    withdrawn: withDrawnValue || calculationSlides.withdrawn || 0,
  };

  const { leftBoxCalculation, rightBoxCalculation } = useMemo(() => {
    const config: TBoxContent = token
      ? {
          price: token.template?.price,
          content: token.template.box.content,
          duration: token.template.box.duration,
          cliff: token.template.box.cliff,
          afterCliffBasisPoints: token.template.box.afterCliffBasisPoints,
        }
      : {
          price: box.template?.price,
          content: box.content,
          duration: box.duration,
          cliff: box.cliff,
          afterCliffBasisPoints: box.afterCliffBasisPoints,
        };

    return splitCalculation(preparedCalculationConfig, Number(vendorAllowanceValue), config);
  }, [preparedCalculationConfig]);

  return (
    <StyledBlockRoot>
      {mode === ContentMode.BOX && (
        <Box className="BlockRoot-headerBox">
          <FormattedMessage id="pages.vesting.currentBox.splitCalculationTitle" tagName="h4" />
          <CustomTooltip title={formatMessage({ id: "pages.vesting.currentBox.splitCalculationInfo" })}>
            <InfoSvg />
          </CustomTooltip>
        </Box>
      )}
      <Grid2
        sx={{ width: "100%", marginTop: 2 }}
        container
        size={12}
        spacing={2.5}
        direction={splitCalculationLayout[mode].direction}
        alignItems={"center"}
      >
        <StyledSlidesBox size={splitCalculationLayout[mode].slidesSize}>
          {Object.entries(calculationSlides).map(([key, value]) => {
            return (
              <CustomSlider
                key={key}
                name={key}
                value={value}
                max={calculationSlidesMaxValues(preparedCalculationConfig)[key as keyof CalculationSlides]}
                min={1}
                onChange={onSliderChange}
                title={formatMessage({ id: `pages.vesting.currentBox.calculationSlides.${key}` }, { value })}
              />
            );
          })}
          <CustomInput
            label={formatMessage(
              { id: "pages.vesting.currentBox.calculationSlides.vendorAllowance" },
              { value: vendorAllowanceValue },
            )}
            onChange={onInputChange}
          />
          {token && mode === ContentMode.ASSET && (
            <SplitTokenButton
              splitPercentage={calculationSlides.split}
              className={"CurrentToken-splitBtn"}
              token={token}
              variant={ListActionVariant.button}
            />
          )}
        </StyledSlidesBox>
        <StyledCalculationBox size={splitCalculationLayout[mode].calculationSize} gap={1}>
          <WithBorderInfoBox
            title={formatMessage({ id: "pages.vesting.currentBox.leftBoxCalculationTitle" })}
            data={leftBoxCalculation}
          />
          <WithBorderInfoBox
            title={formatMessage({ id: "pages.vesting.currentBox.rightBoxCalculationTitle" })}
            data={rightBoxCalculation}
          />
        </StyledCalculationBox>
      </Grid2>
    </StyledBlockRoot>
  );
};
