import { Breakpoint } from "@mui/material";

import { ContentMode } from "../../types";

export const imageSizeByMode: Record<ContentMode, number> = {
  [ContentMode.BOX]: 50,
  [ContentMode.ASSET]: 80,
};

export const containerSizesByMode: Record<ContentMode, Record<Breakpoint, number>> = {
  [ContentMode.BOX]: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
  [ContentMode.ASSET]: { xs: 12, sm: 12, md: 4, lg: 4, xl: 4 },
};
