import React, { FC, useState } from "react";
import { Box, SvgIcon, Typography } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import PersonIcon from "@mui/icons-material/Person";
import { Link, useLocation } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

import { formatItem } from "@framework/exchange";
import { IVestingBox } from "@framework/types";

import { getBackgroundImg, getPeriodFromSecondsInDays, sizeDecreaseCalc } from "../../utils";
import { INFINITY_DISPLAYING, PATHS } from "../../constants";
import { StyledCardMedia } from "../styled";
import {
  Root,
  StyledAvatar,
  StyledCardActionArea,
  StyledCardActions,
  StyledCardActionsInfo,
  StyledCardActionsTopInfoRight,
} from "./styled";
import { VestingBoxCategoryBadge } from "../../../components/common/badge";
import { pageHeader } from "../../styles";

interface IBoxCard {
  vestingBox: IVestingBox;
}

export const BoxCard: FC<IBoxCard> = props => {
  const { vestingBox } = props;

  const [isHovered, setIsHovered] = useState<boolean>(false);
  const location = useLocation();
  const { formatMessage } = useIntl();

  const duration = vestingBox ? getPeriodFromSecondsInDays(vestingBox.duration + vestingBox.cliff) : null;
  const tokensCount = vestingBox ? formatItem(vestingBox.content).split(" ")[1] : null;
  const tokenImg = vestingBox ? vestingBox.content!.components[0].template!.imageUrl : "";
  const immediateUnlock = vestingBox ? vestingBox.afterCliffBasisPoints / 100 : 0;

  const path = location.pathname === PATHS.VestingBoxes ? PATHS.CurrentBox : PATHS.Token;
  const backgroundImg = vestingBox.backgroundImg || getBackgroundImg(vestingBox.vestingBoxCat);

  const limitDisplaying =
    Number(vestingBox.template!.cap) > 0
      ? `${vestingBox.template!.amount}/${vestingBox.template!.cap}`
      : INFINITY_DISPLAYING;

  return (
    <Root
      component={Link}
      to={`${path.replace(":boxId", `${vestingBox?.id}`)}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <StyledCardActionArea
        sx={{
          transform: isHovered ? "scale(1.04)" : "scale(1.0)",
          transition: "transform 0.3s ease-in-out",
        }}
      >
        <VestingBoxCategoryBadge category={vestingBox.vestingBoxCat} />
        <StyledCardMedia className="BoxCard-media" component="img" image={backgroundImg} />
      </StyledCardActionArea>
      <StyledCardActions>
        <Typography
          sx={{
            ...pageHeader(),
            fontSize: sizeDecreaseCalc(20, 16),
          }}
          variant="body1"
          component="h5"
        >
          {vestingBox!.title}
        </Typography>
        <StyledCardActionsInfo sx={{ paddingTop: 1.25 }}>
          <FormattedMessage tagName="span" id="pages.vesting.boxes.limit" values={{ value: limitDisplaying }} />
          <StyledCardActionsTopInfoRight>
            {duration && (
              <Box className="CardTopInfo-item">
                <SvgIcon component={LockIcon} width={12} height={12} />
                <span className="">{duration}</span>
              </Box>
            )}
            <Box className="CardTopInfo-item">
              <SvgIcon component={PersonIcon} width={12} height={12} />
              <span className="CardTopInfoItem-subtitle">{vestingBox.template!.amount}</span>
            </Box>
          </StyledCardActionsTopInfoRight>
        </StyledCardActionsInfo>
        <StyledCardActionsInfo sx={{ paddingTop: 1.25 }}>
          <span className="CardTopInfoItem-semibold">{formatMessage({ id: "pages.vesting.boxes.tvl" })}</span>
          <span className="CardTopInfoItem-semibold">{formatMessage({ id: "pages.vesting.boxes.tgeUnlock" })}</span>
        </StyledCardActionsInfo>
        <StyledCardActionsInfo>
          {tokensCount && (
            <Box className="CardTopInfo-item">
              <StyledAvatar
                component="img"
                className="BoxCard-avatar"
                src={tokenImg}
                alt={"#"}
                width={40}
                height={40}
              />
              <span className="CardTopInfoItem-bold">{tokensCount}</span>
            </Box>
          )}
          <span className="CardTopInfoItem-green">{`${immediateUnlock}%`}</span>
        </StyledCardActionsInfo>
      </StyledCardActions>
    </Root>
  );
};
