import { useState } from "react";
import { BigNumber, Contract, utils } from "ethers";
import { Web3ContextType } from "@web3-react/core";

import { useMetamask } from "@dzambalaorg/react-hooks-eth";

import ReleasableAbi from "@framework/abis/json/ERC721Vesting/releasable.json";
import WithdrawnAmountsAbi from "@framework/abis/json/ERC721Vesting/getWithdrawnAmounts.json";

import { useReFetch } from "./useRefetch";

export const getWithdrawnAmounts = (tokenId: string, tokenAddress: string, provider: any) => {
  if (!tokenId || !tokenAddress) {
    return Promise.reject();
  }
  const contract = new Contract(tokenAddress, WithdrawnAmountsAbi, provider?.getSigner());
  return contract.getWithdrawnAmounts(tokenId);
};

export const getReleasableAmounts = (tokenId: string, tokenAddress: string, provider: any) => {
  if (!tokenId || !tokenAddress) {
    return Promise.reject();
  }
  const contract = new Contract(tokenAddress, ReleasableAbi, provider?.getSigner());
  return contract.releasable(tokenId);
};

export const useVestingContractInfo = (tokenId: string, tokenAddress: string) => {
  const [releasable, setReleasable] = useState<number>(0);
  const [withdrawn, setWithdrawn] = useState<number>(0);

  const releasableMetaFn = useMetamask(
    (web3Context: Web3ContextType) => {
      return getReleasableAmounts(tokenId, tokenAddress, web3Context.provider);
    },
    { success: false, error: false },
  );

  const withdrawAmountMetaFn = useMetamask(
    (web3Context: Web3ContextType) => {
      return getWithdrawnAmounts(tokenId, tokenAddress, web3Context.provider);
    },
    { success: false, error: false },
  );

  const getVestingContractInfo = async () => {
    return Promise.all([releasableMetaFn(), withdrawAmountMetaFn()]).then(
      ([releasableResponse, withdrawAmountResponse]) => {
        /** releasable response has type Array<[tokenType, tokenAddress, tokenId, amount]> **/
        const releasableAmount = releasableResponse[0 as keyof typeof releasableResponse][3];
        const formattedReleasableValue = Number(utils.formatUnits(BigNumber.from(releasableAmount), 18));
        setReleasable(Number(formattedReleasableValue));

        /** getWithdrawnAmounts response has type Array<[tokenType, tokenAddress, tokenId, amount]> **/
        const withdrawAmount = withdrawAmountResponse[0 as keyof typeof withdrawAmountResponse][3];
        const formattedWithdrawAmountValue = Number(utils.formatUnits(BigNumber.from(withdrawAmount), 18));
        setWithdrawn(Number(formattedWithdrawAmountValue));
      },
    );
  };

  useReFetch(getVestingContractInfo);

  return {
    releasable,
    withdrawn,
  };
};
