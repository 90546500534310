import { Box, Grid2, styled } from "@mui/material";
import { contentSideFlex } from "../../../shared";

export const StyledContent = styled(Grid2)(({ theme }) => ({
  width: "100%",

  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}));

export const StyledContentWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  ...contentSideFlex,
  gap: theme.spacing(5),
}));
