import React from "react";
import { Typography, TypographyProps, Dialog, styled } from "@mui/material";

import { buttonStyles, sizeDecreaseCalc } from "../../shared";

export const StyledHeader = styled(Typography)<TypographyProps & { component: React.ElementType }>(({ theme }) => ({
  ...theme.typography.h2,
  margin: 0,
  color: "#E7F0FA",
  textAlign: "center",
  fontFamily: "'Poppins', sans-serif",
  fontSize: sizeDecreaseCalc(38, 26),
  fontWeight: 500,
  lineHeight: "116%",
  letterSpacing: "-0.38px",

  "& b": {
    background: "var(--43, linear-gradient(90deg, #04B6BA 50.09%, #92FCFF 75.06%))",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },

  "& span": {
    color: "#168897",
  },
}));

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    rowGap: theme.spacing(2),
    margin: 0,
    padding: theme.spacing(1.5, 2),
    backdropFilter: "none",
  },

  "& p": {
    fontSize: sizeDecreaseCalc(16, 14),
    fontWeight: 400,
    lineHeight: "140%",
    letterSpacing: "-0.12px",
  },

  "& .MuiTypography-h6, .MuiIconButton-root, .MuiDialogContent-root": {
    padding: 0,
  },

  "& .MuiTypography-h6": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: theme.spacing(2.25),
    fontWeight: 500,
  },

  "& .MuiButton-root": {
    ...buttonStyles,
  },
}));
