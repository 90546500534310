import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import { IVestingBox } from "@framework/types";

import { Plot } from "../../../../../../shared";
import { Root } from "./styled";
import { TimelineDates } from "./dates";

interface ITimelineProps {
  vestingBox: IVestingBox;
}

export const Timeline: FC<ITimelineProps> = props => {
  const { vestingBox } = props;

  return (
    <Root>
      <FormattedMessage id="pages.vesting.currentBox.timelineTitle" tagName="h4" />
      <Plot vestingBox={vestingBox} startTimestamp={new Date().toDateString()}/>
      <TimelineDates vestingBox={vestingBox}/>
    </Root>
  );
};
