import React, { FC, SyntheticEvent, useState } from "react";
import { StyledAccordion, StyledAccordionDetails, StyledAccordionSummary } from "./styled";
import { TabIcon } from "../../assets";

interface ICustomAccordionProps {
  title: string;
  description: React.ReactNode;
}

export const CustomAccordion: FC<ICustomAccordionProps> = props => {
  const { title, description } = props;
  const [expanded, setExpanded] = useState<boolean>(false);

  const toggleExpanded = () => (_event: SyntheticEvent) => {
    setExpanded(prevState => !prevState);
  };
  return (
    <StyledAccordion disableGutters elevation={0} expanded={expanded} onChange={toggleExpanded()}>
      <StyledAccordionSummary expandIcon={<TabIcon isActive />}>{title}</StyledAccordionSummary>
      <StyledAccordionDetails>{description}</StyledAccordionDetails>
    </StyledAccordion>
  );
};
