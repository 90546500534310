import { FC, useState } from "react";
import { Box } from "@mui/material";
import { Menu as MenuIcon, Close as CloseIcon } from "@mui/icons-material";

import {
  StyledMenuWrapper,
  StyledMenuHeader,
  StyledIconButton,
  StyledSwipeableDrawer,
  StyledContentBox,
  StyledMenuBox,
} from "./styled";
import { FooterLogo } from "../../../../shared";
import { IMenuVariant, Menu } from "../../menu";

export const MobileMenu: FC<IMenuVariant> = props => {
  const [open, setOpen] = useState(false);

  const openDrawer = () => {
    setOpen(true);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  const iOS = typeof navigator !== "undefined" && /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <Box>
      <StyledIconButton onClick={openDrawer}>
        <MenuIcon />
      </StyledIconButton>
      <StyledSwipeableDrawer
        open={open}
        anchor="right"
        onOpen={openDrawer}
        onClose={closeDrawer}
        disableBackdropTransition={iOS}
        disableDiscovery={iOS}
      >
        <StyledMenuWrapper>
          <StyledMenuHeader>
            <StyledIconButton onClick={closeDrawer}>
              <CloseIcon sx={{ fontSize: 24 }} />
            </StyledIconButton>
          </StyledMenuHeader>
          <StyledContentBox>
            <StyledMenuBox>
              <FooterLogo width={80} height={80} />
              <Menu onCloseMenu={closeDrawer} variant={props.variant} />
            </StyledMenuBox>
          </StyledContentBox>
        </StyledMenuWrapper>
      </StyledSwipeableDrawer>
    </Box>
  );
};
