import { FC } from "react";
import { Contract } from "ethers";
import { Web3ContextType } from "@web3-react/core";

import { useMetamask } from "@dzambalaorg/react-hooks-eth";
import { ContractFeatures, type IToken } from "@framework/types";
import { ListAction, ListActionVariant } from "@framework/styled";

import VestingSplitABI from "@framework/abis/json/ERC721Vesting/split.json";

interface ISplitTokenButtonProps {
  token: IToken;
  splitPercentage: number;
  className?: string;
  disabled?: boolean;
  variant?: ListActionVariant;
}

export const SplitTokenButton: FC<ISplitTokenButtonProps> = props => {
  const { className, disabled, variant, token, splitPercentage } = props;
  const metaSplit = useMetamask(async (web3Context: Web3ContextType) => {
    const { address } = token.template?.contract || {};

    if (!address) {
      throw new Error("unsupported token type");
    }

    const contract = new Contract(address, VestingSplitABI, web3Context.provider?.getSigner());
    return contract.split(Number(token.tokenId), splitPercentage) as Promise<void>;
  });

  const handleSplit = () => {
    return metaSplit();
  };

  return (
    <ListAction
      onClick={handleSplit}
      message="pages.tokens.current.splitBtn"
      className={className}
      dataTestId="SplitTokenButton"
      disabled={disabled || token.template?.contract?.contractFeatures.includes(ContractFeatures.SOULBOUND)}
      variant={variant}
    />
  );
};
