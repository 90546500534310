import { Badge, ListItemIcon, Menu, Button, ButtonProps, SvgIcon } from "@mui/material";
import { SxProps, styled } from "@mui/material/styles";
import { Circle } from "@mui/icons-material";

interface IStyledMenuTrigger {
  isAnchor: boolean;
}

export const spinnerMixin: SxProps = {
  svg: {
    color: "#FFFFFF",
  },
  top: "-2px",
  left: "-4px",
  bottom: undefined,
  right: undefined,
  width: "32px",
  height: "32px",
  "& span": {
    width: "32px !important",
    height: "32px !important",
  },
};

export const StyledBadge = styled(Badge)(() => ({
  ".MuiBadge-badge": {
    color: "#F5F5F5",
    minWidth: 12,
    height: 12,
    padding: 0,
  },
}));

export const StyledMenuTrigger = styled(Button, { shouldForwardProp: prop => prop !== "isAnchor" })<
  ButtonProps & IStyledMenuTrigger
>(({ theme, isAnchor }) => ({
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 500,
  color: !isAnchor ? "#F5F5F5" : "#profile?.wallet ",
  textTransform: "none",
  columnGap: theme.spacing(0.75),
  padding: 0,

  "& #stroke": {
    stroke: !isAnchor ? "#F5F5F5" : "#4BD4FF",
  },

  "& .MuiButton-icon": {
    margin: 0,
  },

  "&:hover": {
    color: "#4BD4FF",
    "& #stroke": {
      stroke: "#4BD4FF",
    },
  },

  [theme.breakpoints.down("md")]: {
    fontSize: theme.typography.pxToRem(18),
  },
}));

export const StyledCircle = styled(Circle)({
  color: "#F44336",
  width: 12,
  height: 12,
});

export const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.common.black,
})) as typeof ListItemIcon;

export const StyledSvgIcon = styled(SvgIcon)({
  width: 24,
  height: 24,
}) as any;

export const StyledMenu = styled(Menu)(({ theme }) => ({
  marginTop: theme.spacing(1),
  "& svg": {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  "& #stroke": {
    stroke: "#FFFFFF",
  },
  "& li": {
    color: theme.palette.common.black,
  },
})) as typeof Menu;
