import { Box, styled } from "@mui/material";

import { contentSideFlex } from "../styled";

export const StyledBoxWithTitleRoot = styled(Box)(({ theme }) => ({
  width: "100%",
  ...contentSideFlex,
  alignItems: "flex-start",
  gap: theme.spacing(1.25),
  background: "#FFFFFF",
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),

  "& h4": {
    width: "100%",
    color: "#000000",
    fontSize: theme.spacing(3),
    fontWeight: 500,
    lineHeight: "130%",
    margin: 0,
  },
}));
