export type StylesScheme = {
  [key: string]: string | number | StylesScheme;
};

export enum ShapeType {
  LINEAR = "linear",
  CLIFF = "cliff",
  STEPS = "steps",
}

export enum MenuVariant {
  LANDING = "landing",
  INNER = "inner",
}

export enum Time {
  ONE_DAY = "ONE_DAY",
  TWO_DAYS = "TWO_DAYS",
  SEVEN_DAYS = "SEVEN_DAYS",
  MONTH = "MONTH",
  THREE_MONTH = "THREE_MONTH",
  SIX_MONTH = "SIX_MONTH",
  YEAR = "YEAR",
  TWO_YEARS = "TWO_YEARS",
}

export type TPlotConfig = {
  duration: number;
  cliff: number;
  period: number;
  afterCliffBasisPoints: number;
  immediateUnlockPercentage: number;
  immediateUnlockPercentageRestPercent: number;
  growthRate: number;
  startTimestamp: string;
};

export interface IDialogBase {
  open: boolean;
  onClose: () => void;
}
